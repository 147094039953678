<template lang="pug">
  .char-line
    .d-grid.gap-2.d-md-flex.justify-content-md-end
      .btn-group
        button.btn(:class="timeUnit == 'day' ? 'btn-primary' : 'btn-secondary'" @click="setTimeUnit('day')") Diario
        button.btn(:class="timeUnit == 'month' ? 'btn-primary' : 'btn-secondary'" @click="setTimeUnit('month')") Mensual
    template(v-if="!loading")
      .chart-container(ref="chartdiv")
</template>

<script>

  import * as am5 from '@amcharts/amcharts5';
  import * as am5xy from '@amcharts/amcharts5/xy';
  import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

  export default {
    name: 'ChartLine',
    props: {
      data: Array,
      categoryField: String,
      valueFiled: String,
      title: String
    },
    mounted() {
      this.drawChart()

    },
    data () {
      return {
        root: null,
        chart: null,
        xAxis: null,
        yAxis: null,
        series: null,
        legend: null,
        cursor: null,
        timeUnit: 'day',
        loading: false
      }
    },
    methods: {
      drawChart() {
        this.root = am5.Root.new(this.$refs.chartdiv);

        this.root.setThemes([am5themes_Animated.new(this.root)]);

        this.chart = this.root.container.children.push(
          am5xy.XYChart.new(this.root, {
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
          })
        );

        this.cursor = this.chart.set("cursor", am5xy.XYCursor.new(this.root, {
          behavior: "none"
        }));
        this.cursor.lineY.set("visible", false);

        this.xAxis = this.chart.xAxes.push(am5xy.DateAxis.new(this.root, {
          maxDeviation: 0.2,
          baseInterval: {
            timeUnit: this.timeUnit,
            count: 1
          },
          renderer: am5xy.AxisRendererX.new(this.root, {}),
          tooltip: am5.Tooltip.new(this.root, {})
        }));

        this.yAxis = this.chart.yAxes.push(am5xy.ValueAxis.new(this.root, {
          renderer: am5xy.AxisRendererY.new(this.root, {})
        }));

        this.series = this.chart.series.push(am5xy.LineSeries.new(this.root, {
          name: "Series",
          xAxis: this.xAxis,
          yAxis: this.yAxis,
          valueYField: "value",
          valueXField: "date",
          tooltip: am5.Tooltip.new(this.root, {
            labelText: "{valueY}"
          })
        }));

        this.series.data.processor = am5.DataProcessor.new(this.root, {
          dateFormat: "yyyy-MM-dd",
          dateFields: ["date"]
        })
        

        this.chart.set("scrollbarX", am5.Scrollbar.new(this.root, {
          orientation: "horizontal"
        }));
        
        this.series.data.setAll(this.data);

        this.series.appear(1000);
        this.chart.appear(1000, 100);
      },
      setTimeUnit(unit) {
        this.root.dispose()
        this.timeUnit = unit
        this.drawChart()
      }
    }
  }
</script>
<style lang="scss">
  .chart-container {
  width: 100%;
  height: 500px;
}
</style>