import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './locals/en_EN.json'
import es from './locals/es_ES.json'
import ca from './locals/ca_ES.json'
import it from './locals/it_IT.json'
import fr from './locals/fr_FR.json'
import pt from './locals/pt_PT.json'

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'es',
  messages: {
    en: en,
    es: es,
    ca: ca,
    it: it,
    fr: fr,
    pt: pt
  }
})