<template lang="pug">
  .add_and_replace_account.pt-2
    template(v-if="accountIdToMerge")
      span Cuenta actual:
      br
      strong {{accountNameToMerge}}
    template(v-else)
      span Sin cuenta
    hr
    .p-2(v-if="createAccount")
      draw-simp-form(
        :module="accountModule" 
        :value="accountData"
        @createRegister="createAccountData"
        @updateRegister="updateAccountData"
      )
    .p-3(v-else)
      .row
        .col
         p Para añadir el número a una cuenta introduzca el código, nombre o cif de la cuenta. 
         p Si desea generar una cuenta nueva pulse el botón
          button.btn.btn-outline-primary.btn-sm.ms-2(@click="setCreateAccount(true)") Crear cuenta
      .row
        .col
          input.form-control(v-model="accountCode" placeholder="Código de cuenta" @keyup.enter="search()")
        .col
          input.form-control(v-model="accountName" placeholder="Nombre" @keyup.enter="search()")
        .col
          input.form-control(v-model="accountVatNumber" placeholder="Cif" @keyup.enter="search()")
      .row.mt-2
        .col
          button.btn.btn-primary(type="button" @click="search()") Buscar
      .row.mt-2
        .col
          table.table.mb-2
            tbody
              tr(v-for="account in accounts")
                td
                  strong {{account.name}}
                  br
                  span {{account.code}}
                  br
                  span {{account.vat_number}}
                td
                  button.btn.btn-primary(@click="mergeAccounts(account.id)") Asignar
</template>
<script>
  import DrawSimpForm from '../forms/DrawSimp.vue'
  export default {
    name: 'AddAndReplaceAccount',
    components: {
      DrawSimpForm
    },
    props: {
      accountIdToMerge: {type: Number, default: 0},
      accountNameToMerge: {type: String, default: ''},
    },
    beforeMount() {
      this.$store.dispatch("getModuleByUrl", {moduleUrl: 'accounts'})
        .then((res) => {
          this.accountModule = res
        })
    },
    data () {
      return {
        accountModule: {},
        accountCode: '',
        accountName: '',
        accountVatNumber: '',
        accounts: [],
        createAccount: false,
        accountData: {}
      }
    },
    methods: {
      search() {
        let payload = {
          code: this.accountCode,
          name: this.accountName,
          vat_number: this.accountVatNumber
        }

        this.$store.dispatch("getAccountByCodeNameCif", payload)
          .then((res) => {
            this.accounts = res
          })
      },
      mergeAccounts(accountId) {
        this.$store.dispatch("mergeAccounts", {
          accountId: accountId,
          accountIdToMerge: this.accountIdToMerge
        })
          .then((res) => {
            this.$emit("mergeAccount", res)
          })
      },
      setCreateAccount(value) {
        if(value) {
          this.accountData = {}
          if(this.accountIdToMerge) {
            this.$set(this.accountData, 'id', this.accountIdToMerge)
          }
        }
        this.createAccount = value
      },
      createAccountData(res) {
        this.$emit("mergeAccount", res)
      },
      updateAccountData(res) {
        this.$emit("mergeAccount", res)
      }
    }
  }
</script>