<template lang="pug">
  .sample-wall-1
    //.header-actions
      .header-actions-content(style="z-index: 100")
        .d-flex.flex-row
          .p-2
            filter-department(@updateDepartmentIds="updateDepartmentIds")
          .p-2
            .input-group
              button.btn.btn-secondary
                font-awesome-icon(icon="filter")
              input.form-control(type="text" placeholder="Buscar cola" v-model="filter")
    .main-content
      .container-fluid
        .row
          .col-lg-6
            screen-my-queues
            screen-my-extension-calls
          .col-lg-6
            screen-my-user-statuses-large
</template>

<script>
  export default {
    name: 'SampleWall1',
    data () {
      return {
        filter: '',
        showCalls: false,
        showUsers: true,
        showQueue: false
      }
    },
    methods: {
      
    }
  }
</script>
<style lang="scss" scoped>
  .card {
    min-width: 80px !important;
  }
</style>