import Vue from 'vue'
import productsService from '../services/api/products'

const productModule = {
    state: {
        product: {},
        products: []
    },
    mutations: {
        addProducts (state, product) {
            let index = state.products.findIndex(x => x.id === product.id)

            if(index == -1) {
                state.products.push(product)
            }
        },
        setProducts (state, products) {
            state.products = products
        },
        updateProducts (state, product) {
            let index = state.products.findIndex(x => x.id === product.id)

            if(index > -1) {
                Vue.set(state.products, index, product)
            }
        },
        deleteProducts (state, product) {
            let index = state.products.findIndex(x => x.id === product.id)

            if(index > -1) {
                state.products.splice(index,1)
            }
        },

        setProduct  (state, product) {
            state.product = product
        }
    },
    actions: {
        getProductAccount (context, payload) {
            return new Promise((resolve, reject) => {
                productsService.getProductAccount(payload.productId, payload.accountId)
                    .then((product) => {
                        context.commit('setProduct', product)
                        resolve(product)
                    })
                    .catch(reject)
            })
        }
    }
}

export default productModule