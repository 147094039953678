<template lang="pug">
  .screen-queues
    .card.mb-3
      .card-body
        .d-flex.justify-content-between
          h5.card-title.mb-1 {{ $t('Colas') }}
          a.text-dark(v-if="showQueue" @click="showQueue = !showQueue" href="#!")
            font-awesome-icon(icon="compress-alt")
          a.text-dark(v-else  @click="showQueue = !showQueue"  href="#!")
            font-awesome-icon(icon="expand-alt")

        table.table
          thead
            tr.text-center
              th 
              th(style="width:20%") {{ $t('En Cola') }}
              th(style="width:20%") {{ $t('Perdidas') }}
              th(style="width:20%") {{ $t('Totales') }}
              th(style="width:20%") {{ $t('Espera') }}
              th(style="width:20%") {{ $t('Disponibles') }}

          tbody
            my-queue(v-for="queue in queues" :queue="queue" :showQueue="showQueue")
              
</template>
<script>
  import MyQueue from '../queues/MyQueue.vue'
  export default {
    name: 'ScreenQueues',
    components: {
      MyQueue
    },
    props: {
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        showQueue: false,
      }
    },
    computed: {
      currentUser() {
        return this.$store.state.currentUser
      },
      queues() {
        return this.$store.state.queueModule.queues
      }
    },
    mounted() {
        if(this.filters && this.filters.departments_id) {
          this.$store.dispatch("getDepartmentsQueues", {
            departments_id: this.filters.departments_id
          })
        }
    },
    methods: {
      
    }
  }
</script>
<style lang="scss">
</style>