<template lang="pug">
  .gc-call
    my-calls
    call-modal
</template>
<script>
  import MyCalls from './MyCalls.vue'
  import CallModal from './CallModal.vue'
  export default {
    name: 'GcCall',
    components: {
      MyCalls, 
      CallModal
    },
    data () {
      return {
        trunks: ['T1a', 'T1b', 'T2A', 'T2B', 'T3a', 'T3b', 'T4a', 'T4b', 'T2a', 'T2b', 'TrunkMVL1', 'TrunkMVL2', 'TrunkMVL3', 'TrunkTD'],
        extensions: []
      }
    },
    computed: {
      channels() {
        return this.$store.state.callModule.channels
      },
      bridges() {
        return this.$store.state.callModule.bridges
      },
      calls() {
        return this.$store.state.callModule.calls
      },
      myCalls() {
        return this.$store.state.callModule.myCalls
      },
      modalCall() {
        return this.$store.state.callModule.modalCall
      },
      channelInTransfer() {
        return this.$store.state.callModule.channelInTransfer
      },
      currentUser() {
        return this.$store.state.currentUser
      },
      companyConfigs() {
        return this.$store.state.companyConfigs
      }
    },
    beforeCreate() {
      this.$store.dispatch("getInitialState")
    },
    mounted() {
      window.Echo.channel('App.Pbx')
        .listen('PbxNewchannel', (e) => {
            this.processNewChannel(e.event)
        })
        .listen('PbxHangup', (e) => {
            this.processHangup(e.event)
        })
        .listen('PbxQueueCallerJoin', (e) => {
            this.processQueueCallerJoin(e.event)
        })
        .listen('PbxQueueCallerLeave', (e) => {
            this.processQueueCallerLeave(e.event)
        })
        .listen('PbxBridgeCreate', (e) => {
            this.processBridgeCreate(e.event)
        })
        .listen('PbxBridgeDestroy', (e) => {
            this.processBridgeDestroy(e.event)
        })
        .listen('PbxBridgeEnter', (e) => {
            this.processBridgeEnter(e.event)
        })
        .listen('PbxBridgeLeave', (e) => {
            this.processBridgeLeave(e.event)
        })
        .listen('PbxAttendedTransfer', (e) => {
            this.processAttendedTransfer(e.event)
        })
        .listen('PbxHold', (e) => {
            this.processHold(e.event)
        })
        .listen('PbxUnhold', (e) => {
            this.processUnhold(e.event)
        })
        .listen('PbxNewConnectedLine', (e) => {
            this.processNewConnectedLine(e.event)
        })
        .listen('PbxNewCallerid', (e) => {
            this.processNewCallerid(e.event)
        })
        .listen('PbxParkedCall', (e) => {
            this.processParkedCall(e.event)
        })
        .listen('PbxCallAccount', (e) => {
            this.processCallAccount(e)
        })
        .listen('PbxCallCallEnd', (e) => {
            this.processCallCallEnd(e)
        })
        .listen('PbxActiveCallToCall', (e) => {
            this.processActiveCallToCall(e.call)
        })
        .listen('PbxCallComment', (e) => {
          this.processCallComent(e)
        })
        .listen('UpdateApplyConfig', (e) => {
          this.processUpdateApplyConfig(e)
        })
    },
    watch: {
      /*connectionState(newVal) {
        console.log(newVal)
      }*/
    },
    methods: {
      closeMyCall(closedCall) {
        if((this.currentUser.extension_id && this.currentUser.extension.number) || (this.currentUser.extension_id && this.currentUser.extension.number)) {
          let linkedids = []
          if(closedCall.call_linkedids) {
            closedCall.call_linkedids.forEach((l) => {
              linkedids.push(l.linkedid)
            })
          }

          let indexMyCall = this.getMyIndexCallByLinkedId(linkedids)

          if(indexMyCall > -1) {
            if(this.companyConfigs && this.companyConfigs.call_close_on_call_end && this.companyConfigs.call_close_on_call_end == 1 && closedCall.call_end_id) {
              this.$store.commit("deleteMyCallByIndex", indexMyCall)

            } else {
              let myCall = this.myCalls[indexMyCall]

              myCall.duration_wait = closedCall.duration_wait
              myCall.duration = closedCall.duration
              myCall.callStatus = closedCall.call_status.name

              this.$store.commit("updateMyCall", {
                  index: indexMyCall,
                  call: myCall
                });

              if(this.isModalCall(linkedids)) {
                this.$store.commit("setModalCall", myCall)
              }
            }
          }
        }
      },
      saveMyCall(call) {
        if(this.companyConfigs && this.companyConfigs.open_call_tab && this.companyConfigs.open_call_tab == 1) {

          if((call.callType == 'in' && this.companyConfigs.open_call_tab_in && this.companyConfigs.open_call_tab_in == 1) ||
            (call.callType == 'out' && this.companyConfigs.open_call_tab_out && this.companyConfigs.open_call_tab_out == 1) ||
            (call.callType == 'internal' && this.companyConfigs.open_call_tab_internal && this.companyConfigs.open_call_tab_internal == 1)) {
          
            if((this.currentUser.extension_id && this.currentUser.extension.number) || (this.currentUser.extension_id && this.currentUser.extension.number)) {
              if((this.currentUser.extension_id && call.connected.includes(this.currentUser.extension.number)) || (this.currentUser.secondary_extension_id && call.connected.includes(this.currentUser.secondary_extension.number))) {
                let indexMyCall = -1
                if(call.Linkedids) {
                  indexMyCall = this.getMyIndexCallByLinkedId(call.Linkedids)
                } else {
                  let linkedids = []
                  call.call_linkedids.forEach((l) => {
                    linkedids.push(l.linkedid)
                  })
                  indexMyCall = this.getMyIndexCallByLinkedId(linkedids)
                }

                if(indexMyCall > -1) {
                  this.$store.commit("updateMyCall", {
                    index: indexMyCall,
                    call: call
                  });
                } else {
                  //if(!this.currentUser.active_rest_time) {
                    this.$store.commit("addMyCall", call);

                    if(this.companyConfigs && this.companyConfigs.after_call_actions_on_ring && this.companyConfigs.after_call_actions_on_ring == 1) {
                      this.$store.dispatch("getActionsAfterOpenCall", {
                        Linkedid: call.Linkedids[0]
                      }).then((res) => {
                        if(res.url) {
                          if(!this.currentUser.active_rest_time || !this.currentUser.active_rest_time.id) {

                            window.open(res.url, '_blank').focus();
                          }
                        } else if(res.urls) {
                          res.urls.forEach((url) => {
                            window.open(url, '_blank').focus();
                          })
                        }
                      })
                    }
                  //}
                }
              }
            }
          }
        }
      },
      getIndexCallByLinkedId(Linkedid) {
        return this.calls.findIndex((c) => c.Linkedids.some((l) => l == Linkedid))
      },
      getMyIndexCallByLinkedId(Linkedids) {
        let index = -1

        this.myCalls.forEach((call, indexCall) => {
          call.Linkedids.forEach((l) => {
            if(Linkedids.includes(l)) {
              index = indexCall
            }
          })
        })

        return index
      },
      isModalCall(Linkedids) {
        let isModalCall = true

        if(this.modalCall && this.modalCall.Linkedids) {
          this.modalCall.Linkedids.forEach((l) => {
            if(!Linkedids.includes(l)) {
              isModalCall = false
            }
          })
        } else {
          isModalCall = false
        }

        return isModalCall
      },
      getBridgeLinkedids(elements) {
        let bridgeLinkedids = []

        elements.forEach((e) => {
          if(!bridgeLinkedids.some((l) => l == e.Linkedid)) {
            bridgeLinkedids.push(e.Linkedid)
          }
        })

        return bridgeLinkedids
      },
      processNewChannel(eventChannel) {
        //RC: Añadimos el canal a los canales
        this.$store.commit("addChannel", {channel: eventChannel.Channel, start: eventChannel.start, Linkedid: eventChannel.Linkedid, Uniqueid: eventChannel.Uniqueid, ChannelStateDesc: eventChannel.ChannelStateDesc })

        //RC: Obtenemos el nombre del canal
        let indexSlash = eventChannel.Channel.indexOf('/')
        let indexDash = eventChannel.Channel.indexOf('-')

        let channelType = eventChannel.Channel.substring(0, indexSlash)
        let channelName = eventChannel.Channel.substring(indexSlash + 1, indexDash)
        let channelUnique = eventChannel.Channel.substring(indexDash + 1)

        //RC: Miramos si tenemos que asignar el canal o lo añadimos de nuevo
        let indexCall = this.getIndexCallByLinkedId(eventChannel.Linkedid)

        //RC: Miramos si el canal es del troncal
        let indexTrunk = this.trunks.findIndex(x => x == channelName)

        if(indexCall == -1) {
          let newCall = {
            Linkedids: [eventChannel.Linkedid],
            CallerIDNum: eventChannel.CallerIDNum,
            Exten: eventChannel.Exten,
            start: eventChannel.start,
            queue: '',
            parked: false,
            hold: false,
            channels: [{
              channel: eventChannel.Channel, 
              start: eventChannel.start, 
              Linkedid: eventChannel.Linkedid, 
              Uniqueid: eventChannel.Uniqueid,
              ChannelStateDesc: '',
              type: channelType, 
              name: channelName, 
              unique: channelUnique 
            }],
            connected: []
          } 

          if(indexTrunk > -1) {
            this.$set(newCall, 'callType', 'in')
          } else {
            this.$set(newCall, 'callType', 'internal')
            
            //RC: Añadimos la extensión a los conectados si tenemos un canal que no es local
            if(channelType != 'Local') {
              newCall.connected.push(channelName)
              newCall.parked = false
            }
          }
          
          this.$store.commit("addCall", newCall);
          this.saveMyCall(newCall)
        } else {
          let callObject = this.calls[indexCall]

          if(indexTrunk > -1 && callObject.callType == 'internal') {
            this.$set(callObject, 'callType', 'out')
          } else if(indexTrunk == -1) {
            //RC: Añadimos la extensión a los conectados si tenemos un canal que no es local
            if(channelType != 'Local') {
              callObject.connected.push(channelName)
              callObject.parked = false
            }
          }

          callObject.channels.push({
            channel: eventChannel.Channel, 
            start: eventChannel.start, 
            Linkedid: eventChannel.Linkedid, 
            Uniqueid: eventChannel.Uniqueid,
            ChannelStateDesc: '',
            type: channelType, 
            name: channelName, 
            unique: channelUnique 
          })

          this.$store.commit("updateCall", {
            index: indexCall,
            call: callObject
          })
          this.saveMyCall(callObject)
        }
      },
      processHangup(eventChannel) {
        let index = this.channels.findIndex((c) => c.channel == eventChannel.Channel)

        if(index > -1) {
          //RC: Miramos si tenemos que asignar el canal o lo añadimos de nuevo
          let indexCall = this.getIndexCallByLinkedId(eventChannel.Linkedid)

          if(indexCall > -1) {
            let hangupCall = this.calls[indexCall]
            let indexChannel = hangupCall.channels.findIndex((ch) => ch.channel  == eventChannel.Channel)

            if(indexChannel > -1) {
              //RC: Obtenemos el nombre del canal
              let indexSlash = eventChannel.Channel.indexOf('/')
              let indexDash = eventChannel.Channel.indexOf('-')

              let channelType = eventChannel.Channel.substring(0, indexSlash)
              let channelName = eventChannel.Channel.substring(indexSlash + 1, indexDash)

              if(channelType != 'Local') {

                //RC: Si no es un canal local quitamos la extensión de la llamada (siempre que exista)
                let indexConnected = hangupCall.connected.findIndex((connected) => connected == channelName)

                if(indexConnected > -1) {

                  //RC: Antes de eliminar tenemos que mirar si hay otro canal con la misma extensión
                  let totalChannelsConnected = 0
                  hangupCall.channels.forEach((ch) => {
                    let indexSlashCh = ch.channel.indexOf('/')
                    let indexDashCh = ch.channel.indexOf('-')

                    let chType = ch.channel.substring(0, indexSlashCh)
                    let chName = ch.channel.substring(indexSlashCh + 1, indexDashCh)

                    if(chType != 'Local' && chName == channelName) {
                      totalChannelsConnected = totalChannelsConnected + 1
                    }
                  })

                  if(totalChannelsConnected == 1) {
                    hangupCall.connected.splice(indexConnected, 1)
                  }
                }
              }

              hangupCall.channels.splice(indexChannel, 1)

              if(hangupCall.channels.length == 0) {
                this.$store.commit("deleteCallByIndex", indexCall)
              } else {
                this.$store.commit("updateCall", {
                  index: indexCall,
                  call: hangupCall
                })
              }
              this.saveMyCall(hangupCall)
            }
          }

          this.$store.commit("deleteChannelByIndex", index)
        }
      },
      processQueueCallerJoin(eventChannel) {
        let indexChannel = this.channels.findIndex((c) => c.channel == eventChannel.Channel)

        if(indexChannel > -1) {
          let channel = this.channels[indexChannel]

          this.$set(channel, "ChannelStateDesc", eventChannel.ChannelStateDesc)
          
          this.$store.commit("updateChannel", channel)

          //RC: Miramos si tenemos que asignar el canal o lo añadimos de nuevo
          let indexCall = this.getIndexCallByLinkedId(eventChannel.Linkedid)

          if(indexCall > -1) {
            let tempCall = this.calls[indexCall]

            this.$set(tempCall, 'queue', eventChannel.Exten)

            let indexCallChannel = tempCall.channels.findIndex((c) => c.channel == eventChannel.Channel)

            if(indexCallChannel > -1) {
              let callChannel = tempCall.channels[indexCallChannel]

              this.$set(callChannel, "ChannelStateDesc", eventChannel.ChannelStateDesc)
              
              this.$set(tempCall.channels, indexCallChannel, callChannel)
            }
            this.$store.commit("updateCall", {
              index: indexCall,
              call: tempCall
            })
            this.saveMyCall(tempCall)
          }
        }
      },
      processQueueCallerLeave(eventChannel) {
        let indexChannel = this.channels.findIndex((c) => c.channel == eventChannel.Channel)

        if(indexChannel > -1) {
          let channel = this.channels[indexChannel]

          this.$set(channel, "ChannelStateDesc", eventChannel.ChannelStateDesc)
          
          this.$store.commit("updateChannel", channel)

          //RC: Miramos si tenemos que asignar el canal o lo añadimos de nuevo
          let indexCall = this.getIndexCallByLinkedId(eventChannel.Linkedid)

          if(indexCall > -1) {
            let tempCall = this.calls[indexCall]

            this.$set(tempCall, 'queue', '')

            let indexCallChannel = tempCall.channels.findIndex((c) => c.channel == eventChannel.Channel)

            if(indexCallChannel > -1) {
              let callChannel = tempCall.channels[indexCallChannel]

              this.$set(callChannel, "ChannelStateDesc", eventChannel.ChannelStateDesc)
              
              this.$set(tempCall.channels, indexCallChannel, callChannel)
            }
            this.$store.commit("updateCall", {
              index: indexCall,
              call: tempCall
            })
            this.saveMyCall(tempCall)
          }
        }
      },
      processBridgeCreate(eventBridgeCreate) {
        let bridge = {
          id: eventBridgeCreate.BridgeUniqueid, 
          elements: [],
          firstLinkedid: ''
        }
        this.$store.commit("addBridge", bridge);
      },
      processBridgeDestroy(eventBridgeDestroy) {

        let index = this.bridges.findIndex((b) => b.id == eventBridgeDestroy.BridgeUniqueid)
        
        if(index > -1) {
          this.$store.commit("deleteBridgeByIndex", index )
        }
      },
      processBridgeEnter(eventChannel) {
        let indexChannel = this.channels.findIndex((c) => c.channel == eventChannel.Channel)

        if(indexChannel > -1) {
          let channel = this.channels[indexChannel]

          this.$set(channel, "ChannelStateDesc", eventChannel.ChannelStateDesc)
          
          this.$store.commit("updateChannel", channel)

          //RC: Miramos si tenemos que asignar el canal o lo añadimos de nuevo
          let indexCall = this.getIndexCallByLinkedId(eventChannel.Linkedid)

          if(indexCall > -1) {
            let tempCall = this.calls[indexCall]

            this.$set(tempCall, 'queue', '')

            let indexCallChannel = tempCall.channels.findIndex((c) => c.channel == eventChannel.Channel)

            if(indexCallChannel > -1) {
              let callChannel = tempCall.channels[indexCallChannel]

              this.$set(callChannel, "ChannelStateDesc", eventChannel.ChannelStateDesc)
              
              this.$set(tempCall.channels, indexCallChannel, callChannel)
            }
            this.$store.commit("updateCall", {
              index: indexCall,
              call: tempCall
            })
            this.saveMyCall(tempCall)
          }
        }
        
        let index = this.bridges.findIndex((b) => b.id == eventChannel.BridgeUniqueid)

        if(index > -1) {
          let bridge = this.bridges[index]

          let bridgeElement = {
            channel: eventChannel.Channel,
            Linkedid: eventChannel.Linkedid,
          }

          bridge.elements.push(bridgeElement)
          this.$store.commit("updateBridge", bridge)

          //RC: miramos si tenemos linkedid diferentes
          let bridgeLinkedids = this.getBridgeLinkedids(bridge.elements)

          if(bridgeLinkedids.length > 1) {
            //RC: si tenemos linkedid diferentes tenemos que buscar los indices de las llamadas
            let indexCall1 = this.getIndexCallByLinkedId(bridgeLinkedids[0])
            let indexCall2 = this.getIndexCallByLinkedId(bridgeLinkedids[1])

            let callOld = {}
            let callNew = {}

            if(indexCall1 != indexCall2) {
              //RC: si son diferentes los tenemos que unir a la llamada más antigua
              if(this.calls[indexCall1].start <= this.calls[indexCall2].start) {
                callOld = this.calls[indexCall1]
                callNew = this.calls[indexCall2]
              } else {
                callOld = this.calls[indexCall2]
                callNew = this.calls[indexCall1]
              }

              //RC: Añadimos los linkedid
              callNew.Linkedids.forEach((L) => {
                callOld.Linkedids.push(L)
              })

              //RC: Añadimos los canales
              callNew.channels.forEach((Channel) => {
                callOld.channels.push(Channel)

                //RC: Obtenemos el nombre del canal
                let indexSlash = Channel.channel.indexOf('/')
                let indexDash = Channel.channel.indexOf('-')

                let channelType = Channel.channel.substring(0, indexSlash)
                let channelName = Channel.channel.substring(indexSlash + 1, indexDash)

                if(channelType != 'Local') {

                  //RC: Si no es un canal local quitamos la extensión de la llamada (siempre que exista)
                  let indexConnected = callOld.connected.findIndex((connected) => connected == channelName)

                  if(indexConnected == -1) {
                    callOld.connected.push(channelName)
                    callOld.parked = false
                  }
                }
              })

              //RC: Modificamos la llamada anterior y eliminamos la nueva
              if(this.calls[indexCall1] <= this.calls[indexCall2]) {
                this.$store.commit("updateCall", {
                  index: indexCall1,
                  call: callOld
                })
                this.saveMyCall(callOld)
                this.$store.commit("deleteCallByIndex", indexCall2)
              } else {
                this.$store.commit("updateCall", {
                  index: indexCall2,
                  call: callOld
                })
                this.saveMyCall(callOld)
                this.$store.commit("deleteCallByIndex", indexCall1)
              }
            }

          }

          //RC: Puede darse el caso que el canal cambie de linkedid, en este caso tenemos que mover el canal de llamada
          let indexChannel = this.channels.findIndex((c) => c.channel == eventChannel.Channel)

          if(indexChannel > -1) {
            let channel = this.channels[indexChannel]

            if(channel.Linkedid != eventChannel.Linkedid) {
              //RC: Localizamos la llamadan anterior y la actual
              let indexCallOld = this.getIndexCallByLinkedId(channel.Linkedid)

              if(indexCallOld > -1) {
                let callOld = this.calls[indexCallOld]
                
                let indexChannelOld = callOld.channels.findIndex((c) => c.channel == channel.channel)

                if(indexChannelOld > -1) {

                  //RC: Eliminamos el registro
                  callOld.channels.splice(indexChannelOld, 1)

                  //RC: Si no tenemos mas canales eliminamos la llamada
                  if(callOld.channels.length == 0) {
                    this.$store.commit("deleteCallByIndex", indexCallOld)
                  } else {
                    this.$store.commit("updateCall",{
                      index: indexCallOld,
                      call: callOld
                    })
                    this.saveMyCall(callOld)
                  }
                }

                //RC: Obtenemos la nueva llamada
                let indexCallNew = this.getIndexCallByLinkedId(eventChannel.Linkedid)

                if(indexCallNew > -1) {

                  let callNew = this.calls[indexCallNew]

                  //RC: Obtenemos el nombre del canal
                  let indexSlash = eventChannel.Channel.indexOf('/')
                  let indexDash = eventChannel.Channel.indexOf('-')

                  let channelType = eventChannel.Channel.substring(0, indexSlash)
                  let channelName = eventChannel.Channel.substring(indexSlash + 1, indexDash)
                  let channelUnique = eventChannel.Channel.substring(indexDash + 1)

                  callNew.channels.push({
                    channel: eventChannel.Channel, 
                    start: eventChannel.start, 
                    Linkedid: eventChannel.Linkedid, 
                    Uniqueid: eventChannel.Uniqueid,
                    type: channelType, 
                    name: channelName, 
                    unique: channelUnique 
                  })

                  if(channelType != 'Local') {

                    //RC: Si no es un canal local quitamos la extensión de la llamada (siempre que exista)
                    let indexConnected = callNew.connected.findIndex((connected) => connected == channelName)

                    if(indexConnected == -1) {
                      callNew.connected.push(channelName)
                      callNew.parked = false
                    }

                  }
                  //RC: Modificamos la llamda
                  this.$store.commit("updateCalls", {
                    index: indexCallNew,
                    call: callNew
                  })
                  this.saveMyCall(callNew)
                }
              }
            }
          }
        }
      },
      processBridgeLeave(eventChannel) {
        let index = this.bridges.findIndex((b) => b.id == eventChannel.BridgeUniqueid)
        
        if(index > -1) {
          let bridge = this.bridges[index]

          let indexElement = bridge.elements.findIndex((e) => e.channel == eventChannel.Channel)

          if(indexElement > -1) {
            bridge.elements.splice(indexElement, 1)
            this.$store.commit("updateBridge", bridge)
          }
        }
      },
      processNewCallerid(eventChannel) {
        let indexCallNew = this.getIndexCallByLinkedId(eventChannel.Linkedid)

        if(indexCallNew > -1) {
          let callNew = this.calls[indexCallNew]
          let indexSlash = eventChannel.Channel.indexOf('/')
          let indexDash = eventChannel.Channel.indexOf('-')

          let channelName = eventChannel.Channel.substring(indexSlash + 1, indexDash)

          //RC: Miramos si el canal es del troncal
          let indexTrunk = this.trunks.findIndex(x => x == channelName)

          if(indexTrunk > -1) {
            this.$set(callNew, 'Exten', eventChannel.Exten)
          } else {
            this.$set(callNew, 'CallerIDNum', eventChannel.CallerIDNum)
          }

          this.$store.commit("updateCalls", {
            index: indexCallNew,
            call: callNew
          })
          
          this.saveMyCall(callNew)
        }
      },
      processAttendedTransfer(eventChannel) {
        if(this.channelInTransfer == eventChannel.OrigTransfererChannel) {
          this.$store.commit("setChannelInTransfer", '')
        }
      },
      processHold(eventChannel) {
        let indexCallNew = this.getIndexCallByLinkedId(eventChannel.Linkedid)

        if(indexCallNew > -1) {
          let callNew = this.calls[indexCallNew]
          this.$set(callNew, 'hold', true)
          
          this.$store.commit("updateCalls", {
            index: indexCallNew,
            call: callNew
          })
          this.saveMyCall(callNew)
        }
      },
      processUnhold(eventChannel) {
        let indexCallNew = this.getIndexCallByLinkedId(eventChannel.Linkedid)

        if(indexCallNew > -1) {
          let callNew = this.calls[indexCallNew]
          this.$set(callNew, 'hold', false)
          
          this.$store.commit("updateCalls", {
            index: indexCallNew,
            call: callNew
          })
          this.saveMyCall(callNew)
        }
      },
      processNewConnectedLine(eventChannel) {
        //console.log(eventChannel)
        let index = this.channels.findIndex((c) => c.channel == eventChannel.Channel)

        if(index > -1) {
          let channel = this.channels[index]

          this.$set(channel, "ChannelStateDesc", eventChannel.ChannelStateDesc)
          
          this.$store.commit("updateChannel", channel)

          let indexCallNew = this.getIndexCallByLinkedId(eventChannel.Linkedid)

          if(indexCallNew > -1) {
            let callNew = this.calls[indexCallNew]

            let indexCallChannel = callNew.channels.findIndex((c) => c.channel == eventChannel.Channel)

            if(indexCallChannel > -1) {
              let callChannel = callNew.channels[indexCallChannel]

              this.$set(callChannel, "ChannelStateDesc", eventChannel.ChannelStateDesc)
              
              this.$set(callNew.channels, indexCallChannel, callChannel)

              this.$store.commit("updateCall", {
                index: indexCallNew,
                call: callNew
              })
              this.saveMyCall(callNew)

            }
          }
        }

      },
      processNewExten(eventChannel) {
        let index = this.channels.findIndex((c) => c.channel == eventChannel.Channel)

        if(index > -1) {
          let channel = this.channels[index]

          this.$set(channel, "ChannelStateDesc", eventChannel.ChannelStateDesc)
          
          this.$store.commit("updateChannel", channel)

          let indexCallNew = this.getIndexCallByLinkedId(eventChannel.Linkedid)

          if(indexCallNew > -1) {
            let callNew = this.calls[indexCallNew]

            let indexCallChannel = callNew.channels.findIndex((c) => c.channel == eventChannel.Channel)

            if(indexCallChannel > -1) {
              let callChannel = callNew.channels[indexCallChannel]

              this.$set(callChannel, "ChannelStateDesc", eventChannel.ChannelStateDesc)
              
              this.$set(callNew.channels, indexCallChannel, callChannel)

              this.$store.commit("updateCall", {
                index: indexCallNew,
                call: callNew
              })

            }
          }
        }
      },
      processParkedCall(eventChannel) {
        let indexCallNew = this.getIndexCallByLinkedId(eventChannel.ParkeeLinkedid)

        if(indexCallNew > -1) {
          let callNew = this.calls[indexCallNew]
          this.$set(callNew, 'queue', '')
          this.$set(callNew, 'parked', true)
          
          this.$store.commit("updateCalls", {
            index: indexCallNew,
            call: callNew
          })
          this.saveMyCall(callNew)
        }
      },
      processCallAccount(eventChannel) {
        let indexCallNew = this.getIndexCallByLinkedId(eventChannel.Linkedid)
        if(indexCallNew > -1) {
          let callNew = this.calls[indexCallNew]
          this.$set(callNew, 'account', eventChannel.account)

          this.$store.commit("updateCalls", {
            index: indexCallNew,
            call: callNew
          })

          this.saveMyCall(callNew)
        }
      },
      processCallCallEnd(eventChannel) {
        let indexCallNew = this.getIndexCallByLinkedId(eventChannel.Linkedid)
        if(indexCallNew > -1) {
          let callNew = this.calls[indexCallNew]
          this.$set(callNew, 'call_end_id', eventChannel.callEndId)

          this.$store.commit("updateCalls", {
            index: indexCallNew,
            call: callNew
          })

          this.saveMyCall(callNew)
        } else {
          if(this.companyConfigs && this.companyConfigs.call_close_on_call_end && this.companyConfigs.call_close_on_call_end == 1) {
            let indexMyCall = this.getMyIndexCallByLinkedId([eventChannel.Linkedid])
            
            if(indexMyCall > -1) {
              this.$store.commit("deleteMyCallByIndex", indexMyCall)
            }

          }
        }
      },
      processActiveCallToCall(eventCloseCall) {
        this.closeMyCall(eventCloseCall)
      },
      processCallComent(eventCall) {
        let indexCallNew = this.getIndexCallByLinkedId(eventCall.Linkedid)
        if(indexCallNew > -1) {
          let callNew = this.calls[indexCallNew]
          if(!callNew.comments) {
            this.$set(callNew, 'comments', [])
          }
          
          callNew.comments.push(eventCall.comment)

          this.$store.commit("updateCalls", {
            index: indexCallNew,
            call: callNew
          })

          this.saveMyCall(callNew)
        }
      },
      processUpdateApplyConfig(eventUpdateApplyConfig) {
        if(eventUpdateApplyConfig.companyConfig.value == '1') {
          this.$store.commit("setNeedApplyConfig", true)
        } else {
          this.$store.commit("setNeedApplyConfig", false)
        }
      }

      
    }
  }
</script>
<style lang="scss">
</style>