<template lang="pug">
  .gc-phone
    a#offcanvasPhoneBtn(href="#" data-bs-toggle='offcanvas' data-bs-target='#offcanvasPhone' aria-controls='offcanvasPhone')
      font-awesome-icon(icon="phone")
    #offcanvasPhone.offcanvas.offcanvas-end(tabindex='-1' aria-labelledby='offcanvasPhoneLabel')
      .offcanvas-header
        h5#offcanvasPhoneLabel.offcanvas-title Teléfono
        a(type='button' data-bs-dismiss='offcanvas' aria-label='Close')
          font-awesome-icon(icon='times')
      .offcanvas-body
        .row
          .col
            .input-group.mb-2
              input.form-control(type="text" placeholder="Llamar a" v-model="phone")
              button.btn.btn-info(v-if="currentUser.secondary_extension_id" @click="makeCall(currentUser.secondary_extension.number)")
                font-awesome-icon(icon="phone")
                small.ms-1 {{currentUser.secondary_extension.number}}
              button.btn.btn-primary(v-if="currentUser.extension_id" @click="makeCall(currentUser.extension.number)")
                font-awesome-icon(icon="phone")
                small.ms-1 {{currentUser.extension.number}}
        .p-2
          .row
            .col.p-0.m-1
              .d-grid.gap-2
                button.btn.btn-outline-primary.p-3(@click="phone = phone + 1")
                  span 1
            .col.p-0.m-1
              .d-grid.gap-2
                button.btn.btn-outline-primary.p-3(@click="phone = phone + 2")
                  span 2
            .col.p-0.m-1
              .d-grid.gap-2
                button.btn.btn-outline-primary.p-3(@click="phone = phone + 3")
                  span 3
          .row
            .col.p-0.m-1
              .d-grid.gap-2
                button.btn.btn-outline-primary.p-3(@click="phone = phone + 4")
                  span 4
            .col.p-0.m-1
              .d-grid.gap-2
                button.btn.btn-outline-primary.p-3(@click="phone = phone + 5")
                  span 5
            .col.p-0.m-1
              .d-grid.gap-2
                button.btn.btn-outline-primary.p-3(@click="phone = phone + 6")
                  span 6
          .row
            .col.p-0.m-1
              .d-grid.gap-2
                button.btn.btn-outline-primary.p-3(@click="phone = phone + 7")
                  span 7
            .col.p-0.m-1
              .d-grid.gap-2
                button.btn.btn-outline-primary.p-3(@click="phone = phone + 8")
                  span 8
            .col.p-0.m-1
              .d-grid.gap-2
                button.btn.btn-outline-primary.p-3(@click="phone = phone + 9")
                  span 9
          .row
            .col.p-0.m-1
            .col.p-0.m-1
              .d-grid.gap-2
                button.btn.btn-outline-primary.p-3(@click="phone = phone + 0")
                  span 0
            .col.p-0.m-1


</template>
<script>
  
  export default {
    name: 'GcPhone',
    data () {
      return {
        phone: ''
      }
    },
    computed: {
      currentUser() {
        return this.$store.state.currentUser
      }
    },
    watch: {
      
    },
    mounted() {
      
    },
    methods: {
      makeCall(extension) {
        if(this.phone) {
          this.$store.dispatch("makeCall", {
            phone: this.phone,
            extension: extension
          })
        }
      }
    }
  }
</script>
<style lang="scss">
</style>