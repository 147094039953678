import appService from "./appService";

const reportsService = {
    getMyReports() {
        return appService.get('/reports/my-reports')
            .then((res) => res.data)
    },
    getReportSample(filter) {
        return appService.post('/reports/sample', filter)
            .then((res) => res.data)
    },
    getReportReportItemData(reportReportItemId, data) {
        return appService.post('/reports/get-report-report-item/' + reportReportItemId, data)
            .then((res) => res.data)
    }
}

export default reportsService;