import messageEndsService from '../services/api/message_ends'

const messageEndModule = {
    state: {
        myMessageEnds: []
    },
    mutations: {
        setMyMessageEnds(state, myMessageEnds) {
            state.myMessageEnds = myMessageEnds
        }
    },
    actions: {
        getCurrentUserMessageEnds(context) {
            return new Promise((resolve, reject) => {
                messageEndsService.getCurrentUserMessageEnds()
                    .then((res) => {
                        context.commit("setMyMessageEnds", res)
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        assignMessageEndToMessage(context, data) {
            return new Promise((resolve, reject) => {
                messageEndsService.assignMessageEndToMessage(data)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
    }
}

export default messageEndModule