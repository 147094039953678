<template lang="pug">
  .card
    .card-header.text-center(v-if="node.parentId != -1")
      flowy-drag-handle()
        p.mb-0
          strong {{node.parentOption}}
    .card-body
      span.icon.me-2
        font-awesome-icon(:icon="node.data.icon")
      strong {{title}}
      .d-flex.justify-content-between
        div
          button.btn.btn-link.text-danger(v-if="node.parentId != -1" @click="remove")
            font-awesome-icon(icon="unlink")
        div
          button.btn.btn-link.text-primary()
            font-awesome-icon(icon="edit")
</template>

<script>
  export default {
    name: 'DemoNode',
    props: ['remove', 'node', 'title', 'description'],
    data () {
      return {
        text: 'This is component A'
      }
    },
  
  }
</script>
<style lang="scss">
  .card {
    min-width: 200px;
  }
</style>