<template lang="pug">
  div(v-if="relatedLegalDocumentLines")
    button.btn.btn-sm.btn-primary.me-2(
      type="button" 
      v-for="relatedLegalDocumentLine in relatedLegalDocumentLines"
      @click="openRelationed(relatedLegalDocumentLine.legal_document)" 
    )  {{relatedLegalDocumentLine.legal_document.fullnumber}}
</template>

<script>
  export default {
    name: 'legalDocumentRelationed',
    props: {
      relatedLegalDocumentLines: {type: Array, default: function() {return [] }},
    },
    methods: {
      openRelationed(legalDocument) {
        let legalDocumentUri = ''
        switch(legalDocument.legal_document_type_id) {
          case 1:
            legalDocumentUri = 'budgets'
            break;
          case 2:
            legalDocumentUri = 'orders'
            break;
          case 3:
            legalDocumentUri = 'delivery_notes'
            break;
          case 4:
            legalDocumentUri = 'invoices'
            break;
          case 5:
            legalDocumentUri = 'in_delivery_notes'
            break;
          case 6:
            legalDocumentUri = 'refunds'
            break;
          case 7:
            legalDocumentUri = 'credit_notes'
            break;
        }

        if(legalDocumentUri) {
          this.$router.push("/" + legalDocumentUri + "/" + legalDocument.id)
        }
      }
    }
  }
</script>