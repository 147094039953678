<template lang="pug">
  .generate-delivery-note                        
    #generateInvoiceModal.modal(tabindex='-1' aria-hidden="true") 
      .modal-dialog.modal-lg
        .modal-content
          .modal-header
            h5.modal-title Generar una factura
            button#generateInvoiceModalClose.btn-close(type='button' data-bs-dismiss='modal' aria-label='Close')
          .modal-body
            draw-simp-form(
              :module="currentModule" 
              :value="invoiceData"
              @createRegister="createInvoice"
            )
</template>

<script>
  import jQuery from 'jquery'
  import DrawSimpForm from '../forms/DrawSimp.vue'
  export default {
    name: 'GenerateInvoice',
    components: {
      DrawSimpForm
    },
    props: {
      legalDocument: {type: Object, default: function() {return [] }},
    },
    data () {
      return {
        currentModule: {},
        invoiceData: {},
        account: {}
      }
    },
    beforeMount() {
      this.$store.dispatch("getModuleByUrl", {moduleUrl: 'invoices'})
        .then((res) => {
          this.currentModule = res

          this.$store.dispatch("getRegister", {moduleUrl: 'accounts', id: this.legalDocument.account_id})
          .then((res) => {
            this.account = res

            this.currentModule.module_tabs.forEach((tab, indexTab) => {
            tab.module_sections.forEach((section, indexSection) => {
              section.module_fields.forEach((input, indexInput) => {
                if(input.name == 'account_id') {
                  this.$set(input, 'disabled', true)
                  this.$set(section.module_fields, indexInput, input)
                  this.$set(tab.module_sections, indexSection, section)
                  this.$set(this.currentModule.module_tabs, indexTab, tab)
                }
                if(input.name == 'money_id') {
                  this.$set(input, 'disabled', true)
                  this.$set(section.module_fields, indexInput, input)
                  this.$set(tab.module_sections, indexSection, section)
                  this.$set(this.currentModule.module_tabs, indexTab, tab)
                }
                if(input.name == 'number') {
                  this.$set(input, 'disabled', true)
                  this.$set(section.module_fields, indexInput, input)
                  this.$set(tab.module_sections, indexSection, section)
                  this.$set(this.currentModule.module_tabs, indexTab, tab)
                }

                if(this.account.invoice_series && this.account.invoice_series.length == 1) {
                 if(input.name == 'legal_document_serie_id') {
                  this.$set(input, 'disabled', true)
                  this.$set(section.module_fields, indexInput, input)
                  this.$set(tab.module_sections, indexSection, section)
                  this.$set(this.currentModule.module_tabs, indexTab, tab)
                } 
                }
              })
            })
          })

            this.setInvoiceData();
          })
        })
    },
    methods: {
      setInvoiceData() {
        this.invoiceData = {}
        this.invoiceData.parent_legal_document_id = this.legalDocument.id
        this.invoiceData.account_id = this.legalDocument.account_id
        this.invoiceData.code = this.legalDocument.code
        this.invoiceData.money_id = this.legalDocument.money_id

        if(this.account.invoice_series && this.account.invoice_series.length == 1) {
          this.invoiceData.legal_document_serie_id = this.account.invoice_series[0].id
        }

        if(this.legalDocument.invoice_account_address_id) {
          this.invoiceData.invoice_account_address_id = this.legalDocument.invoice_account_address_id
        } else if(this.legalDocument.shipping_account_address_id) {
          this.invoiceData.invoice_account_address_id = this.legalDocument.shipping_account_address_id
        } else if(this.legalDocument.shipping_legal_document_address.address) {
          this.invoiceData.invoice_name = this.legalDocument.invoice_legal_document_address.name
          this.invoiceData.invoice_code = this.legalDocument.invoice_legal_document_address.code
          this.invoiceData.invoice_address = this.legalDocument.invoice_legal_document_address.address
          this.invoiceData.invoice_address_aux = this.legalDocument.invoice_legal_document_address.address_aux
          this.invoiceData.invoice_postal_code = this.legalDocument.invoice_legal_document_address.postal_code
          this.invoiceData.invoice_location = this.legalDocument.invoice_legal_document_address.location
          this.invoiceData.invoice_region = this.legalDocument.invoice_legal_document_address.region
          this.invoiceData.invoice_country_id = this.legalDocument.invoice_legal_document_address.country_id
        } else {
          this.invoiceData.invoice_name = this.legalDocument.invoice_legal_document_address.name
          this.invoiceData.invoice_code = this.legalDocument.invoice_legal_document_address.code
          this.invoiceData.invoice_address = this.legalDocument.invoice_legal_document_address.address
          this.invoiceData.invoice_address_aux = this.legalDocument.invoice_legal_document_address.address_aux
          this.invoiceData.invoice_postal_code = this.legalDocument.invoice_legal_document_address.postal_code
          this.invoiceData.invoice_location = this.legalDocument.invoice_legal_document_address.location
          this.invoiceData.invoice_region = this.legalDocument.invoice_legal_document_address.region
          this.invoiceData.invoice_country_id = this.legalDocument.invoice_legal_document_address.country_id
        }
      },
      createInvoice(res) {
        this.$router.push('/invoices/' + res.id)
        this.invoiceData = {}
        jQuery('#generateInvoiceModalClose').trigger('click')
      }
    }
  }
</script>