<template lang="pug">
  .call-lists
    #callRecordModal.modal.fade(tabindex='-1' aria-labelledby='callRecordModalLabel' aria-hidden='true')
      .modal-dialog.modal-lg
        .modal-content
          .modal-header
            h5.modal-title Grabación de la llamada
          .modal-body
            .row
              .col
                strong Grabaciones disponibles
                .list-group
                  a.list-group-item.list-group-item-action(v-for="(callRecord, index) in callRecords" @click="getCallRecord(callRecord)") Grabación {{index}}
              .col
                strong Reproductor
                .d-flex
                  audio(v-if="pathRecord" style="max-width: 100%" controls)
                    source(:src="pathRecord" type="audio/x-wav")
                  .btn-group.ms-2
                    a.btn.btn-primary.btn-sm(:href="pathRecord" target="_blank")
                      font-awesome-icon(icon="cloud-download-alt")
                    a.btn.btn-info.btn-sm(v-if="!loadingMp3 && pathRecordMp3 == ''" @click="getCallRecordMp3()") MP3
                    a.btn.btn-info.btn-sm(v-else-if="loadingMp3 && pathRecordMp3 == ''")
                      font-awesome-icon(icon="spinner")
                    a.btn.btn-info.btn-sm(v-else :href="pathRecordMp3" target="_blank")
                      font-awesome-icon(icon="cloud-download-alt")

    .header-actions
      .header-actions-content
        .d-flex
          .flex-grow-1.p-2
          .p-2
            .btn-group
              button.btn.btn-outline-success(@click="exportToCsv()") Exportar a CSV
          .p-2
            .btn-group
              button.btn(@click="showUserExtension = 'users'" :class="showUserExtension == 'users' ? 'btn-primary' : 'btn-outline-primary'") Usuarios
              button.btn(@click="showUserExtension = 'extensions'" :class="showUserExtension == 'extensions' ? 'btn-primary' : 'btn-outline-primary'") Extensiones
          .p-2
            .input-group
              button.btn.btn-outline-secondary(type='button' @click="prevPage()" v-if="page > 1")
                font-awesome-icon(icon='angle-double-left')
              select.form-select.border-secondary(aria-label='Default select example' v-model="page" @change="search()")
                option(v-for='i in totalPages' :key="'page' + i" :value='i') {{i}}
              button.btn.btn-outline-secondary(type='button' @click="nextPage()" v-if="page < totalPages")
                font-awesome-icon(icon='angle-double-right')
    .main-content
      .p-2
        .container-fluid
          .row
            .col-12
              .card
                .card-body
                  .row
                    .col-6
                      h5.card-title {{$t('Llamadas')}}
                    .col-6
                      p.text-end 
                        span {{$t('Mostrando')}}&nbsp;
                        strong {{limitStart}} - {{limitEnd}}
                        span &nbsp;{{$t('de')}}&nbsp; 
                        strong {{totalElements}}
                  .table-responsive
                    table.table.table-striped.table-hover
                      thead
                        tr
                          th(@click="changeSortDirection('calls.start')") 
                            template(v-if="sortColumn == 'calls.start'")
                              font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                              font-awesome-icon.float-end(icon="angle-down" v-else)
                            span {{$t('Fecha')}}
                          th(@click="changeSortDirection('call_types.name')") 
                            template(v-if="sortColumn == 'call_types.name'")
                              font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                              font-awesome-icon.float-end(icon="angle-down" v-else)
                            span {{$t('Tipo')}}
                          th(@click="changeSortDirection('call_status.name')")
                            template(v-if="sortColumn == 'call_status.name'")
                              font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                              font-awesome-icon.float-end(icon="angle-down" v-else)
                            span {{$t('Estado')}}
                          th(@click="changeSortDirection('accounts.name')")
                            template(v-if="sortColumn == 'accounts.name'")
                              font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                              font-awesome-icon.float-end(icon="angle-down" v-else)
                            span {{$t('Cuenta')}}
                          th(@click="changeSortDirection('calls.from')")
                            template(v-if="sortColumn == 'calls.from'")
                              font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                              font-awesome-icon.float-end(icon="angle-down" v-else)
                            span {{$t('Origen')}}
                          th(@click="changeSortDirection('calls.to')")
                            template(v-if="sortColumn == 'calls.to'")
                              font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                              font-awesome-icon.float-end(icon="angle-down" v-else)
                            span {{$t('Destino')}}
                          th(@click="changeSortDirection('calls.duration_wait')")
                            template(v-if="sortColumn == 'calls.duration_wait'")
                              font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                              font-awesome-icon.float-end(icon="angle-down" v-else)
                            span {{$t('Espera')}}
                          th(@click="changeSortDirection('calls.duration')")
                            template(v-if="sortColumn == 'calls.duration'")
                              font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                              font-awesome-icon.float-end(icon="angle-down" v-else)
                            span {{$t('Duración')}}
                          th(v-if="showUserExtension == 'users'" @click="changeSortDirection('users.name')")
                            template(v-if="sortColumn == 'users.name'")
                              font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                              font-awesome-icon.float-end(icon="angle-down" v-else)
                            span {{$t('Usuarios')}}
                          th(v-if="showUserExtension == 'extensions'" @click="changeSortDirection('extensions.name')")
                            template(v-if="sortColumn == 'extensions.name'")
                              font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                              font-awesome-icon.float-end(icon="angle-down" v-else)
                            span {{$t('Extensiones')}}
                          th(@click="changeSortDirection('call_ends.name')")
                            template(v-if="sortColumn == 'call_ends.name'")
                              font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                              font-awesome-icon.float-end(icon="angle-down" v-else)
                            span {{$t('Final')}}
                          th(@click="changeSortDirection('departments.name')")
                            template(v-if="sortColumn == 'departments.name'")
                              font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                              font-awesome-icon.float-end(icon="angle-down" v-else)
                            span {{$t('Departamentos')}}
                          th
                        tr
                          th
                            input.form-control.form-control-sm(type="date" placeholder="Desde" v-model="advancedFilter.start.start")
                            input.form-control.form-control-sm(type="date" placeholder="Hasta" v-model="advancedFilter.start.end")
                          th
                            v-select(:options="callTypes" :reduce="label => label.id"  label="label" v-model="advancedFilter.call_types_id" multiple="multiple")
                          th
                            template(v-if="callStatuses.length > 0")
                              v-select(:options="callStatuses" :reduce="label => label.id"  label="label" v-model="advancedFilter.call_statuses_id" multiple="multiple")
                          th
                            input.form-control.form-control-sm(type="text" placeholder="Cuenta" v-model="advancedFilter.account")
                          th
                            input.form-control.form-control-sm(type="text" placeholder="Origen" v-model="advancedFilter.from")
                          th
                            input.form-control.form-control-sm(type="text" placeholder="Destino" v-model="advancedFilter.to")
                          th
                          th
                          th(v-if="showUserExtension == 'users'")
                            v-select(:options="users" :reduce="user => user.id"  label="name" v-model="advancedFilter.users_id" multiple="multiple")
                          th(v-if="showUserExtension == 'extensions'")
                            v-select(:options="extensions" :reduce="extension => extension.id"  label="name" v-model="advancedFilter.extensions_id" multiple="multiple")
                          th
                          th
                            filter-department(defaultKey="calls" @updateDepartmentIds="updateDepartmentIds")
                      tbody
                        tr(v-for="call in registers")
                          td {{[call.start, "unix"] | unixToDateTime}}
                          td
                            span.icon.text-primary(v-if="call.call_type_id == 1")
                              font-awesome-icon(icon="arrow-right")
                            span.icon.text-success(v-else-if="call.call_type_id == 2")
                              font-awesome-icon(icon="arrow-left")
                            span.icon.text-warning(v-else)
                              font-awesome-icon(icon="redo")
                          td
                            strong.badge(:class="getBackgroundStatus(call.call_status_id)") {{call.call_status.name}}
                          td
                            strong(v-if="call.account_id && call.account") {{call.account.name}}
                            span(v-else) -
                          td {{call.from}}
                          td {{call.to}}
                          td {{call.duration_wait | timeInHoursMin}}
                          td {{call.duration | timeInHoursMin}}
                          td(v-if="showUserExtension == 'users'")
                            template(v-for="user in call.answered_users")
                              span {{user.name}}
                              br
                          td(v-if="showUserExtension == 'extensions'")
                            template(v-for="extension in call.answered_extensions")
                              span {{extension.name}}
                              br
                          td
                            span(v-if="call.call_end_id") {{call.call_end.name}}
                            span(v-else) -
                          td
                            template(v-if="call.call_departments" v-for="callDepartment in call.call_departments")
                              span(v-if="callDepartment.department_id && callDepartment.department") {{callDepartment.department.name}}
                              br
                          td
                            template(v-if="companyConfigs && companyConfigs.show_call_record && companyConfigs.show_call_record == 1 && call.call_records && call.call_records.length > 0")
                              button.btn.btn-link.me-2(type="button"  @click="showCallRecords(call)")
                                font-awesome-icon(icon="play")
                            button.btn.btn-link.text-dark.btn-sm(@click.stop="setModalCall(call)")
                              font-awesome-icon(icon="eye")
</template>

<script>
  import {Modal} from 'bootstrap'
  export default {
    name: 'CallList',
    data() {
      return {
        currentModule: {},
        registers: [],
        filter: '',
        sortColumn: 'calls.start',
        sortDirection: -1,
        page: 0,
        totalPages: 0,
        limitStart: 0,
        limitEnd: 0,
        totalElements: 0,
        showUserExtension: 'users',
        callRecords: [],
        pathRecord: '',
        advancedFilter: {
          start: {start:'', end:''},
          call_types_id: [],
          call_statuses_id: [],
          account: '',
          from: '',
          to: '',
          users_id: [],
          extensions_id: [],
          departments_id: [],
        },
        pointerFilter: null,
        callTypes: [
          {id: 1, label: 'In'},
          {id: 2, label: 'Out'},
          {id: 3, label: 'Internal'},
        ],
        callStatuses: [],
        loadingMp3: false,
        pathRecordMp3: '',
        currentCallRecord: null
      }
    },
    computed: {
      companyConfigs() {
        return this.$store.state.companyConfigs
      },
      users() {
        return this.$store.state.userModule.users
      },
      extensions() {
        return this.$store.state.extensionModule.extensions
      },
    },
    watch: {
      advancedFilter: {
        deep: true,
        handler() {
          if(this.pointerFilter) {
            clearTimeout(this.pointerFilter)
          }
          this.pointerFilter = setTimeout(() => this.search(), 500)
        }
      }
    },
    beforeMount() {
      this.moduleUrl = 'calls'
      this.$store.dispatch("getModuleByUrl", {moduleUrl: this.moduleUrl})
        .then((res) => {
          this.currentModule = res
        })

      this.$store.dispatch("getRegistersList", {moduleUrl: "call_statuses", columns: "id,name"})
        .then((res) => {
          res.forEach((r) => {
            if(r.id > 1) {
              this.callStatuses.push(r)
            }
          })
        })
      this.search()
    },
    methods: {
      getBackgroundStatus(callStatusId) {
        let callStatusClass = 'bg-primary'

        switch(callStatusId) {
          case 2:
            callStatusClass = 'bg-success'
            break
          case 3:
            callStatusClass = 'bg-warning'
            break
          case 4:
            callStatusClass = 'bg-danger'
            break
        }

        return callStatusClass
      },
      search() {
        this.$store.dispatch("searchCalls", {
          page: this.page,
          data: {
            advancedFilter: this.advancedFilter,
            sortColumn: this.sortColumn,
            sortDirection: this.sortDirection,
            limit: this.limit,
          }
        }).then((res) => {
          this.registers = res.data
          this.totalPages = res.total_pages
          this.limit = res.limit
          this.limitStart = res.limit_start + 1
          this.limitEnd = res.limit_start + res.limit
          this.totalElements = res.total
          if(this.limitEnd > this.totalElements) {
            this.limitEnd = this.totalElements
          }
        })
      },
      exportToCsv() {
        this.$store.dispatch("exportCallsCsv", {
          data: {
            advancedFilter: this.advancedFilter
          }
        }).then((res) => {
          if(res.error) {
            this.$swal.fire({
              title: 'No se pudo realizar la petición.',
              icon: 'error'
            })
          } else {
            this.$swal.fire({
              title: 'Informe solicitado correctamente, en breve recibirá un correo.',
              icon: 'info'
            })
          }
        })
      },
      prevPage() {
        this.page--
        this.search();
      },
      nextPage() {
        this.page++
        this.search();
      },
      changeSortDirection(column) {
        if(column == this.sortColumn) {
          this.sortDirection = this.sortDirection * -1
          this.page = 1
        } else {
          this.sortColumn = column
          this.sortDirection = 1
          this.page = 1
        }

        this.search()
      },
      updateDepartmentIds(event) {
        this.$set(this.advancedFilter, 'departments_id', event.departments_id)
      },
      setModalCall(call) {
        this.$store.commit("setModalCall", call)
        let modalCall = document.getElementById('callModal')
        new Modal(modalCall).show() 
      },
      showCallRecords(call) {
        this.callRecords = call.call_records

        if(this.callRecords.length == 1) {
          this.getCallRecord(this.callRecords[0])
        }
        let callRecordModal = document.getElementById('callRecordModal')
        new Modal(callRecordModal).show() 
      },
      getCallRecord(callRecord) {
        this.pathRecord = ''
        this.pathRecordMp3 = ''
        this.loadingMp3 = false
        this.currentCallRecord = callRecord
        this.$store.dispatch("getCallRecord", {
          callRecordId: callRecord.id
        }).then((res) => {
          if(res.path) {
            this.pathRecord = res.path
          }
        })
      },
      getCallRecordMp3() {
        if(!this.loadingMp3) {
          this.loadingMp3 = true
          this.pathRecordMp3 = ''
          this.$store.dispatch("getCallRecordMp3", {
            callRecordId: this.currentCallRecord.id
          }).then((res) => {
            if(res.path) {
              this.pathRecordMp3 = res.path
            }

            this.loadingMp3= false
          })
        }
      }
    }
  }
</script>