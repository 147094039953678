<template lang="pug">
  .chart-donut(v-if="showChart")
    apexchart(type="donut" height="350" :options="chartOptions" :series="series" ref="chart")
</template>

<script>

  export default {
    name: 'ChartDonut',
    props: {
      series: Array,
      labels: Array,
      colors: {type: Array, default: function() {return [] }},
    },
    data () {
      return {
        chartOptions: {
          chart: {
            type: 'donut',
          },
          labels: [],
        },
        showChart: false
      }
    },
    watch: {
      series(newVal) {
        this.$refs['chart'].updateSeries(newVal)
      },
      labels(newVal) {
        this.chartOptions.labels = newVal
        this.$refs['chart'].updateSeries(this.series)
      }
    },
    mounted() {
      this.chartOptions.labels = this.labels
      if(this.colors.length > 0) {
        this.$set(this.chartOptions, 'colors', this.colors)
      }
      this.showChart = true
    },
    methods: {
      
    }
  }
</script>
<style lang="scss">
  .chart-container {
  width: 100%;
  height: 500px;
}
</style>