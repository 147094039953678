<template lang="pug">
  .rate-show(v-if="rate.id")
    .header-actions
      .header-actions-content
        .d-flex
          .flex-grow-1.p-2
            .btn-group.me-2.ms-2
              
          .p-2
            .btn-group.me-2
              a.btn.btn-outline-secondary(type='button' href="/rates")
                font-awesome-icon.me-2(:icon="['fas', 'arrow-left']")
                span Volver
            .btn-group.me-2
              a.btn.btn-outline-primary(type='button' :href="'/rates/' + rate.id + '/edit'")
                font-awesome-icon.me-2(:icon="['fas', 'edit']")
                span Editar
            .btn-group.me-2
              button.btn.btn-outline-danger(type='button' @click="deleteRate()")
                font-awesome-icon.me-2(:icon="['fas', 'trash']")
                span Eliminar
    .main-content
      .p-2
        .container-fluid
          .row
            .col-12
              .card
                .card-body
                  .row
                    .col-8
                      h1.title {{rate.name}}
                    .col-4
                      .input-group
                        input.form-control(type="text" v-model="filter" placeholder="Buscar..")
                        button.btn.btn-primary.btn-sm(type='button' data-bs-toggle='modal' data-bs-target='#rateProductModal' @click="setDefaultRateProduct")
                          font-awesome-icon.me-2(icon="plus-circle")
                          span Añadir
                  .row
                    .col-12.p-2
                      table.table
                        thead
                          tr
                            th Producto
                            th Precio
                            th
                        tbody
                          tr(v-for="rateProduct in rateProducts")
                            td {{rateProduct.product.name}}
                            td {{rateProduct.price}}
                            td
                              button.btn.btn-link.text-dark(type='button' data-bs-toggle='modal' data-bs-target='#rateProductModal' @click="editRateProduct(rateProduct)")
                                font-awesome-icon(icon="edit")
                              button.btn.btn-link.text-dark(type='button' @click="deleteRateProduct(rateProduct)")
                                font-awesome-icon(:icon="['fas', 'trash']")
    #rateProductModal.modal(v-if="rateProduct" tabindex='-1' aria-hidden="true") 
      .modal-dialog.modal-lg
        .modal-content
          .modal-header
            h5.modal-title Añadir una nueva etapa
            button#rateProductModalClose.btn-close(type='button' data-bs-dismiss='modal' aria-label='Close')
          .modal-body
            draw-simp-form(
              :module="rateProductModule" 
              :value="rateProduct"
              @createRegister="createRateProduct"
              @updateRegister="updateRateProduct"
            )
</template>

<script>
  import jQuery from 'jquery'
  import DrawSimpForm from '../../components/forms/DrawSimp.vue'

  export default {
    name: 'BudgetShow',
    components: {
      DrawSimpForm,
    },
    beforeMount() {
      this.id = this.$route.params.id
      this.moduleUrl = 'rates'
      this.$store.dispatch("getModuleByUrl", {moduleUrl: this.moduleUrl})
        .then((res) => {
          this.currentModule = res

          this.$store.dispatch("getModuleByUrl", {moduleUrl: 'rate_products'})
          .then((res) => {
            this.rateProductModule = res
          })
        })
      this.$store.dispatch("getRegister", {moduleUrl: this.moduleUrl, id: this.id})
        .then((res) => {
          this.rate = res
        })
    },
    data () {
      return {
        id: 0,
        moduleUrl: '',
        config: [],
        currentModule: {},
        rateProductModule: {},
        rateProduct: {},
        rate: {},
        filter: ''
      }
    },
    computed: {
      rateProducts() {
        if(this.filter) {
          return this.rate.rate_products.filter(rate_product => {
            return rate_product.product.name.toLowerCase().includes(this.filter);
          });
        } else {
          return this.rate.rate_products
        }
      }
    },
    watch: {
      
    },
    methods: {
      setDefaultRateProduct() {
        this.rateProduct.rate_id = this.id
      },
      editRateProduct(rateProduct) {
        this.rateProduct = rateProduct
      },
      createRateProduct(res) {
        this.rate.rate_products.push(res)
        this.rateProduct = {}
        jQuery('#rateProductModalClose').trigger('click')
      },
      updateRateProduct(res) {
        let index = this.rate.rate_products.findIndex((x) => x.id === res.id)

        if(index > -1) {
          this.$set(this.rate.rate_products, index, res)
        }

        this.rateProduct = {}
        jQuery('#rateProductModalClose').trigger('click')
      },
      deleteRateProduct(rateProduct) {
        this.$swal.fire({
          title: 'Está seguro que desea eliminar este elemento?',
          icon: 'warning',
          showCancelButton: true
        }).then((result) => {
          if(result) {
            this.$store.dispatch("deleteRegister", {
              moduleUrl: this.rateProductModule.url,
              id: rateProduct.id
            }).then((res) => {
              let index = this.rate.rate_products.findIndex((x) => x.id === res.id)

              if(index > -1) {
                this.rate.rate_products.splice(index, 1);
              }
            })
          }
        });
      }
    }
  }
</script>
<style scooped lang="scss">
</style>