<template lang="pug">
  .screen-queues-full
    .card.mb-3
      .card-body
        .d-flex.justify-content-between
          h5.card-title.mb-1 {{ $t('Mis colas') }}
          a.text-dark(v-if="showQueue" @click="showQueue = !showQueue" href="#!")
            font-awesome-icon(icon="compress-alt")
          a.text-dark(v-else  @click="showQueue = !showQueue"  href="#!")
            font-awesome-icon(icon="expand-alt")

        table.table
          thead
            tr.text-center
              th 
              th {{ $t('En Cola') }}
              th {{ $t('Perdidas') }}
              th {{ $t('Totales') }}
              th {{ $t('Espera') }}
              th {{ $t('Disponibles') }}
              th {{ $t('Nivel Servicio') }}
              th {{ $t('Pickup rate') }}

          tbody
            my-queue-full(v-for="queue in queues" :queue="queue" :showQueue="showQueue")
          tfoot(v-if="queues.length > 1")
            my-queue-full-total(:key="'totalQueues' + counterKey" :queues="queues")
              
</template>
<script>
  import MyQueueFull from '../queues/MyQueueFull.vue'
  import MyQueueFullTotal from '../queues/MyQueueFullTotal.vue'
  export default {
    name: 'ScreenMyQueuesFull',
    components: {
      MyQueueFull,
      MyQueueFullTotal
    },
    props: {
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        showQueue: false,
      }
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      currentUser() {
        return this.$store.state.currentUser
      },
      queues() {
        return this.$store.state.queueModule.queues
      }
    },
    mounted() {
        if(this.filters.queues_id) {
          this.$store.dispatch("getQueuesByIds", {
            queues_id: this.filters.queues_id
          })
        }
    },
    methods: {
      
    }
  }
</script>
<style lang="scss">
</style>