<template lang="pug">
  .screen-my-queues
    .card.mb-3
      .card-body
        .d-flex.justify-content-between
          h5.card-title.mb-1 {{ $t('Mis colas') }}
          a.text-dark(v-if="showQueue" @click="showQueue = !showQueue" href="#!")
            font-awesome-icon(icon="compress-alt")
          a.text-dark(v-else  @click="showQueue = !showQueue"  href="#!")
            font-awesome-icon(icon="expand-alt")

        table.table
          thead
            tr.text-center
              th 
              th {{ $t('En Cola') }}
              th {{ $t('Perdidas') }}
              th {{ $t('Totales') }}
              th {{ $t('Espera') }}
              th {{ $t('Disponibles') }}
              th {{ $t('Pickup rate') }}

          tbody
            my-queue(v-for="queue in queues" :queue="queue" :showQueue="showQueue")
              
</template>
<script>
  import MyQueue from '../queues/MyQueuePenalty0.vue'
  export default {
    name: 'ScreenMyQueues',
    components: {
      MyQueue
    },
    data () {
      return {
        showQueue: false,
      }
    },
    computed: {
      currentUser() {
        return this.$store.state.currentUser
      },
      queues() {
        return this.$store.state.queueModule.queues
      }
    },
    mounted() {
        
    },
    methods: {
      
    }
  }
</script>
<style lang="scss">
</style>