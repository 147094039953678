import appService from "./appService";

const messageEndsService = {
    getCurrentUserMessageEnds() {
        return appService.get('/message_ends/get-current-user-message-ends')
            .then((res) => res.data)
    },
    assignMessageEndToMessage(data) {
        return appService.post('/message_ends/assign-to-chatroom', data)
            .then((res) => res.data)
    },
}

export default messageEndsService;