import Vue from 'vue'
// import productsService from '../services/api/products'

const userModule = {
    state: {
        users: [],
    },
    mutations: {
        setUsers (state, users) {
            state.users = users
        },
        addUser (state, user) {
            let index = state.users.findIndex(x => x.id === user.id)

            if(index == -1) {
                state.users.push(user)
            }
        },
        updateUser (state, user) {
            let index = state.users.findIndex(x => x.id === user.id)

            if(index > -1) {
                Vue.set(state.users, index, user)
            }
        },
        deleteUser (state, user) {
            let index = state.users.findIndex(x => x.id === user.id)

            if(index > -1) {
                state.users.splice(index,1)
            }
        },
        deleteUserByIndex (state, index) {
            if(index > -1) {
                state.users.splice(index,1)
            }
        },
    },
    actions: {
        
    }
}

export default userModule