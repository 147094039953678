<template lang="pug">
  .screen-my-calls-withoud-ends
    .card.mb-3
      .card-body
        .d-flex.justify-content-between
          h5.card-title.mb-1 {{ $t('Mis llamadas sin finalizar') }}
          a.text-dark(@click="refresh()")
            font-awesome-icon(icon="sync")
        table.table
          thead.text-center
            tr
              th
                strong
                  font-awesome-icon.me-1(icon="arrow-right")
                br
                span {{ $t('TOTAL') }}
          tbody
            tr.text-center
              td
                h1.mb-0.text-danger(v-if="data.total") {{data.total}}
            tr
              td
                table.table.mb-0
                  tr.mb-0(v-for="call in data.calls")
                    td
                      span.icon.text-primary(v-if="call.call_type_id == 1")
                        font-awesome-icon(icon="arrow-right")
                      span.icon.text-success(v-else-if="call.call_type_id == 2")
                        font-awesome-icon(icon="arrow-left")
                      span.icon.text-warning(v-else)
                        font-awesome-icon(icon="redo")
                    td
                      strong {{call.call_status.name}}
                    td
                      strong(v-if="call.account_id") {{call.account.name}}
                      br(v-if="call.account_id")
                      span(v-if="call.call_type_id == 1") {{call.from}}
                      span(v-else-if="call.call_type_id == 2") {{call.to}}
                      span(v-else) {{call.from}} - {{call.to}}
                    td
                      span {{[call.start, "unix"] | unixToDateTime}}
                    td
                      span W: {{call.duration_wait | timeInHoursMin}}
                      br
                      span D: {{call.duration | timeInHoursMin}}
                    td
                      button.btn.btn-link.text-dark.btn-sm(@click.stop="setModalCall(call)")
                        font-awesome-icon(icon="eye")
              
</template>
<script>
  import {Modal} from 'bootstrap'
  export default {
    name: 'ScreenMyCallsWithoutEnd',
    data () {
      return {
        showCalls: false,
        data: {}
      }
    },
    computed: {
      currentUser() {
        return this.$store.state.currentUser
      },
    },
    watch: {
      
    },
    mounted() {
        this.refresh()
    },
    methods: {
      refresh() {
        this.$store.dispatch("getMyCallsWithoutEnd")
          .then((res) => {
            this.data = res
          })
      },
      setModalCall(call) {
        this.$store.commit("setModalCall", call)
        let modalCall = document.getElementById('callModal')
        new Modal(modalCall).show() 
      },
    }
  }
</script>
<style lang="scss">
</style>