<template lang="pug">
  .char-columns
    apexchart(type="bar" height="350" :options="chartOptions" :series="series" ref="chart")
</template>

<script>

  export default {
    name: 'ChartColumns',
    props: {
      series: {type: Array, default: function() {return [] }},
      categories: {type: Array, default: function() {return [] }},
      colors: {type: Array, default: function() {return [] }},
    },
    data () {
      return {
        chartOptions: {
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: this.categories,
          },
          fill: {
            opacity: 1
          },
        },
      }
    },
    watch: {
      series(newVal) {
        this.$refs['chart'].updateSeries(newVal)
      }
    },
    mounted()  {
      if(this.colors.length > 0) {
        this.$set(this.chartOptions, 'colors', this.colors)
      }
    },
    methods: {
      
    }
  }
</script>
<style lang="scss">
  .chart-container {
  width: 100%;
  height: 500px;
}
</style>