<template lang="pug">
  .draw-form
    form(@submit.prevent="saveForm()")
      .header-actions
        .header-actions-content
          .d-flex
            .flex-grow-1.p-2
              
            .p-2
              .btn-group.me-2
                button.btn.btn-outline-secondary(type="button" @click="goBack()")
                  font-awesome-icon.me-2(:icon="['fas', 'arrow-left']")
                  span Volver
              .btn-group.me-2
                button.btn.btn-outline-primary(type='submit')
                  font-awesome-icon.me-2(:icon="['fas', 'save']")
                  span Guardar
      .main-content
        .p-2
          .container-fluid
            .row
              .col-12
                .card
                  .card-body
                    .row
                      .col-12
                        h5.card-title {{title}}
                        
                        .row.mt-2
                          .col-12
                            nav.nav.mb-2
                              a.nav-link(v-for="moduleTab in module.module_tabs" :key="'formTab' + moduleTab.id" :class="currentTab == moduleTab.id ? 'active' : ''" aria-current='page' @click="setTab(moduleTab.id)") {{moduleTab.name}}
                          .col-12
                            .p-2
                              div(v-for="moduleTab in module.module_tabs" :key="'formTabContent' + moduleTab.id" v-if="currentTab == moduleTab.id")
                                div(v-for="moduleSection in moduleTab.module_sections" :key="'formTabContentSection' +  moduleSection.id")
                                  p(v-if="moduleTab.module_sections.length > 1")
                                    strong {{moduleSection.name}}
                                  .row.mb-2
                                    template(v-for="moduleField in moduleSection.module_fields")
                                      template(v-if="moduleField.field_type_id == 1")
                                        input(type="hidden" v-model="$data['localValue'][moduleField.name]")
                                      template(v-else)
                                        .mb-2(:class="moduleField.width")
                                          label.form-label {{moduleField.label}}
                                          template(v-if="moduleField.field_type_id == 2")
                                            input-text(:value="$data['localValue'][moduleField.name]" v-model="$data['localValue'][moduleField.name]")
                                          template(v-else-if="moduleField.field_type_id == 3")
                                            input-number(:value="$data['localValue'][moduleField.name]" :step="1" v-model="$data['localValue'][moduleField.name]")
                                          template(v-else-if="moduleField.field_type_id == 4")
                                            input-number(:value="$data['localValue'][moduleField.name]" :step="0.01" v-model="$data['localValue'][moduleField.name]")
                                          template(v-else-if="moduleField.field_type_id == 5")
                                            input-number(:value="$data['localValue'][moduleField.name]" :step="0.0001" v-model="$data['localValue'][moduleField.name]")
                                          template(v-else-if="moduleField.field_type_id == 6")
                                            input-email(:value="$data['localValue'][moduleField.name]" v-model="$data['localValue'][moduleField.name]")
                                          template(v-else-if="moduleField.field_type_id == 7")
                                            input-password(:value="$data['localValue'][moduleField.name]" v-model="$data['localValue'][moduleField.name]")
                                          template(v-else-if="moduleField.field_type_id == 8")
                                            input-select(
                                              :value="$data['localValue'][moduleField.name]" 
                                              :moduleOptions="moduleField.options" 
                                              :relatedKey="moduleField.related_field ? moduleField.related_field : ''"
                                              :relatedValue="moduleField.related_field ? $data['localValue'][moduleField.related_field] : ''"
                                              v-model="$data['localValue'][moduleField.name]" 
                                            )
                                          template(v-else-if="moduleField.field_type_id == 9")
                                            input-multi-select(:value="$data['localValue'][moduleField.name]" :moduleOptions="moduleField.options" v-model="$data['localValue'][moduleField.name]" )
                                          template(v-else-if="moduleField.field_type_id == 10")
                                            input-textarea(:value="$data['localValue'][moduleField.name]" v-model="$data['localValue'][moduleField.name]")
                                          template(v-else-if="moduleField.field_type_id == 11")
                                            input-wysiwyg(:value="$data['localValue'][moduleField.name]" v-model="$data['localValue'][moduleField.name]")
                                          template(v-else-if="moduleField.field_type_id == 12")
                                            input-file(:value="$data['localValue'][moduleField.name]" v-model="$data['localValue'][moduleField.name]")
                                          template(v-else-if="moduleField.field_type_id == 13")
                                            input-radio(:value="$data['localValue'][moduleField.name]" :moduleOptions="moduleField.options" v-model="$data['localValue'][moduleField.name]" )
                                          template(v-else-if="moduleField.field_type_id == 14")
                                            input-checkbox(:value="$data['localValue'][moduleField.name]" :moduleOptions="moduleField.options" v-model="$data['localValue'][moduleField.name]" )
                                          template(v-else-if="moduleField.field_type_id == 15")
                                            input-date(:value="$data['localValue'][moduleField.name]" v-model="$data['localValue'][moduleField.name]")
                                          template(v-else-if="moduleField.field_type_id == 16")
                                            input-color(:value="$data['localValue'][moduleField.name]" v-model="$data['localValue'][moduleField.name]")
                                          template(v-else-if="moduleField.field_type_id == 17")
                                            input-time(:value="$data['localValue'][moduleField.name]" v-model="$data['localValue'][moduleField.name]")
                                          template(v-else-if="moduleField.field_type_id == 18")
                                            input-product(
                                              :value="$data['localValue'][moduleField.name]" 
                                              :moduleOptions="moduleField.options" 
                                              :relatedKey="moduleField.related_field ? moduleField.related_field : ''"
                                              :relatedValue="moduleField.related_field ? $data['localValue'][moduleField.related_field] : ''"
                                              v-model="$data['localValue'][moduleField.name]" 
                                            )
                                          template(v-if="$data['errors'][moduleField.name]")
                                            span.text-danger(v-for="error in $data['errors'][moduleField.name]") {{error}}
</template>
<script>
import InputText from "./inputs/Text.vue"
import InputNumber from "./inputs/Number.vue"
import InputEmail from "./inputs/Email.vue"
import InputPassword from "./inputs/Password.vue"
import InputDate from "./inputs/Date.vue"
import InputTime from "./inputs/Time.vue"
import InputFile from "./inputs/File.vue"
import InputTextarea from "./inputs/Textarea.vue"
import InputWysiwyg from "./inputs/Wysiwyg.vue"
import InputSelect from "./inputs/Select.vue"
import InputMultiSelect from "./inputs/MultiSelect.vue"
// import InputRadio from "./inputs/Radio.vue"
import InputCheckbox from "./inputs/Checkbox.vue"
import InputColor from "./inputs/Color.vue"
import InputProduct from "./inputs/Product.vue"
export default {
  name: 'DrawForm',
  components: {
    InputText,
    InputNumber,
    InputEmail,
    InputPassword,
    InputDate,
    InputTime,
    InputFile,
    InputTextarea,
    InputWysiwyg,
    InputSelect,
    InputMultiSelect,
   // InputRadio,
    InputCheckbox,
    InputColor,
    InputProduct,
  },
  props: {
    title: {type: String, default: 'Crear o modificar un elemento'},
    previusUrl: {type: String, default: ''},
    module: {type: Object, default: function() {return {} }},
    value: {type: Object, default: function() {return {} }},
  },
  data () {
    return {
      localValue: {},
      currentTab: '',
      errors: {}
    }
  },
  beforeMount() {
    if(this.module && this.module.module_tabs) {
        let firstTab = this.module.module_tabs[0]
        this.currentTab = firstTab.id
      }
  },
  watch: {
    value(newVal) {
      if(newVal) {
        this.localValue = newVal
      }
    },
    module(newVal) {
      if(newVal && newVal.module_tabs) {
        let firstTab = newVal.module_tabs[0]
        this.currentTab = firstTab.id
      }
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    setTab(tab) {
      this.currentTab = tab
    },
    saveForm() {
      this.errors = {}

      if(this.localValue.id) {
        this.$store.dispatch("updateRegister", {
          moduleUrl: this.module.url,
          id: this.localValue.id,
          data: this.localValue
        }).then((res) => {
          if(res.errors) {
            this.errors = res.errors
          } else {
            this.$router.go(-1)
          }
        })
      } else {
        this.$store.dispatch("createRegister", {
          moduleUrl: this.module.url,
          data: this.localValue
        }).then((res) => {
          if(res.errors) {
            this.errors = res.errors
          } else {
            if(this.module.url_after_create) {
              this.$router.push(this.module.url_after_create.replace("##ID##", res.id))
            } else {
              this.$router.go(-1)
            }
          }
        })
      }
    }
  }
}
</script>
<style scoped>
</style>