<template lang="pug">
  .chat-room-chat    
    .chat-item
      .comment.small.mb-3(:class="!message.user ? '' : 'comment-end'")
        .comment-content(:class="!message.user ? 'bg-primary-opacity-50' : 'bg-secondary-opacity-50'")
          template(v-if="messageContentTypeId == 1")
            p.mb-0 {{message.message_content.content}}
          template(v-else-if="messageContentTypeId == 2")
            .figure(v-if="messageContentContent.url")
              a(:href="messageContentContent.url" target="_blank")
                img(
                    :src="messageContentContent.url" 
                    style="max-width: 200px"
                )
                p.mt-1.mb-0(v-if="messageContentContent.caption") {{messageContentContent.caption}}
            .figure(v-else)
              a(:href="messageContentContent" target="_blank")
                  img(
                      :src="messageContentContent" 
                      style="max-width: 200px"
                  )
          template(v-if="messageContentTypeId === 3")
            p.has-text-centered(v-if="messageContentContent.url")
              a(:href="messageContentContent.url" target="_blank")
                a(
                  :href="messageContentContent.url" 
                  target="_blank"
                )
                  font-awesome-icon(
                      icon="cloud-download-alt" 
                      size="4x"
                  )
                  br
                  span {{messageContentContent.url.substring(messageContentContent.url.lastIndexOf('/')).replace('/', '')}}
                p.mt-1.mb-0(v-if="messageContentContent.caption") {{messageContentContent.caption}}
            p.has-text-centered(v-else)
              a(
                :href="message.message_content.content" 
                target="_blank"
              )
                font-awesome-icon(
                    icon="cloud-download-alt" 
                    size="4x"
                )
                br
                span {{message.message_content.content.substring(message.message_content.content.lastIndexOf('/')).replace('/', '')}}
          template(v-else-if="messageContentTypeId == 4")
            a(:href="message.message_content.content" target="_blank").mb-0 {{message.message_content.content}}
          template(v-else-if="messageContentTypeId == 5")
            div(v-if="messageContentContent.url")
              audio(style="max-width: 100%" controls)
                source(:src="messageContentContent.url")
              p.mt-1.mb-0(v-if="messageContentContent.caption") {{messageContentContent.caption}}
            div(v-else)
              audio(style="max-width: 100%" controls)
                source(:src="messageContentContent")
          template(v-else-if="messageContentTypeId == 6")
            div(v-if="messageContentContent.url")
              video(style="max-width: 100%" controls)
                source(:src="messageContentContent.url")
              p.mt-1.mb-0(v-if="messageContentContent.caption") {{messageContentContent.caption}}
            div(v-else)
              video(style="max-width: 100%" controls)
                source(:src="messageContentContent")
        .comment-footer
          template(v-if="!message.user")
            .d-flex.mt-2
              .message-item-icon.me-2.text-center
                span.message-icon {{ message.account.name | string2Initials }}
              div
                strong {{message.account.name}}
                br
                span {{message.sended_at | formatFullTime}}
          template(v-else)
            .d-flex.justify-content-end.mt-2
              div
                strong {{message.user.name}}
                br
                span {{message.sended_at | formatFullTime}}
              .message-item-icon.ms-2.text-center
                span.message-icon {{ message.user.name | string2Initials }}
    div(style="clear:both;")
    template(v-if="message.message_message_ends" v-for="message_message_end in message.message_message_ends")
      .bg-info-opacity-25.mt-2.mb-2.p-2.text-center.rounded
        strong {{message_message_end.message_end.name}}
        br
        small {{message_message_end.user.name}}
</template>
<script>
  export default {
    name: 'ChatRoomMessageItem',
    props: {
      message: {type: Array, default: function() {return {}}},
    },
    data () {
      return {
        
      }
    },
    computed: {
      currentUser() {
        return this.$store.state.currentUser
      },
      selectedChatRoom() {
        return this.$store.state.chatRoomModule.selectedChatRoom
      },
      messageContentTypeId() {
        return this.message.message_content.message_content_type_id
      },
      messageContentContent() {
        if(this.message && this.message.message_content && this.message.message_content.content) {
          if(this.IsJsonString(this.message.message_content.content)) {
            console.log(JSON.parse(this.message.message_content.content))
            return JSON.parse(this.message.message_content.content)
          } else {
            return this.message.message_content.content
          }
        } else {
          return ''
        }
      },
    },
    mounted() {
      
    },
    methods: {
      IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
      }
    }
  }
</script>
<style lang="scss">
@import "../../scss/colors.scss";
.message-item-icon {
  width: 40px;
}
.message-item-icon span.message-icon {
  font-size: 14px;
  color: $info;
  border: 2px solid $info;
  border-radius: 50%;
  padding: 5px;
  display: inline-block;
  width: 35px;
  height: 35px;
}
</style>