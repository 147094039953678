import appService from "./appService";

const queuesService = {
    getMyQueues() {
        return appService.get('/queues/my-queues')
            .then((res) => res.data)
    },
    getMyHiddenQueues() {
        return appService.get('/queues/my-hidden-queues')
            .then((res) => res.data)
    },
    getDepartmentsQueues(data) {
        return appService.get('/queues/get-by-departments', data)
            .then((res) => res.data)
    },
    getQueuesByIds(data) {
        return appService.post('/queues/get-by-ids', data)
            .then((res) => res.data)
    },
    getQueuesTotalCallsByHours(data) {
        return appService.post('/queues/get-total-calls-by-hour', data)
            .then((res) => res.data)
    },
    updateQueueExtensions(queueId, data) {
        return appService.post('/queues/' + queueId + '/update-extensions', data)
            .then((res) => res.data)
    },
    getQueueExtensions(queueId, data) {
        return appService.get('/queues/' + queueId + '/get-extensions-statuses', data)
            .then((res) => res.data)
    },
    pauseAllQueues(extensionId) {
        return appService.post('/extensions/' + extensionId + '/pause-all')
            .then((res) => res.data)
    },
    unpauseAllQueues(extensionId) {
        return appService.post('/extensions/' + extensionId + '/unpause-all')
            .then((res) => res.data)
    },
    pauseExtensionQueue(extensionId, queueId) {
        return appService.post('/extensions/' + extensionId + '/pause/' + queueId)
            .then((res) => res.data)
    },
    unpauseExtensionQueue(extensionId, queueId) {
        return appService.post('/extensions/' + extensionId + '/unpause/' + queueId)
            .then((res) => res.data)
    },
}

export default queuesService;