// import Vue from 'vue'
import timeGroupsService from '../services/api/time_groups'

const timeGroupModule = {
    actions: {
        timeGroupSaveOptions (context, payload) {
            return new Promise((resolve, reject) => {
                timeGroupsService.saveOptions(payload.timeGroupId)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
    }
}

export default timeGroupModule