<template lang="pug">
  .generate-delivery-note                        
    #generateDeliveryNoteModal.modal(tabindex='-1' aria-hidden="true") 
      .modal-dialog.modal-lg
        .modal-content
          .modal-header
            h5.modal-title Generar un albarán de envío
            button#generateDeliveryNoteModalClose.btn-close(type='button' data-bs-dismiss='modal' aria-label='Close')
          .modal-body
            draw-simp-form(
              :module="currentModule" 
              :value="deliveryNoteData"
              @createRegister="createDeliveryNote"
            )
</template>

<script>
  import jQuery from 'jquery'
  import DrawSimpForm from '../forms/DrawSimp.vue'
  export default {
    name: 'GenerateDeliveryNote',
    components: {
      DrawSimpForm
    },
    props: {
      legalDocument: {type: Object, default: function() {return [] }},
    },
    data () {
      return {
        currentModule: {},
        deliveryNoteData: {},
      }
    },
    beforeMount() {
      this.$store.dispatch("getModuleByUrl", {moduleUrl: 'delivery_notes'})
        .then((res) => {
          this.currentModule = res
          this.setDeliveryNoteData();
        })
    },
    methods: {
      setDeliveryNoteData() {
        this.deliveryNoteData = {}
        this.deliveryNoteData.parent_legal_document_id = this.legalDocument.id
        this.deliveryNoteData.account_id = this.legalDocument.account_id
        if(this.legalDocument.invoice_account_address_id) {
          this.deliveryNoteData.shipping_account_address_id = this.legalDocument.invoice_account_address_id
        } else if(this.legalDocument.shipping_account_address_id) {
          this.deliveryNoteData.shipping_account_address_id = this.legalDocument.shipping_account_address_id
        } else if(this.legalDocument.shipping_legal_document_address.address) {
          this.deliveryNoteData.shipping_name = this.legalDocument.shipping_legal_document_address.name
          this.deliveryNoteData.shipping_code = this.legalDocument.shipping_legal_document_address.code
          this.deliveryNoteData.shipping_address = this.legalDocument.shipping_legal_document_address.address
          this.deliveryNoteData.shipping_address_aux = this.legalDocument.shipping_legal_document_address.address_aux
          this.deliveryNoteData.shipping_postal_code = this.legalDocument.shipping_legal_document_address.postal_code
          this.deliveryNoteData.shipping_location = this.legalDocument.shipping_legal_document_address.location
          this.deliveryNoteData.shipping_region = this.legalDocument.shipping_legal_document_address.region
          this.deliveryNoteData.shipping_country_id = this.legalDocument.shipping_legal_document_address.country_id
        } else {
          this.deliveryNoteData.shipping_name = this.legalDocument.invoice_legal_document_address.name
          this.deliveryNoteData.shipping_code = this.legalDocument.invoice_legal_document_address.code
          this.deliveryNoteData.shipping_address = this.legalDocument.invoice_legal_document_address.address
          this.deliveryNoteData.shipping_address_aux = this.legalDocument.invoice_legal_document_address.address_aux
          this.deliveryNoteData.shipping_postal_code = this.legalDocument.invoice_legal_document_address.postal_code
          this.deliveryNoteData.shipping_location = this.legalDocument.invoice_legal_document_address.location
          this.deliveryNoteData.shipping_region = this.legalDocument.invoice_legal_document_address.region
          this.deliveryNoteData.shipping_country_id = this.legalDocument.invoice_legal_document_address.country_id
        }
      },
      createDeliveryNote(res) {
        this.$router.push('/delivery_notes/' + res.id)
        this.deliveryNoteData = {}
        jQuery('#generateDeliveryNoteModalClose').trigger('click')
      }
    }
  }
</script>