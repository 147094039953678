import appService from "./appService";

const callEndsService = {
    getCurrentUserCallEnds() {
        return appService.get('/call_ends/get-current-user-call-ends')
            .then((res) => res.data)
    },
    assignCallEndToCall(data) {
        return appService.post('/call_ends/assign-to-call', data)
            .then((res) => res.data)
    },
}

export default callEndsService;