// import Vue from 'vue'
import messageTemplatesService from '../services/api/message_templates'

const messageTemplateModule = {
    state: {
        messageTemplates: [],
    },
    mutations: {
        setMessageTemplates (state, messageTemplates) {
            state.messageTemplates = messageTemplates
        }
    },
    actions: {
        getUserMessageTemplates (context, payload) {
            return new Promise((resolve, reject) => {
                messageTemplatesService.getUserMessageTemplates(payload.messageTypeId)
                    .then((res) => {
                        context.commit("setMessageTemplates", res)
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        
    }
}

export default messageTemplateModule