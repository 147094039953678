// import Vue from 'vue'
import departmentsService from '../services/api/departments'

const departmentModule = {
    state: {
        departmentsStructure: [],
        departments: [],
    },
    mutations: {
        setDepartmentsStructure (state, departmentsStructure) {
            state.departmentsStructure = departmentsStructure
        },
        setDepartments (state, departments) {
            state.departments = departments
        },
    },
    actions: {
        getDepartmentsStructure (context) {
            return new Promise((resolve, reject) => {
                departmentsService.getDepartmentsStructure()
                    .then((res) => {
                        context.commit("setDepartmentsStructure", res)
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        getDepartmentsStructureId (context, payload) {
            return new Promise((resolve, reject) => {
                departmentsService.getDepartmentsStructureId(payload)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        getDepartmentsList (context) {
            return new Promise((resolve, reject) => {
                departmentsService.getDepartmentsList()
                    .then((res) => {
                        context.commit("setDepartments", res)
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        
    }
}

export default departmentModule