import appService from "./appService";

const whatsappNumbersService = {
    addUser(whatsappNumberId, data) {
        return appService.post('/whatsapp_numbers/' + whatsappNumberId +'/add-user', data)
            .then((res) => res.data)
    },
    removeUser(whatsappNumberId, data) {
        return appService.post('/whatsapp_numbers/' + whatsappNumberId +'/remove-user', data)
            .then((res) => res.data)
    },
}

export default whatsappNumbersService;