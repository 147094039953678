// import Vue from 'vue'
import accountsService from '../services/api/accounts'

const accountModule = {
    state: {
        account: {},
    },
    mutations: {
        setAccount (state, account) {
            state.account = account
        }
    },
    actions: {
        getAccountUninvoicedElements (context, payload) {
            return new Promise((resolve, reject) => {
                accountsService.getAccountUninvoicedElements(payload.accountId)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        getAccountByCodeNameCif (context, payload) {
            return new Promise((resolve, reject) => {
                accountsService.getAccountByCodeNameCif(payload)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        mergeAccounts (context, payload) {
            return new Promise((resolve, reject) => {
                accountsService.mergeAccounts(payload.accountId, payload.accountIdToMerge)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        
    }
}

export default accountModule