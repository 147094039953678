<template lang="pug">
  .call-modal
    #callModal.modal.fade(tabindex='-1' aria-labelledby='callModalLabel' aria-hidden='true')
      .modal-dialog.modal-xl
        .modal-content
          .d-flex.justify-content-between
            div.p-2
              h4
                span(v-if="call.account") {{call.account.name}}
                span(v-else) Sin contacto
              div
                small.ms-2.small(v-if="call.callType == 'out'") {{to}}
                small.ms-2.small(v-else) {{from}}
            div.p-2
              .text-end
                //.m-2(v-if="myCallState == 'Activa' || isRinging")
                  button.btn.btn-link.p-0.text-dark(@click="parkMainChannel()" title="Aparcar llamada") 
                    font-awesome-icon(:icon="['fas', 'parking']")
                //.m-2(v-else)
                  button.btn.btn-link.p-0.text-muted(type="button" title="Aparcar llamada") 
                    font-awesome-icon(:icon="['fas', 'parking']")
                //.m-2(v-if="myCallState == 'Activa' || isRinging")
                  button.btn.btn-link.p-0.text-dark(@click="attendedTransferOwnChannel()" title="Transferencia atendida")
                    font-awesome-icon(:icon="['fas', 'exchange-alt']")
                //.m-2(v-else)
                  button.btn.btn-link.p-0.text-muted(type="button" title="Transferencia atendida")
                    font-awesome-icon(:icon="['fas', 'exchange-alt']")
                //.m-2(v-if="myCallState == 'Activa' || isRinging")
                  button.btn.btn-link.p-0.text-dark(@click="redirectMainChannel()" title="Transferencia ciega")
                    font-awesome-icon(:icon="['fas', 'retweet']")
                //.m-2(v-else)
                  button.btn.btn-link.p-0.text-muted(title="Transferencia ciega")
                    font-awesome-icon(:icon="['fas', 'retweet']")
                //.m-2(v-if="myCallState == 'Activa' || isRinging")
                  button.btn.btn-link.p-0.text-dark(@click="hangupOwnChannel()" title="Colgar llamada")
                    font-awesome-icon(:icon="['fas', 'phone-slash']")
                //.m-2(v-else)
                  button.btn.btn-link.p-0.text-muted(type="button" title="Colgar llamada")
                    font-awesome-icon(:icon="['fas', 'phone-slash']")
                .m-2
                  button#callModalClose.btn-close(type='button' data-bs-dismiss='modal' aria-label='Close')
              div
                .input-select
                  v-select(:options="callCallEnds" :reduce="callEnd => callEnd.id"  label="name" v-model="callEndId")
          .mt-1.p-2
            .row.mb-2
              .col
                ul.nav.nav-tabs
                  li.nav-item
                    a.nav-link(href="#!" :class="tab == 'sumary' ? 'active' : 'text-dark'" @click="setTab('sumary')") Resumen
                  li.nav-item(v-if="false")
                    a.nav-link(href="#!" :class="tab == 'comments' ? 'active' : 'text-dark'" @click="setTab('comments')") Comentarios
                  li.nav-item(v-if="false")
                    a.nav-link(href="#!" :class="tab == 'account' ? 'active' : 'text-dark'" @click="setTab('account')") Cuenta
            .row(v-if="tab == 'account'")
              .col-6
                h5.card-title Información
                h5.card-title Comentarios de la cuenta

              .col-6
                h5.card-title Histórico llamadas
                table.table.table-bordered.table-sm
                  tbody
                    tr
                      td
                        small 01-01-2021 09:00:00 (13s)
                        br
                        span Roger Corominas, Administración
                        br
                        strong.text-primary Final de llamada
                    tr
                      td
                        small 01-01-2021 09:00:00 (13s)
                        br
                        strong.text-primary Final de llamada
                    tr
                      td
                        small 01-01-2021 09:00:00 (13s)
                        br
                        strong.text-primary Final de llamada
                    tr
                      td
                        small 01-01-2021 09:00:00 (13s)
                        br
                        strong.text-primary Final de llamada
            .row(v-if="tab == 'comments'")
              .col
                h5.card-title Comentarios
                .row
                  .col-8
                    .row
                      .col-xxl-8.col-xl-9.col-lg-10.col-md-12
                        .comment.mb-3(v-for="comment in comments")
                          .comment-content.bg-primary-opacity-25
                            p.mb-0 {{comment.comment}}
                          .comment-footer
                            .d-flex.justify-content-between
                              div
                                strong {{comment.user.name}}
                              div
                                span {{comment.created_at}}
                  .col-4
                    .input-textarea
                      textarea.form-control(v-model="comment" rows="3" placeholder="Añadir un comentario")
                      .d-grid.gap-2.mt-2
                        button.btn.btn-primary(@click="saveComment") Añadir comentario
            .row(v-if="tab == 'sumary'")
              .col-md-4
                h5.card-title Información
                table.table.table-bordered.table-sm.mb-2(v-if="call.id")
                  tbody
                    tr
                      td(style="width:50%")
                        small De
                        br
                        strong {{call.from}}
                      td
                        small Para
                        br
                        strong {{call.to}}
                    tr
                      td
                        small Tipo de llamada
                        br
                        strong {{call.call_type.name}}
                      td
                        small Estado
                        br
                        strong.text-primary {{call.call_status.name}}
                    tr
                      td
                        small Inicio
                        br
                        strong {{[call.start, "unix"] | unixToDateTime}}
                      td
                        small Final
                        br
                        strong {{[call.start + call.duration, "unix"] | unixToDateTime}}
                    tr
                      td
                        small Duración espera
                        br
                        strong {{call.duration_wait | timeInHoursMin}}
                      td
                        small Duración total
                        br
                        strong {{call.duration | timeInHoursMin}}
                    tr
                      td(colspan="2")
                        strong.text-primary(v-if="call.call_end_id && call.call_end") {{call.call_end.name}}
                table.table.table-bordered.table-sm.mb-2(v-else)
                  tbody
                    tr
                      td(style="width:50%")
                        small De
                        br
                        strong {{from}}
                      td
                        small Para
                        br
                        strong {{to}}
                    tr
                      td
                        small Tipo de llamada
                        br
                        strong {{call.callType}}
                      td
                        small Estado
                        br
                        strong.text-primary(v-if="call.callStatus") {{call.callStatus}}
                        strong.text-primary(v-else) {{myCallState}}
                    tr
                      td
                        small Inicio
                        br
                        strong {{[call.start, "unix"] | unixToDateTime}}
                      td
                        small Final
                        br
                        strong {{[call.start + call.duration, "unix"] | unixToDateTime}}
                    tr
                      td
                        small Duración espera
                        br
                        strong(v-if="call.duration_wait") {{call.duration_wait | timeInHoursMin}}
                        strong(v-else="-")
                      td
                        small Duración total
                        br
                        strong(v-if="call.duration") {{call.duration | timeInHoursMin}}
                        strong(:key="'callModal' + counterKey" v-else) {{[call.start, "unix"] | unixToDateTimeEn | diffToNow | timeInHoursMin}}
                h5.card-title Registro de la llamada
                table.table.table-bordered.table-sm
                  tbody
                    tr(v-for="callLog in sortedCallLog")
                      template(v-if="callLog.type == 'call_queue'")
                        td
                          small {{[callLog.start, "unix"] | unixToDateTime}} ({{callLog.duration | timeInHoursMin }})
                          br
                          strong.text-primary entramos en la cola {{callLog.name}} ({{callLog.number}})
                      template(v-else-if="callLog.type == 'call_connected' && callLog.answered == '0'")
                        td
                          small {{[callLog.start, "unix"] | unixToDateTime}} ({{callLog.duration_ring | timeInHoursMin }} - {{callLog.hangup_status}})
                          br
                          span suena {{callLog.name}} ({{callLog.number}})
                          template(v-if="callLog.user")
                            br
                            font-awesome-icon.me-2(icon="user")
                            span {{callLog.user}}
                      template(v-else-if="callLog.type == 'call_connected' && callLog.answered")
                        td
                          small {{[callLog.start, "unix"] | unixToDateTime}} ({{callLog.duration | timeInHoursMin }})
                          br
                          strong.text-success conectamos con {{callLog.name}} ({{callLog.number}})
                          template(v-if="callLog.user")
                            br
                            font-awesome-icon.me-2(icon="user")
                            span {{callLog.user}}
                      template(v-else-if="callLog.type == 'call_ivr'")
                        td
                          small {{[callLog.start, "unix"] | unixToDateTime}} 
                          br
                          span.text-info Menú {{callLog.name}} ({{callLog.option}})
                      template(v-else-if="callLog.type == 'call_voicemail' && callLog.path")
                        td
                          strong.text-danger Mensaje en el buzón de voz
                      template(v-else-if="callLog.type == 'call_voicemail' && !callLog.path")
                        td
                          strong.text-danger Buzón de voz sin mensaje
                      template(v-else-if="callLog.type == 'call_end'")
                        td
                          small {{[callLog.start, "unix"] | unixToDateTime}}
                          br
                          strong.text-danger asignamos el final {{callLog.name}}
                          template(v-if="callLog.user")
                            br
                            font-awesome-icon.me-2(icon="user")
                            span {{callLog.user}}
              .col-md-4
                h5.card-title Comentarios
                .row
                  .col-md-12
                    .comment.mb-3(v-for="comment in comments")
                      .comment-content.bg-primary-opacity-25
                        p.mb-0 {{comment.comment}}
                      .comment-footer
                        .d-flex.justify-content-between
                          div
                            strong {{comment.user.name}}
                          div
                            span {{comment.created_at}}
                .row
                  .col
                    .input-textarea
                      textarea.form-control(v-model="comment" rows="3")
                      .d-grid.gap-2.mt-2
                        button.btn.btn-primary(@click="saveComment") Añadir comentario

              .col-md-4
                h5.card-title Histórico llamadas
                table.table.table-bordered.table-sm
                  tbody
                    tr(v-for="lastCall in lastCalls")
                      td
                        span.badge(:class="getBackgroundStatus(lastCall.call_status_id)") {{lastCall.call_status.name}}
                        br
                        small {{[lastCall.start, "unix"] | unixToDateTime}} ({{lastCall.duration | timeInHoursMin}})
                        br
                        span(v-if="lastCall.answered_users" v-for="answeredUser in lastCall.answered_users") {{answeredUser.name}},&nbsp;
                        span(v-else) -
                        br
                        strong.text-primary(v-if="lastCall.call_end_id && lastCall.call_end") {{lastCall.call_end.name}}
</template>

<script>
  import {Offcanvas} from 'bootstrap'
  export default {
    name: 'CallModal',
    data () {
      return {
        trunks: ['T1a', 'T1b', 'T2A', 'T2B', 'T3a', 'T3b', 'T4a', 'T4b', 'T2a', 'T2b', 'TrunkMVL1', 'TrunkMVL2', 'TrunkMVL3', 'TrunkTD'],
        isInTransfer: false,
        isRinging: true,
        callEndId: 0,
        comment: '',
        comments: [],
        lastCalls: [],
        tab: 'sumary',
        commentsLinkedid: '',
        lastCallsAccountId: 0,
        callLog: []
      }
    },
    computed: {
      sortedCallLog() {
        if(this.callLog.length > 0) {
          let callLog = this.callLog
          return callLog.sort((a, b) => {
            if (a.start < b.start) {
              return -1
            } else if (a.start > b.start) {
              return 1
            } else if(a.type == 'call_ivr' && b.type != 'call_ivr') {
              return -1
            } else if(a.type != 'call_ivr' && b.type == 'call_ivr') {
              return 1
            } else if(a.type == 'call_queue' && b.type != 'call_queue') {
              return -1
            } else if(a.type != 'call_queue' && b.type == 'call_queue') {
              return 1
            } else if(a.type == 'call_connected' && b.type == 'call_connected' && a.answered == 0 && b.answered == 1) {
              return -1
            } else if(a.type == 'call_connected' && b.type == 'call_connected' && a.answered == 1 && b.answered == -1) {
              return 1
            }
            
            return 0
          })
        } else {
          return []
        }
      },
      call() {
        return this.$store.state.callModule.modalCall
      },
      counterKey() {
        return this.$store.state.counterKey
      },
      currentUser() {
        return this.$store.state.currentUser
      },
      channelToRedirect() {
        return this.$store.state.callModule.channelToRedirect
      },
      channelToTransfer() {
        return this.$store.state.callModule.channelToTransfer
      },
      channelInTransfer() {
        return this.$store.state.callModule.channelInTransfer
      },
      from() {
        return this.call.CallerIDNum
      },
      to() {
        return this.call.Exten
      },
      callState() {
        if(this.call && this.call.channels) {
          if(this.call.channels.every((c) => c.ChannelStateDesc == 'Up')) {
            return 'Activa'
          } else if(this.call.hold) {
            return 'Retenida'
          } else {
            return 'Inactiva'
          }
        } else {
          return 'Inactiva'
        }
      },
      myChannels() {
        let channels = []
        if(this.call && this.call.channels) {
          this.call.channels.forEach((channel) => {
            if(channel.name == this.currentUser.extension.number) {
              channels.push(channel)
            }
          })
        }

        return channels
      },
      myCallState() {
        if(this.myChannels.length == 0) {
          return 'Inactiva'
        } else if(this.call.hold) {
          return 'Retenida'
        } else if(this.myChannels.every((c) => c.ChannelStateDesc == 'Up')) {
          return 'Activa'
        } else if (this.isRinging){
          return 'Sonando'
        } else {
          return 'Inactiva'
        }
      },
      myCallEnds() {
        return this.$store.state.callEndModule.myCallEnds
      },
      callCallEnds() {
        if(this.call.id) {
          return this.myCallEnds[this.call.call_type.name.toLowerCase()]
        } else {
          return this.myCallEnds[this.call.callType]
        }
      }
    },
    watch: {
      myCallState(newVal) {
        if(newVal == 'Activa' || newVal == 'Inactiva') {
          this.isRinging = false
        }

        if(newVal == 'Inactiva') {
          let dateEn = this.$options.filters.unixToDateTimeEn([this.call.start, "unix"])
          this.$set(this.call, 'duration', this.$options.filters.diffToNow(dateEn))
        } else {
          this.$set(this.call, 'duration', 0)
        }
      },
      channelInTransfer(newVal, oldVal) {
        if(newVal != oldVal) {
          if(this.call.channels.some((c) => c.channel == newVal)) {
            this.isInTransfer = true
          }
        }
      },
      callEndId(newVal) {
        if(newVal && (!this.call.call_end_id || newVal != this.call.call_end_id)) {
          console.log('test')
          if(this.call.Linkedids) {
            console.log('test2')
            
            this.$store.dispatch("assignCallEndToCall", {
              call_end_id: newVal,
              linkedid: this.call.Linkedids[0]
            }).then((res) => {
              if(res.error) {
                this.$swal.fire({
                  title: 'No se pudo guardar el final de la llamada',
                  icon: 'warning',
                  showCancelButton: false
                })
              } else {
                let auxCall = this.call
                this.$set(auxCall, 'call_end', res.call_end)
                this.$set(auxCall, 'call_end_id', res.call_end.id)
                this.$store.commit("setModalCall", auxCall)
              }
            })
          } else if(this.call.call_linkedids) {
            console.log('test3')
            this.$store.dispatch("assignCallEndToCall", {
              call_end_id: newVal,
              linkedid: this.call.call_linkedids[0].linkedid
            }).then((res) => {
              if(res.error) {
                this.$swal.fire({
                  title: 'No se pudo guardar el final de la llamada',
                  icon: 'warning',
                  showCancelButton: false
                })
              } else {
                let auxCall = this.call
                this.$set(auxCall, 'call_end', res.call_end)
                this.$set(auxCall, 'call_end_id', res.call_end.id)
                this.$store.commit("setModalCall", auxCall)
              }
            })
          }
          
        }
      },
      call: {
        deep: true,
        handler(newVal) {
          this.callEndId = newVal.call_end_id

          let linkedid = '';
          if(newVal.Linkedids) {
            linkedid = newVal.Linkedids[0]
          } else if(newVal.call_linkedid) {
            linkedid = newVal.call_linkedid[0].linkedid
          } else if(newVal.call_linkedids) {
            linkedid = newVal.call_linkedids[0].linkedid
          }

          if(linkedid && linkedid != this.commentsLinkedid) {
            this.commentsLinkedid = linkedid
            this.$store.dispatch("getCallComments", {
              linkedid: linkedid
            }).then((res) => {
              this.comments = res
            })
          }

          let accountId = 0
          if(newVal.account_id) {
            accountId = newVal.account_id
          } else if(newVal.account && newVal.account.id) {
            accountId = newVal.account.id
          } else {
            accountId = 0
            this.lastCalls = []
            this.lastCallsAccountId = 0
          }

          if(accountId && this.lastCallsAccountId != accountId) {
            this.lastCalls = []
            this.lastCallsAccountId = accountId
            this.$store.dispatch("getAccountLastCalls", {
              accountId: accountId
            }).then((res) => {
              this.lastCalls = res
            })
          }

          this.callLog = []
          if(newVal.id) {
            this.getCallLog(newVal.id)
          }
        }
      }
    },
    methods: {
      getCallLog(callId) {
        this.$store.dispatch("getCallLog", {
          callId: callId
        }).then((res) => {
          this.callLog = res
        })
      },
      getBackgroundStatus(callStatusId) {
        let callStatusClass = 'bg-primary'

        switch(callStatusId) {
          case 2:
            callStatusClass = 'bg-success'
            break
          case 3:
            callStatusClass = 'bg-warning'
            break
          case 4:
            callStatusClass = 'bg-danger'
            break
        }

        return callStatusClass
      },
      setTab(tab) {
        this.tab = tab
      },
      saveComment() {
        if(this.comment) {
          let linkedid = '';
          if(this.call.Linkedids) {
            linkedid = this.call.Linkedids[0]
          } else if(this.call.call_linkedid) {
            linkedid = this.call.call_linkedid[0].linkedid
          } else if(this.call.call_linkedids) {
            linkedid = this.call.call_linkedids[0].linkedid
          }

          this.$store.dispatch("assignCommentToCall", {
            comment: this.comment,
            linkedid: linkedid
          }).then((res) => {
            if(res.error) {
              this.$swal.fire({
                title: 'No se pudo guardar el comentario',
                icon: 'warning',
                showCancelButton: false
              })
            } else {
              this.comment = ''
            }
          })
        }
      },
      getMainChannel() {
        let getMainChannel = ''

        this.call.channels.forEach((channel) => {
          let indexSlash = channel.channel.indexOf('/')
          let indexDash = channel.channel.indexOf('-')

          // let channelType = channel.channel.substring(0, indexSlash)
          let channelName = channel.channel.substring(indexSlash + 1, indexDash)
          // let channelUnique = channel.channel.substring(indexDash + 1)

          let indexTrunk = this.trunks.findIndex(x => x == channelName)

          if(indexTrunk > -1) {
            getMainChannel = channel.channel
          }
        })

        return getMainChannel
      },
      getOwnChannel() {
        let getOwnChannel = ''

        this.call.channels.forEach((channel) => {
          let indexSlash = channel.channel.indexOf('/')
          let indexDash = channel.channel.indexOf('-')

          // let channelType = channel.channel.substring(0, indexSlash)
          let channelName = channel.channel.substring(indexSlash + 1, indexDash)
          // let channelUnique = channel.channel.substring(indexDash + 1)

          if(channelName == this.currentUser.extension.number) {
            getOwnChannel = channel.channel
          }
        })

        return getOwnChannel
      },
      hangupOwnChannel() {
        let ownChannel = this.getOwnChannel()

        if(ownChannel) {
          this.isRinging = false
          this.$store.dispatch("hangupChannel", ownChannel)
        }
      },
      parkMainChannel() {
        let mainChannel = this.getMainChannel()

        if(mainChannel) {
          this.isRinging = false
          this.$store.dispatch("parkChannel", mainChannel)
        }
      },
      redirectMainChannel() {
        let mainChannel = this.getMainChannel()

        if(!this.channelToRedirect) {
          if(mainChannel) {
            this.$store.commit("setChannelToRedirect", mainChannel)

            let offcanvasUsers = document.getElementById('offcanvasUsers')
            if(offcanvasUsers.style.visibility != 'visible') {
              new Offcanvas(offcanvasUsers).show()
            }
          }
        } else if(mainChannel == this.channelToRedirect) {
          this.$store.commit("setChannelToRedirect", '')
        }
      },
      attendedTransferOwnChannel() {
        let mainChannel = this.getOwnChannel()

        if(!this.channelToTransfer) {
          if(mainChannel) {
            this.$store.commit("setChannelToTransfer", mainChannel)
            this.isInTransfer = true

            let offcanvasUsers = document.getElementById('offcanvasUsers')
            if(offcanvasUsers.style.visibility != 'visible') {
              new Offcanvas(offcanvasUsers).show()
            }
          }
        } else if(mainChannel == this.channelToTransfer) {
          this.$store.commit("setChannelToTransfer", '')
          this.isInTransfer = false
        }
      }
    }
  }
</script>
<style lang="scss">
  
</style>