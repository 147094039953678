// import Vue from 'vue'
import reportsService from '../services/api/reports'

const reportModule = {
    state: {
        reports: [],
    },
    mutations: {
        setReports (state, reports) {
            state.reports = reports
        }
    },
    actions: {
        getMyReports (context) {
            return new Promise((resolve, reject) => {
                reportsService.getMyReports()
                    .then((res) => {
                        context.commit("setReports", res)
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        getReportReportItemData (context, payload) {
            return new Promise((resolve, reject) => {
                reportsService.getReportReportItemData(payload.reportReportItemId, payload.data)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        
    }
}

export default reportModule