<template lang="pug">
  .my-call-item
    .toast.d-block(role='alert' @mouseover="setHover(true)" @mouseleave="setHover(false)") 
      .toast-header(:class="isRinging ? 'blink-slow ' + getCallBackground() : getCallBackground() " @click="setModalCall()")
        strong.me-auto(v-if="call.account") {{call.account.name}}
        strong.me-auto(v-else-if="call.callType == 'in' || (user && call.callType == 'internal' && call.Exten == currentUser.extension.number)") {{call.CallerIDNum}}
        strong.me-auto(v-else-if="call.callType == 'out' || (user && call.callType == 'internal' && call.CallerIDNum == user.extension.number)") {{call.Exten}}
        strong.me-auto(v-else) {{call.CallerIDNum}} {{call.Exten}}
        small(v-if="myCallState == 'Activa'" :key="'callStart-' + (user ? user.id : '')  + '-' + call.Linkedids[0] + '-' + counterKey") {{[call.start, "unix"] | unixToDateTimeEn | diffToNow | timeInHoursMin}}
        button.btn-close(type='button' data-bs-dismiss='toast' aria-label='Close' @click.stop="closeMyCall()")
      .toast-body
        .row
          .col
            router-link.me-auto(v-if="call.account" :to="'/accounts/' + call.account.id" ) {{call.account.name}}
            span.me-auto(v-else) Sin contacto
            .mt-2(v-if="call.account && call.account.tags")
              span.badge.bg-primary.me-2(v-for="tag in call.account.tags") {{tag.name}}
            br
            span.me-auto(v-if="call.callType == 'in' || (user && call.callType == 'internal' && call.Exten == currentUser.extension.number)") {{call.CallerIDNum}}
            span.me-auto(v-else-if="call.callType == 'out' || (user && call.callType == 'internal' && call.CallerIDNum == user.extension.number)") {{call.Exten}}
            span.me-auto(v-else) {{call.CallerIDNum}} {{call.Exten}}
        .row.mt-2
          .col(v-if="myCallTab == 'callEnd'")
            label.form-label Final de la llamada
            .d-flex
              v-select.flex-grow-1(:options="callCallEnds" :reduce="callEnd => callEnd.id" label="name" v-model="callEndId" append-to-body :calculate-position="withPopper")
              button.btn.btn-primary(type="button" @click="saveCallEndId()")
                font-awesome-icon(icon="save")
          .col(v-if="myCallTab == 'comment'")
            label.form-label Comentario
            .input-textarea
              textarea.form-control(v-model="comment" rows="3")
              .d-grid.gap-2.mt-2
                button.btn.btn-primary(@click="saveComment") Añadir comentario
        .row.g-0.mt-2(v-if="hover")
          .col.small.pt-0.pb-0
            .d-flex.justify-content-around
              div
                template(v-if="companyConfigs && companyConfigs.call_call_end_button && companyConfigs.call_call_end_button == 1")
                  button.btn.btn-link.p-0(@click="setMyCallTab('callEnd')" title="Indicar Final de la llamada" :class="myCallTab == 'callEnd' ? 'text-primary' : 'text-dark'") 
                    font-awesome-icon(:icon="['fas', 'flag-checkered']")
              div
                template(v-if="companyConfigs && companyConfigs.call_comments_button && companyConfigs.call_comments_button == 1")
                  button.btn.btn-link.p-0.position-relative(@click="setMyCallTab('comment')" title="Añadir un comentario" :class="myCallTab == 'comment' ? 'text-primary' : 'text-dark'") 
                    font-awesome-icon(:icon="['fas', 'comments']")
                    span.position-absolute.top-0.start-100.translate-middle.p-1.bg-danger.border.border-light.rounded-circle.blink-slow(v-if="call.comments && call.comments.length > 0")
                      span.visually-hidden {{call.comments.length}}
              div(v-if="myCallState == 'Activa' || isRinging")
                template(v-if="companyConfigs && companyConfigs.call_park && companyConfigs.call_park == 1")
                  button.btn.btn-link.p-0.text-dark(@click="parkMainChannel()" title="Aparcar llamada") 
                    font-awesome-icon(:icon="['fas', 'parking']")
              div(v-else)
                template(v-if="companyConfigs && companyConfigs.call_park && companyConfigs.call_park == 1")
                  button.btn.btn-link.p-0.text-muted(type="button" title="Aparcar llamada") 
                    font-awesome-icon(:icon="['fas', 'parking']")
              div(v-if="myCallState == 'Activa' || isRinging")
                template(v-if="companyConfigs && companyConfigs.call_attended_transfer && companyConfigs.call_attended_transfer == 1")
                  button.btn.btn-link.p-0.text-dark(@click="attendedTransferOwnChannel()" title="Transferencia atendida")
                    font-awesome-icon(:icon="['fas', 'exchange-alt']")
              div(v-else)
                template(v-if="companyConfigs && companyConfigs.call_attended_transfer && companyConfigs.call_attended_transfer == 1")
                  button.btn.btn-link.p-0.text-muted(type="button" title="Transferencia atendida")
                    font-awesome-icon(:icon="['fas', 'exchange-alt']")
              div(v-if="myCallState == 'Activa' || isRinging")
                template(v-if="companyConfigs && companyConfigs.call_blind_transfer && companyConfigs.call_blind_transfer == 1")
                  button.btn.btn-link.p-0.text-dark(@click="redirectMainChannel()" title="Transferencia ciega")
                    font-awesome-icon(:icon="['fas', 'retweet']")
              div(v-else)
                template(v-if="companyConfigs && companyConfigs.call_blind_transfer && companyConfigs.call_blind_transfer == 1")
                  button.btn.btn-link.p-0.text-muted(title="Transferencia ciega")
                    font-awesome-icon(:icon="['fas', 'retweet']")
              div(v-if="myCallState == 'Retenida' || myCallState == 'Activa' || isRinging")
                template(v-if="companyConfigs && companyConfigs.call_hangup && companyConfigs.call_hangup == 1")
                  button.btn.btn-link.p-0.text-dark(@click="hangupOwnChannel()" title="Colgar llamada")
                    font-awesome-icon(:icon="['fas', 'phone-slash']")
              div(v-else)
                template(v-if="companyConfigs && companyConfigs.call_hangup && companyConfigs.call_hangup == 1")
                  button.btn.btn-link.p-0.text-muted(type="button" title="Colgar llamada")
                    font-awesome-icon(:icon="['fas', 'phone-slash']")
</template>

<script>
  import {Offcanvas, Modal} from 'bootstrap'
  import { createPopper } from '@popperjs/core'
  export default {
    name: 'MyCallItem',
    props: { 
      call: Object,
      index: Number
    },
    data () {
      return {
        hover: false,
        trunks: ['T1a', 'T1b', 'T2A', 'T2B', 'T3a', 'T3b', 'T4a', 'T4b', 'T2a', 'T2b', 'TrunkMVL1', 'TrunkMVL2', 'TrunkMVL3', 'TrunkTD'],
        isInTransfer: false,
        isRinging: true,
        isAnswered: false,
        callEndId: 0,
        comment: '',
        myCallTab: 'callEnd'
      }
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      currentUser() {
        return this.$store.state.currentUser
      },
      companyConfigs() {
        return this.$store.state.companyConfigs
      },
      channelToRedirect() {
        return this.$store.state.callModule.channelToRedirect
      },
      channelToTransfer() {
        return this.$store.state.callModule.channelToTransfer
      },
      channelInTransfer() {
        return this.$store.state.callModule.channelInTransfer
      },
      callState() {
        if(this.call.channels.every((c) => c.ChannelStateDesc == 'Up')) {
          return 'Activa'
        } else if(this.call.hold) {
          return 'Retenida'
        } else {
          return 'Inactiva'
        }
      },
      myChannels() {
        let channels = []
        this.call.channels.forEach((channel) => {
          if(channel.name == this.currentUser.extension.number) {
            channels.push(channel)
          }
        })

        return channels
      },
      myCallState() {
        if(this.myChannels.length == 0) {
          return 'Inactiva'
        } else if(this.call.hold) {
          return 'Retenida'
        } else if(this.myChannels.every((c) => c.ChannelStateDesc == 'Up')) {
          return 'Activa'
        } else if (this.isRinging){
          return 'Sonando'
        } else {
          return 'Inactiva'
        }
      },
      /*myCallState() {
        if(this.myChannels.length == 0) {
          return 'Inactiva'
        } else if(this.call.hold) {
          return 'Retenida'
        } else if(this.myChannels.every((c) => c.ChannelStateDesc == 'Up')) {
          return 'Activa'
        } else {
          return 'Inactiva'
        }
      },*/
      myCallEnds() {
        return this.$store.state.callEndModule.myCallEnds
      },
      callCallEnds() {
        return this.myCallEnds[this.call.callType]
      }
    },
    watch: {
      myCallState(newVal) {
        if(newVal == 'Activa' || newVal == 'Inactiva') {

          if(newVal == 'Activa' && this.isRinging) {
            this.isAnswered = true
            if(this.companyConfigs && this.companyConfigs.after_call_actions_on_ring && this.companyConfigs.after_call_actions_on_ring == 0) {
              this.$store.dispatch("getActionsAfterOpenCall", {
                Linkedid: this.call.Linkedids[0]
              }).then((res) => {
                if(res.url) {
                  if(!this.currentUser.active_rest_time || !this.currentUser.active_rest_time.id) {

                    window.open(res.url, '_blank').focus();
                  }
                } else if(res.urls) {
                  res.urls.forEach((url) => {
                    window.open(url, '_blank').focus();
                  })
                }
              })
            }
          } else if(newVal == 'Inactiva' && !this.isAnswered) {
            this.closeMyCall()
          }

          this.isRinging = false
        }
      },
      channelInTransfer(newVal, oldVal) {
        if(newVal != oldVal) {
          if(this.call.channels.some((c) => c.channel == newVal)) {
            this.isInTransfer = true
          }
        }
      },
      callEndId(newVal) {
        if(this.companyConfigs && this.companyConfigs.save_call_end_without_button == 1) {
          if(newVal != this.call.call_end_id) {
            this.$store.dispatch("assignCallEndToCall", {
              call_end_id: newVal,
              linkedid: this.call.Linkedids[0]
            }).then((res) => {
              if(res.error) {
                this.$swal.fire({
                  title: 'No se pudo guardar el final de la llamada',
                  icon: 'warning',
                  showCancelButton: false
                })
              }
            })
          }
        }
      },
      call: {
        deep: true,
        handler(newVal) {
          this.callEndId = newVal.call_end_id
        }
      }
    },
    beforeMount() {
      
    },
    methods: {
      withPopper(dropdownList, component, { width }) {
        /**
         * We need to explicitly define the dropdown width since
         * it is usually inherited from the parent with CSS.
         */
        dropdownList.style.width = width

        /**
         * Here we position the dropdownList relative to the $refs.toggle Element.
         *
         * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
         * the dropdownList overlap by 1 pixel.
         *
         * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
         * wrapper so that we can set some styles for when the dropdown is placed
         * above.
         */
        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: "top",
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -1],
              },
            },
            {
              name: 'toggleClass',
              enabled: true,
              phase: 'write',
              fn({ state }) {
                component.$el.classList.toggle(
                  'drop-up',
                  state.placement === 'top'
                )
              },
            },
          ],
        })

        /**
         * To prevent memory leaks Popper needs to be destroyed.
         * If you return function, it will be called just before dropdown is removed from DOM.
         */
        return () => popper.destroy()
      },
      saveCallEndId() {
        if(this.callEndId != this.call.call_end_id) {
          this.$store.dispatch("assignCallEndToCall", {
            call_end_id: this.callEndId,
            linkedid: this.call.Linkedids[0]
          }).then((res) => {
            if(res.error) {
              this.$swal.fire({
                title: 'No se pudo guardar el final de la llamada',
                icon: 'warning',
                showCancelButton: false
              })
            }
          })
        }
      },
      setModalCall() {
        this.$store.commit("setModalCall", this.call)
        let modalCall = document.getElementById('callModal')
        new Modal(modalCall).show() 
      },
      saveComment() {
        if(this.comment) {
          this.$store.dispatch("assignCommentToCall", {
            comment: this.comment,
            linkedid: this.call.Linkedids[0]
          }).then((res) => {
            if(res.error) {
              this.$swal.fire({
                title: 'No se pudo guardar el comentario',
                icon: 'warning',
                showCancelButton: false
              })
            } else {
              this.comment = ''
            }
          })
        }
      },
      setHover(hover) {
        this.hover = hover
      },
      setMyCallTab(myCallTab) {
        this.myCallTab = myCallTab
      },
      getCallBackground() {
        let background = ''

        if(this.myCallState == 'Activa') {
          background = 'bg-success-opacity-25'
        } else if(this.myCallState == 'Retenida') {
          background = 'bg-warning-opacity-25'
        } else if(this.myCallState == 'Inactiva' && this.isRinging) {
          background = 'bg-primary-opacity-25'
        }

        return background
      },
      getMainChannel() {
        let getMainChannel = ''

        this.call.channels.forEach((channel) => {
          let indexSlash = channel.channel.indexOf('/')
          let indexDash = channel.channel.indexOf('-')

          // let channelType = channel.channel.substring(0, indexSlash)
          let channelName = channel.channel.substring(indexSlash + 1, indexDash)
          // let channelUnique = channel.channel.substring(indexDash + 1)

          let indexTrunk = this.trunks.findIndex(x => x == channelName)

          if(indexTrunk > -1) {
            getMainChannel = channel.channel
          }
        })

        return getMainChannel
      },
      getOwnChannel() {
        let getOwnChannel = ''

        this.call.channels.forEach((channel) => {
          let indexSlash = channel.channel.indexOf('/')
          let indexDash = channel.channel.indexOf('-')

          // let channelType = channel.channel.substring(0, indexSlash)
          let channelName = channel.channel.substring(indexSlash + 1, indexDash)
          // let channelUnique = channel.channel.substring(indexDash + 1)

          if(channelName == this.currentUser.extension.number) {
            getOwnChannel = channel.channel
          }
        })

        return getOwnChannel
      },
      closeMyCall() {
        if(this.myCallState != 'Activa') {
          if(!this.companyConfigs || this.companyConfigs.close_call_without_call_end == 1 || this.callEndId || this.call.call_end || this.call.callType == 'internal') {
            this.$store.commit("deleteMyCallByIndex", this.index)
          }
        }
      },
      hangupOwnChannel() {
        let ownChannel = this.getOwnChannel()

        if(ownChannel) {
          this.isRinging = false
          this.$store.dispatch("hangupChannel", ownChannel)
        }
      },
      parkMainChannel() {
        let mainChannel = this.getMainChannel()

        if(mainChannel) {
          this.isRinging = false
          this.$store.dispatch("parkChannel", mainChannel)
        }
      },
      redirectMainChannel() {
        let mainChannel = this.getMainChannel()

        if(!this.channelToRedirect) {
          if(mainChannel) {
            this.$store.commit("setChannelToRedirect", mainChannel)

            let offcanvasUsers = document.getElementById('offcanvasUsers')
            if(offcanvasUsers.style.visibility != 'visible') {
              new Offcanvas(offcanvasUsers).show()
            }
          }
        } else if(mainChannel == this.channelToRedirect) {
          this.$store.commit("setChannelToRedirect", '')
        }
      },
      attendedTransferOwnChannel() {
        let mainChannel = this.getOwnChannel()

        if(!this.channelToTransfer) {
          if(mainChannel) {
            this.$store.commit("setChannelToTransfer", mainChannel)
            this.isInTransfer = true

            let offcanvasUsers = document.getElementById('offcanvasUsers')
            if(offcanvasUsers.style.visibility != 'visible') {
              new Offcanvas(offcanvasUsers).show()
            }
          }
        } else if(mainChannel == this.channelToTransfer) {
          this.$store.commit("setChannelToTransfer", '')
          this.isInTransfer = false
        }
      }
    }
  }
</script>
<style lang="scss">
</style>