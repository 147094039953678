import { render, staticRenderFns } from "./ChartSimpleColumn.vue?vue&type=template&id=028f0882&lang=pug&"
import script from "./ChartSimpleColumn.vue?vue&type=script&lang=js&"
export * from "./ChartSimpleColumn.vue?vue&type=script&lang=js&"
import style0 from "./ChartSimpleColumn.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports