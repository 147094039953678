<template lang="pug">
  li.list-group-item
    flowy-drag-handle
      span.icon.me-1
        font-awesome-icon(:icon="icon")
      span {{title}}
</template>

<script>
  export default {
    name: 'DemoBlock',
    props: ['remove', 'node', 'title', 'description', 'icon'],
    data () {
      return {
        text: 'This is component A'
      }
    },
  
  }
</script>