<template lang="pug">
  .report-total-call-by-call-status-hour
    .card.mb-3
      .card-body
        .d-flex.justify-content-between
          h5.card-title {{reportReportItem.name}}
          .btn-group
            button.btn.btn-sm(@click="showTab = 'in'" :class="showTab == 'in' ? 'btn-primary' : 'btn-outline-primary'") In
            button.btn.btn-sm(@click="showTab = 'out'" :class="showTab == 'out' ? 'btn-primary' : 'btn-outline-primary'") Out
            button.btn.btn-sm(@click="showTab = 'internal'" :class="showTab == 'internal' ? 'btn-primary' : 'btn-outline-primary'") Internal
        template(v-if="showChart && series && series.length > 0 && labels && labels.length > 0")
          chart-columns(v-if="series.length > 0" :series="series" :categories="labels" :colors="colors")
       
        table.table.text-center
          thead
            tr
              th Hora
              th(v-for="(header, index) in data.headers") {{index}}
          tbody(v-if="showTab == 'in'")
            tr(v-for="(row, index) in data.dataTable")
              td {{index}}
              td(v-for="col in row") {{col.in_total}}
          tbody(v-if="showTab == 'out'")
            tr(v-for="(row, index) in data.dataTable")
              td {{index}}
              td(v-for="col in row") {{col.out_total}}
          tbody(v-if="showTab == 'internal'")
            tr(v-for="(row, index) in data.dataTable")
              td {{index}}
              td(v-for="col in row") {{col.internal_total}}
</template>
<script>
  import ChartColumns from '../chart/ChartColumns.vue'
  export default {
    name: 'ReportTotalCallByCallStatusHour',
    components: {
      ChartColumns
    },
    props: {
      reportReportItem: {type: Object, default: function() {return {} }},
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        data: {},
        showTab: 'in',
        colors: ["#09ad95", "#f7b731", "#f82649", "#6259ca", "#45aaf2"],
        lastFilter: 0,
        showChart: true
      }
    },
    computed: {
      series() {
        if(this.data.dataChart && this.data.dataChart.length > 0 && this.data.headers) {
          let series = []
          Object.keys(this.data.headers).forEach((index) => {
            console.log(index)
            series.push({
              name: index,
              data: []
            })
          })

          this.data.dataChart.forEach((row) => {
            row.forEach((col) => {
              
              let index = series.findIndex((s) => s.name == col.call_status)

              if(this.showTab == 'in') {
                series[index].data.push(col.in_total)
              } else if(this.showTab == 'out') {
                series[index].data.push(col.out_total)
              } else {
                series[index].data.push(col.internal_total)
              }
            })
          })

          return series
        } else {
          return []
        }
      },
      labels() {
        return [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]
      }
    },
    watch: {
      filters: {
        deep: true,
        handler(newVal) {
          if(newVal.counter != this.lastFilter) {
            this.getData()
            this.lastFilter = newVal.counter
          }
        }
      }
    },
    mounted() {
    },
    methods: {
      getData() {
        this.showChart = false
        this.$store.dispatch("getReportReportItemData", {
          reportReportItemId: this.reportReportItem.id,
          data: {
            filters: this.filters
          }
        }).then((res) => {
          this.data = res
          this.showChart = true
        })
      }
    }
  }
</script>
<style lang="scss">
</style>