<template lang="pug">
  .dashboard-show
    .header-actions
      .header-actions-content
        .d-flex
          .flex-grow-1.p-2(v-if="dashboards")
            v-select(:options="dashboards" :reduce="dashboard => dashboard.id"  label="name" v-model="id" placeholder="Seleccione una ")
    .main-content
      .container-fluid(v-if="showTemplate")
        template(v-if="selectDashboard && selectDashboard.dashboard_template_id == 1")
          .row
            .col-lg-12(v-if="selectDashboardSections")
              div(v-for="dashboardDashboardItem in selectDashboardSections[1]")
                component(:is="dashboardDashboardItem.dashboard_item.component" :filters="dashboardDashboardItem.filters")
        template(v-else-if="selectDashboard && selectDashboard.dashboard_template_id == 2")
          .row
            .col-lg-6(v-if="selectDashboardSections && selectDashboardSections[1]")
              div(v-for="dashboardDashboardItem in selectDashboardSections[1]")
                component(:is="dashboardDashboardItem.dashboard_item.component"  :filters="dashboardDashboardItem.filters")
            .col-lg-6(v-if="selectDashboardSections && selectDashboardSections[2]")
              div(v-for="dashboardDashboardItem in selectDashboardSections[2]")
                component(:is="dashboardDashboardItem.dashboard_item.component"  :filters="dashboardDashboardItem.filters")
        template(v-else-if="selectDashboard && selectDashboard.dashboard_template_id == 3")
          .row
            .col-lg-8(v-if="selectDashboardSections && selectDashboardSections[1]")
              div(v-for="dashboardDashboardItem in selectDashboardSections[1]")
                component(:is="dashboardDashboardItem.dashboard_item.component"  :filters="dashboardDashboardItem.filters")
            .col-lg-4(v-if="selectDashboardSections && selectDashboardSections[2]")
              div(v-for="dashboardDashboardItem in selectDashboardSections[2]")
                component(:is="dashboardDashboardItem.dashboard_item.component"  :filters="dashboardDashboardItem.filters")
</template>

<script>
  import ScreenMyCallsWithoutEnd from "../../components/screens/ScreenMyCallsWithoutEnd.vue"
  import UserCalls from "../../components/calls/UserCalls.vue"
  export default {
    name: 'DashboardShow',
    components: {
      ScreenMyCallsWithoutEnd,
      UserCalls
    },
    beforeMount() {

      this.$store.dispatch("getMyDashboards", {
        dashboardTypeId: 1
      })
        .then(() => {
          if(this.$route.params.id) {
            this.id = parseInt(this.$route.params.id)
          } else if(this.dashboards && this.dashboards[0]) {
            this.id = this.dashboards[0].id
          }
        })
    
    },
    data () {
      return {
        id: 0,
        showTemplate: true,
      }
    },
    computed: {
      dashboards() {
        return this.$store.state.dashboardModule.dashboards
      },
      selectDashboard() {
        let selectDashboard = null
        if(this.dashboards) {
          let index = this.dashboards.findIndex((d) => d.id == this.id)

          if(index > -1) {
            selectDashboard = this.dashboards[index]
          }
        }

        return selectDashboard
      },
      selectDashboardSections() {
        let dashboardSections = {}

        if(this.selectDashboard) {
          this.selectDashboard.dashboard_dashboard_items.forEach((ddi) => {
            let ddiAux = JSON.parse(JSON.stringify(ddi))
            if(!dashboardSections[ddiAux.section]) {
              dashboardSections[ddiAux.section] = []
            }

            if(ddiAux.filters) {
              ddiAux.filters = JSON.parse(ddiAux.filters)
            } else {
              ddiAux.filters = {}
            }
            dashboardSections[ddiAux.section].push(ddiAux)
          })
        }

        return dashboardSections
      }
    },
    watch: {
      id() {
        this.showTemplate = false
        setTimeout(()=> {
          this.showTemplate = true
        }, 1000)
      }
    },
    methods: {
    }
  }
</script>