<template lang="pug">
  .account-phones
    .border.p-2
      .float-end
        button.btn.btn-primary.btn-sm(type='button' data-bs-toggle='modal' data-bs-target='#accountPhoneModal' @click="setDefaultAccountPhone")
          font-awesome-icon.me-2(icon="plus-circle")
          span Añadir
      strong Teléfonos
      table.table.table-border.table-sm
        tbody
          tr(v-for="accountPhone in accountPhones" :key="'accountPhone' + accountPhone.id")
            td
              div
                .float-end
                  button.btn.btn-link.text-dark(v-if="currentUser.extension_id" type='button' @click="makeCall(accountPhone)")
                    font-awesome-icon(icon="phone")
                  button.btn.btn-link.text-dark(v-if="accountPhoneModule.user_type_module.update == 1" type='button' data-bs-toggle='modal' data-bs-target='#accountPhoneModal' @click="editAccountPhone(accountPhone)")
                    font-awesome-icon(icon="edit")
                  button.btn.btn-link.text-dark(v-if="accountPhoneModule.user_type_module.delete == 1" type='button' @click="deleteAccountPhone(accountPhone)")
                    font-awesome-icon(:icon="['fas', 'trash']")
                strong {{accountPhone.fullphone}}
                br
                small {{accountPhone.name}}
              
                            
      #accountPhoneModal.modal(v-if="accountPhone" tabindex='-1' aria-hidden="true") 
        .modal-dialog.modal-lg
          .modal-content
            .modal-header
              h5.modal-title Añadir un teléfono
              button#accountPhoneModalClose.btn-close(type='button' data-bs-dismiss='modal' aria-label='Close')
            .modal-body
              draw-simp-form(
                :module="accountPhoneModule" 
                :value="accountPhone"
                @createRegister="createAccountPhone"
                @updateRegister="updateAccountPhone"
              )
</template>

<script>
  import jQuery from 'jquery'
  import DrawSimpForm from '../forms/DrawSimp.vue'
  export default {
    name: 'AccountPhones',
    components: {
      DrawSimpForm
    },
    props: {
      accountId: {type: Number, default: 0},
      accountPhones: {type: Array, default: function() {return [] }},
    },
    beforeMount() {
      
      this.$store.dispatch("getModuleByUrl", {moduleUrl: 'account_phones'})
        .then((res) => {
          this.accountPhoneModule = res
        })
    },
    data () {
      return {
        accountPhoneModule: {},
        accountPhone: {}
      }
    },
    computed: {
      currentUser() {
        return this.$store.state.currentUser
      },
    },
    methods: {
      setDefaultAccountPhone() {
        this.accountPhone = {name: '', phone: '', country_id: 1}
        this.accountPhone.account_id = this.accountId
      },
      makeCall(accountPhone) {
        this.$store.dispatch("makeCall", {
          phone: '0' + accountPhone.fullphone,
          extension: this.currentUser.extension.number
        })
      },
      editAccountPhone(accountPhone) {
        this.accountPhone = accountPhone
      },
      createAccountPhone(res) {
        this.$emit("createAccountPhone", res) 
        this.accountPhone = {}
        jQuery('#accountPhoneModalClose').trigger('click')
      },
      updateAccountPhone(res) {
        this.$emit("updateAccountPhone", res) 
        this.accountPhone = {}
        jQuery('#accountPhoneModalClose').trigger('click')
      },
      deleteAccountPhone(accountPhone) {
        this.$swal.fire({
          title: 'Está seguro que desea eliminar este elemento?',
          icon: 'warning',
          showCancelButton: true
        }).then((result) => {
          if(result) {
            this.$store.dispatch("deleteRegister", {
              moduleUrl: this.accountPhoneModule.url,
              id: accountPhone.id
            }).then((res) => {
              this.$emit("deleteAccountPhone", res) 
            })
          }
        });
      }
    }
  }
</script>