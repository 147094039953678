import callEndsService from '../services/api/call_ends'

const callEndModule = {
    state: {
        myCallEnds: []
    },
    mutations: {
        setMyCallEnds(state, myCallEnds) {
            state.myCallEnds = myCallEnds
        }
    },
    actions: {
        getCurrentUserCallEnds(context) {
            return new Promise((resolve, reject) => {
                callEndsService.getCurrentUserCallEnds()
                    .then((res) => {
                        context.commit("setMyCallEnds", res)
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        assignCallEndToCall(context, data) {
            return new Promise((resolve, reject) => {
                callEndsService.assignCallEndToCall(data)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
    }
}

export default callEndModule