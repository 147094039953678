<template lang="pug">
  .pbx-show
    .container-fluid
      .row
        .col-sm-3.bg-white
          .lateral-menu
            .lateral-menu-header
              input.form-control(placeholder="Buscar" v-model="filter")
              select.form-select(v-model="filterGroup")
                option(value="") Todos
                option(value="Announcement") Anuncios
                option(value="Queue") Colas
                option(value="TimeCondition") Condiciones Horarias
                option(value="Extensions") Extensiones
                option(value="RingGroup") Grupos de timbrado
                option(value="IVR") IVRs
            .lateral-menu-body
              
        .col-sm-9
          canvas#pbxCanvas(style="width:100%;height:99%;border:1px solid red;background:#fff;") Your browser does not support the HTML5 canvas tag.
</template>

<script>
  export default {
    name: 'PbxShow',
    mounted() {
      this.drawChart()
    },
    data () {
      return {
        holder: [],
        zoom: 100,
        filter: '', 
        filterGroup: '',
        dragging: false,
        backgroundDrag: false,
        blocks: [
          {
            id: '1',
            preview: {
              title: 'Festivo Barcelona',
              type: 'TimeCondition',
            },
            node: {
              title: 'Festivos Barcelona',
              description: '1',
            },
            destinationNode: {
              nodeComponent: 'demo-node',
              data: {
                text: 'Parent block',
                icon: 'clock',
                type: 'TimeCondition',
                title: 'Festivos Barcelona',
              },
            }
          },
          {
            id: '2',
            preview: {
              title: 'Festivo Cataluña',
              type: 'TimeCondition',
            },
            node: {
              title: 'Festivos Cataluña',
              description: '2',
            },
            destinationNode: {
              nodeComponent: 'demo-node',
              data: {
                text: 'Parent block',
                icon: 'clock',
                type: 'TimeCondition',
                title: 'Festivos Cataluña',
              },
            }
          },
          {
            id: '3',
            preview: {
              title: 'Festivo España',
              type: 'TimeCondition',
            },
            node: {
              title: 'Festivos España',
              description: '3',
            },
            destinationNode: {
              nodeComponent: 'demo-node',
              data: {
                text: 'Parent block',
                icon: 'clock',
                type: 'TimeCondition',
                title: 'Festivos España',
              },
            }
          },
          {
            id: '4',
            preview: {
              title: 'Festivo España',
              type: 'TimeCondition',
            },
            node: {
              title: 'Festivos España',
              description: '4',
            },
            destinationNode: {
              nodeComponent: 'demo-node',
              data: {
                text: 'Parent block',
                icon: 'clock',
                type: 'TimeCondition',
                title: 'Festivos España',
              },
            }
          },
          {
            id: '5',
            preview: {
              title: 'Horario General',
              type: 'TimeCondition',
            },
            node: {
              title: 'Horario General',
              description: '5',
            },
            destinationNode: {
              nodeComponent: 'demo-node',
              data: {
                text: 'Parent block',
                icon: 'clock',
                type: 'TimeCondition',
                title: 'Horario General',
              },
            }
          },
          {
            id: '13',
            preview: {
              title: 'Locución festivo',
              type: 'Announcement',
            },
            node: {
              title: 'Locución festivo',
              description: '13',
            },
            destinationNode: {
              nodeComponent: 'demo-node',
              data: {
                text: 'Parent block',
                icon: 'volume-up',
                type: 'Announcement',
                title: 'Locución festivo',
              },
            }
          },
          {
            id: '6',
            preview: {
              title: 'Locución fuera de horario',
              type: 'Announcement',
            },
            node: {
              title: 'Locución fuera de horario',
              description: '6',
            },
            destinationNode: {
              nodeComponent: 'demo-node',
              data: {
                text: 'Parent block',
                icon: 'volume-up',
                type: 'Announcement',
                title: 'Locución fuera de horario',
              },
            }
          },
          {
            id: '7',
            preview: {
              title: 'Menú Opciones Barcelona',
              type: 'IVR',
            },
            node: {
              title: 'Menú Opciones Barcelona',
              description: '7',
            },
            destinationNode: {
              nodeComponent: 'demo-node',
              data: {
                text: 'Parent block',
                icon: 'list-ol',
                type: 'IVR',
                title: 'Menú Opciones Barcelona',
              },
            }
          },
          {
            id: '8',
            preview: {
              title: 'Cola Operadora',
              type: 'Queue',
            },
            node: {
              title: 'Cola Operadora',
              description: '8',
            },
            destinationNode: {
              nodeComponent: 'demo-node',
              data: {
                text: 'Parent block',
                icon: 'sitemap',
                type: 'Queue',
                title: 'Cola Operadora',
              },
            }
          },
          {
            id: '9',
            preview: {
              title: 'Cola Administración',
              type: 'Queue',
            },
            node: {
              title: 'Cola Administración',
              description: '9',
            },
            destinationNode: {
              nodeComponent: 'demo-node',
              data: {
                text: 'Parent block',
                icon: 'sitemap',
                type: 'Queue',
                title: 'Cola Administración',
              },
            }
          },
          {
            id: '10',
            preview: {
              title: 'Cola Comercial',
              type: 'Queue',
            },
            node: {
              title: 'Cola Comercial',
              description: '10',
            },
            destinationNode: {
              nodeComponent: 'demo-node',
              data: {
                text: 'Parent block',
                icon: 'sitemap',
                type: 'Queue',
                title: 'Cola Comercial',
              },
            }
          },
          {
            id: '11',
            preview: {
              title: 'Cola Técnicos',
              type: 'Queue',
            },
            node: {
              title: 'Cola Técnicos',
              description: '11',
            },
            destinationNode: {
              nodeComponent: 'demo-node',
              data: {
                text: 'Parent block',
                icon: 'sitemap',
                type: 'Queue',
                title: 'Cola Técnicos',
              },
            }
          },
          {
            id: '12',
            preview: {
              title: 'Grupo de timbrado Técnicos',
              type: 'RingGroup',
            },
            node: {
              title: 'Grupo de timbrado Técnicos',
              description: '12',
            },
            destinationNode: {
              nodeComponent: 'demo-node',
              data: {
                text: 'Parent block',
                icon: 'bell',
                type: 'RingGroup',
                title: 'Grupo de timbrado Técnicos',
              },
            }
          },
        ],
        nodes: [
          {
            id: '1',
            parentId: -1,
            nodeComponent: 'demo-node',
            data: {
              text: 'Parent block',
              icon: 'phone',
              type: 'RouteIn',
              title: 'Cabecera Barcelona 1',
              description: 'Telf: 934123456',
            },
          },
          {
            id: '2',
            parentId: '1',
            nodeComponent: 'demo-node',
            data: {
              text: 'Parent block',
              icon: 'clock',
              type: 'TimeCondition',
              title: 'Festivos Barcelona',
              description: '',
            },
          },
          {
            id: '3',
            parentId: '2',
            parentOption: 'Si',
            nodeComponent: 'demo-node',
            data: {
              text: 'Parent block',
              icon: 'volume-up',
              type: 'Announcement',
              title: 'Locución fuera de horario',
              description: '',
            },
          },
          {
            id: '4',
            parentId: '2',
            parentOption: 'No',
            nodeComponent: 'demo-node',
            data: {
              text: 'Parent block',
              icon: 'clock',
              type: 'TimeCondition',
              title: 'Festivos Cataluña',
              description: '',
            },
          },
          {
            id: '5',
            parentId: '4',
            parentOption: 'Si',
            nodeComponent: 'demo-node',
            data: {
              text: 'Parent block',
              icon: 'volume-up',
              type: 'Announcement',
              title: 'Locución fuera de horario',
              description: '',
            },
          },
          {
            id: '6',
            parentId: '4',
            parentOption: 'No',
            nodeComponent: 'demo-node',
            data: {
              text: 'Parent block',
              icon: 'clock',
              type: 'TimeCondition',
              title: 'Festivos España',
              description: '',
            },
          },
          {
            id: '7',
            parentId: '6',
            parentOption: 'Si',
            nodeComponent: 'demo-node',
            data: {
              text: 'Parent block',
              icon: 'volume-up',
              type: 'Announcement',
              title: 'Locución fuera de horario',
              description: '',
            },
          },
          {
            id: '8',
            parentId: '6',
            parentOption: 'No',
            nodeComponent: 'demo-node',
            data: {
              text: 'Parent block',
              icon: 'clock',
              type: 'TimeCondition',
              title: 'Horario General',
              description: '',
            },
          },
          {
            id: '9',
            parentId: '8',
            parentOption: 'Si',
            nodeComponent: 'demo-node',
            data: {
              text: 'Parent block',
              icon: 'list-ol',
              type: 'IVR',
              title: 'Menú Opciones Barcelona',
              description: '',
            },
          },
          {
            id: '10',
            parentId: '8',
            parentOption: 'No',
            nodeComponent: 'demo-node',
            data: {
              text: 'Parent block',
              icon: 'volume-up',
              type: 'Announcement',
              title: 'Locución fuera de horario',
              description: '',
            },
          },
          {
            id: '11',
            parentId: '9',
            parentOption: '0',
            nodeComponent: 'demo-node',
            data: {
              text: 'Cola Operadoras',
              icon: 'sitemap',
              type: 'Queue',
              title: 'Cola Operadora',
              description: '',
            },
          },
          {
            id: '12',
            parentId: '9',
            parentOption: '1',
            nodeComponent: 'demo-node',
            data: {
              text: 'Cola Administración',
              icon: 'sitemap',
              type: 'Queue',
              title: 'Cola Administración',
              description: '',
            },
          },
          {
            id: '13',
            parentId: '9',
            parentOption: '2',
            nodeComponent: 'demo-node',
            data: {
              text: 'Cola Comercial',
              icon: 'sitemap',
              type: 'Queue',
              title: 'Cola Comercial',
              description: '',
            },
          },
          {
            id: '14',
            parentId: '9',
            parentOption: '3',
            nodeComponent: 'demo-node',
            data: {
              text: 'Cola Técnicos',
              icon: 'sitemap',
              type: 'Queue',
              title: 'Cola Técnicos',
              description: '',
            },
          },
          {
            id: '15',
            parentId: '9',
            parentOption: 'Timeout',
            nodeComponent: 'demo-node',
            data: {
              text: 'Cola Operadoras',
              icon: 'sitemap',
              type: 'Queue',
              title: 'Cola Operadoras',
              description: '',
            },
          },
        ]
      }
    },
    computed: {
      filteredBlocks() {
        return this.blocks.filter(block => {
          if(this.filterGroup) {
            return (block.destinationNode.data.title.toLowerCase().includes(this.filter) && block.destinationNode.data.type == this.filterGroup);
          } else {
            return block.destinationNode.data.title.toLowerCase().includes(this.filter);
          }
        });
      },
      sortedBlocks() {
        function compare(a, b) {
          if (a.preview.title < b.preview.title)
            return -1;
          if (a.preview.title > b.preview.title)
            return 1;
          return 0;
        }

        return [...this.filteredBlocks].sort(compare);
      },
      sortedNodes() {
        function compare(a, b) {
          if (a.parentId < b.parentId)
            return -1;
          if (a.parentId > b.parentId)
            return 1;
          if (a.parentOption < b.parentOption)
            return -1;
          if (a.parentOption > b.parentOption)
            return 1;
          return 0;
        }

        return [...this.nodes].sort(compare);
      }
    },
    methods: {
      drawChart() {
        var c = document.getElementById("pbxCanvas");
        var ctx = c.getContext("2d");
        ctx.beginPath();
        ctx.fillStyle = "rgb(200,0,0)";
        ctx.arc(50, 50, 5, 0, 2 * Math.PI);
        ctx.stroke();
      },
     
    }
  }
</script>
<style lang="scss">
  .lateral-menu {
   padding-top: 10px;
  }
  .lateral-menu .lateral-menu-header {
    height: 80px;
  }
  .lateral-menu .lateral-menu-body {
    height: calc(100vh - 150px);
  }
  .flowy {
    padding-top: 10px;
    height: calc(100vh - 60px);
  }
  .lateral-menu .mr-24px {
    margin-right: 0px !important; 
  }
  .zoom100 > .flowy > .flowy-tree {
    zoom: 100%;
  }
  .zoom90 > .flowy > .flowy-tree {
    zoom: 95%;
  }
  .zoom80 > .flowy > .flowy-tree {
    zoom: 90%;
  }
  .zoom70 > .flowy > .flowy-tree {
    zoom: 85%;
  }
  .zoom60 > .flowy > .flowy-tree {
    zoom: 80%;
  }
  .zoom50 > .flowy > .flowy-tree {
    zoom: 75%;
  }
  .zoom40 > .flowy > .flowy-tree {
    zoom: 70%;
  }
  .zoom30 > .flowy > .flowy-tree {
    zoom: 65%;
  }
  .zoom20 > .flowy > .flowy-tree {
    zoom: 60%;
  }
  .zoom10 > .flowy > .flowy-tree {
    zoom: 55%;
  }
  .zoom0 > .flowy > .flowy-tree {
    zoom: 50%;
  }
  .cont-zoom {
    position: absolute;
    z-index: 10000;
    top: 70px;
    right: 40px;
    width: 200px;
  }
</style>