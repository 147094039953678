<template lang="pug">
  .screen-user-statuses-large
    .card.mb-3
      .card-body
        .d-flex.justify-content-between
          h5.card-title.mb-1 Usuarios con descansos activos 
        table.table
          thead
            tr
              th Nombre del Usuario
              th Descanso
              th Tiempo
              th
          tbody
            tr(v-for="user in sortedUsers")
              td {{user.name}}
              td {{user.active_rest_time.rest_time.name}}
              td(:key="'activeUser' + user.id + counterKey") {{user.active_rest_time.start | diffToNow | timeInHoursMin}}
              td
                button.btn.btn-link.text-dark(@click="forceEnd(user)")
                  font-awesome-icon(icon="pause")
</template>
<script>
  import UserStatus from '../users/UserStatus.vue'
  export default {
    name: 'ScreenUserStatusesLarge',
    components: {
      UserStatus
    },
    props: {
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        showUsers: true,
      }
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      users() {
        let users = []
        if(this.filters.departments_id) {
          
          this.$store.state.userModule.users.forEach((u) => {
            if(u.department_id) {
              let index = this.filters.departments_id.findIndex((dId) => dId == u.department_id )

              if(index > -1) {
                if(u.active_rest_time && u.active_rest_time.id) {
                  users.push(u)
                }
              }
            }
          })

        } else {
          this.$store.state.userModule.users.forEach((u) => {
            if(u.active_rest_time && u.active_rest_time.id) {
              users.push(u)
            }
          })

        }
        
        return users
      },
      sortedUsers () {
        let users = this.users
        return users.sort((a, b) => {
            
            if (a.name < b.name) {
                return -1
            } else if (a.name > b.name) {
                return 1
            }
            
            return 0
        })
      },
      currentUser() {
        return this.$store.state.currentUser
      },
      totalUsers() {
        return this.users.length
      },
    },
    mounted() {
      /*this.$store.dispatch("getRegisters", {moduleUrl: "users"})
        .then((users) => {
          this.$store.commit("setUsers", users)
        })*/
        
    },
    methods: {
      forceEnd(user) {
        this.$store.dispatch("forceEndUserRestTime", {
          userId: user.id
        })
      }
    }
  }
</script>
<style lang="scss">
</style>