<template lang="pug">
  .screen-my-extension-calls
    .card.mb-3
      .card-body
        .d-flex.justify-content-between
          h5.card-title.mb-1 {{ $t('Mis llamadas') }}
          a.text-dark(v-if="showCalls" @click="showCalls = !showCalls" href="#!")
            font-awesome-icon(icon="compress-alt")
          a.text-dark(v-else  @click="showCalls = !showCalls"  href="#!")
            font-awesome-icon(icon="expand-alt")
        table.table
          thead.text-center
            tr
              th(style="width:25%") 
                strong
                  font-awesome-icon.me-1(icon="arrow-right")
                br
                span {{ $t('ENTRADA') }}
              th(style="width:25%")
                strong
                  font-awesome-icon.me-1(icon="arrow-left")
                br
                span {{ $t('SALIDA') }}
              th(style="width:25%")
                strong
                  font-awesome-icon.me-1(icon="redo")
                br
                span {{ $t('INTERNA') }}
              th(style="width:25%")
                strong
                  font-awesome-icon.me-1(icon="exclamation-triangle")
                br
                span {{ $t('PERDIDA') }}
          tbody(v-if="currentUser.extension && currentUser.extension.report_today")
            tr.text-center
              td
                h1.mb-0.text-primary {{currentUser.extension.report_today.total_in_calls}}
              td
                h1.mb-0.text-success {{currentUser.extension.report_today.total_out_calls}}
              td
                h1.mb-0.text-warning {{currentUser.extension.report_today.total_internal_calls}}
              td
                h1.mb-0.text-secondary {{currentUser.extension.report_today.total_lost_calls}}
            tr(v-if="showCalls" )
              td(colspan="4")
                table.table.mb-0
                  tr.mb-0(v-for="call in myTodayCalls")
                    td
                      span.icon.text-primary(v-if="call.call_type_id == 1")
                        font-awesome-icon(icon="arrow-right")
                      span.icon.text-success(v-else-if="call.call_type_id == 2")
                        font-awesome-icon(icon="arrow-left")
                      span.icon.text-warning(v-else)
                        font-awesome-icon(icon="redo")
                    td
                      strong {{call.call_status.name}}
                    td
                      strong(v-if="call.account_id") {{call.account.name}}
                      br(v-if="call.account_id")
                      span(v-if="call.call_type_id == 1") {{call.from}}
                      span(v-else-if="call.call_type_id == 2") {{call.to}}
                      span(v-else) {{call.from}} - {{call.to}}
                    td
                      span {{[call.start, "unix"] | unixToDateTime}}
                    td
                      span W: {{call.duration_wait | timeInHoursMin}}
                      br
                      span D: {{call.duration | timeInHoursMin}}
              
</template>
<script>
  export default {
    name: 'ScreenMyExtensionCalls',
    data () {
      return {
        showCalls: false,
      }
    },
    computed: {
      currentUser() {
        return this.$store.state.currentUser
      },
      queues() {
        return this.$store.state.queueModule.queues
      },
      myTodayCalls() {
        return this.$store.state.callModule.myTodayCalls
      }
    },
    watch: {
      user: {
        deep: true,
        handler() {
          this.$store.dispatch("getMyExtensionTodayCalls")
        }
      }
    },
    mounted() {
        this.$store.dispatch("getMyExtensionTodayCalls")
    },
    methods: {
      
    }
  }
</script>
<style lang="scss">
</style>