import appService from "./appService";

const callsService = {
    search(page, data) {
        return appService.post('/calls/search/' + page, data)
            .then((res) => res.data)
    },
    exportCsv(data) {
        return appService.post('/calls/export-csv', data)
            .then((res) => res.data)
    },
    getMyCallsWithoutEnd() {
        return appService.get('/calls/own/without-end')
            .then((res) => res.data)
    },
    getMyTodayCalls() {
        return appService.get('/calls/my-today-calls')
            .then((res) => res.data)
    },
    getMyExtensionTodayCalls() {
        return appService.get('/calls/my-extension-today-calls')
            .then((res) => res.data)
    },
    getInitialState() {
        return appService.get('/calls/get-initial-state')
            .then((res) => res.data)
    },
    hangupChannel(data) {
        return appService.post('/calls/hangup-channel', data)
            .then((res) => res.data)
    },
    parkChannel(data) {
        return appService.post('/calls/park-channel', data)
            .then((res) => res.data)
    },
    redirectChannel(data) {
        return appService.post('/calls/redirect-channel', data)
            .then((res) => res.data)
    },
    attendedtransferChannel(data) {
        return appService.post('/calls/attendedtransfer-channel', data)
            .then((res) => res.data)
    },
    cancelAttendedtransferChannel(data) {
        return appService.post('/calls/cancel-attendedtransfer-channel', data)
            .then((res) => res.data)
    },
    makeCall(data) {
        return appService.post('/calls/make-call', data)
            .then((res) => res.data)
    },
    getAccountLastCalls(accountId) {
        return appService.get('/accounts/' + accountId + '/last-calls')
            .then((res) => res.data)
    },
    getActionsAfterOpenCall(data) {
        return appService.post('/calls/actions-after-open-call', data)
            .then((res) => res.data)
    },
    getCallRecord(callRecordId) {
        return appService.get('/calls/get-record/' + callRecordId)
            .then((res) => res.data)
    },
    getCallRecordMp3(callRecordId) {
        return appService.get('/calls/get-record-mp3/' + callRecordId)
            .then((res) => res.data)
    },
    getCallLog(callId) {
        return appService.get('/calls/' + callId + '/log')
            .then((res) => res.data)
    }

}

export default callsService;