<template lang="pug">
  .chart-container(ref="chartdiv")
</template>

<script>

  import * as am5 from '@amcharts/amcharts5';
  import * as am5xy from '@amcharts/amcharts5/xy';
  import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

  export default {
    name: 'ChartSimpleColumn',
    props: {
      data: Array,
      categoryField: String,
      valueField: String,
      title: String
    },
    mounted() {
      this.root = am5.Root.new(this.$refs.chartdiv);

      this.root.setThemes([am5themes_Animated.new(this.root)]);

      let chart = this.root.container.children.push(
        am5xy.XYChart.new(this.root, {
          panX: true,
          panY: true,
          wheelX: "panX",
          wheelY: "zoomX"
        })
      );

      // Create Y-axis
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(this.root, {
          renderer: am5xy.AxisRendererY.new(this.root, {})
        })
      );

      let xRenderer = am5xy.AxisRendererX.new(this.root, { minGridDistance: 30 });
      xRenderer.labels.template.setAll({
        rotation: -45,
        centerY: am5.p50,
        centerX: am5.p100,
        paddingRight: 15,
        oversizedBehavior: "truncate",
        maxWidth: 150
      });

      // Create X-Axis
      let xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(this.root, {
          renderer: xRenderer,
          categoryField: this.categoryField,
          tooltip: am5.Tooltip.new(this.root, {})
        })
      );

      xAxis.data.setAll(this.data);

      // Create series
      let series1 = chart.series.push(
        am5xy.ColumnSeries.new(this.root, {
          name: this.title,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: this.valueField,
          sequencedInterpolation: true,
          categoryXField: this.categoryField,
          tooltip: am5.Tooltip.new(this.root, {
            labelText:"{valueY}"
          })
        })
      );
      series1.data.setAll(this.data);

      // Add legend
      let legend = chart.children.push(am5.Legend.new(this.root, {}));
      legend.data.setAll(chart.series.values);

      // Add cursor
      chart.set("cursor", am5xy.XYCursor.new(this.root, {}));
    },
    data () {
      return {
        root: null
      }
    },
    methods: {
      
    }
  }
</script>
<style lang="scss">
  .chart-container {
  width: 100%;
  height: 500px;
}
</style>