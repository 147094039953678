import appService from "./appService";

const accountsService = {
    getAccountUninvoicedElements(accountId) {
        return appService.get('/accounts/' + accountId +'/uninvoiced_elements')
            .then((res) => res.data)
    },
    getAccountByCodeNameCif(payload) {
        let urlPayload = "?code=" + payload.code + "&name=" + payload.name + "&vat_number=" + payload.vat_number
        return appService.get('/accounts/get-by-code-name-cif' + urlPayload)
            .then((res) => res.data)
    },
    mergeAccounts(accountId, accountIdToMerge) {
        return appService.post('/accounts/merge/' + accountId + '/' + accountIdToMerge)
            .then((res) => res.data)
    },
}

export default accountsService;