<template lang="pug">
  .report-total-sessions-summary-users
    .card.mb-3
      .card-body
        .d-flex.justify-content-between
          h5.card-title {{reportReportItem.name}}
        template(v-if="showChart && series && series.length > 0 && labels && labels.length > 0")
          chart-columns(v-if="series.length > 0" :series="series" :categories="labels" :colors="colors")
       
        table.table
          thead
            tr
              th Usuario
              th Duración
          tbody
            tr(v-for="row in data.dataTable")
              td {{row.user}}
              td {{row.duration | timeInHours}}
              
</template>
<script>
  import ChartColumns from '../chart/ChartColumns.vue'
  export default {
    name: 'ReportTotalSessionsSummaryUsers',
    components: {
      ChartColumns
    },
    props: {
      reportReportItem: {type: Object, default: function() {return {} }},
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        data: {},
        showTab: 'in',
        colors: ["#09ad95"],
        lastFilter: 0,
        showChart: true
      }
    },
    computed: {
      series() {
        if(this.data.dataChart && this.data.dataChart.length > 0 ) {
          let series = []

          series.push({
            name: "Tiempo de sesión",
            data: []
          })

          this.data.dataChart.forEach((col) => {
            series[0].data.push(col.duration)
          })

          return series
        } else {
          return []
        }
      },
      labels() {
        let labels = []
        if(this.data.dataChart && this.data.dataChart.length > 0 ) {
          this.data.dataChart.forEach((row) => {
            labels.push(row.user)
          })
        }

        return labels
      }
    },
    watch: {
      filters: {
        deep: true,
        handler(newVal) {
          if(newVal.counter != this.lastFilter) {
            this.getData()
            this.lastFilter = newVal.counter
          }
        }
      }
    },
    mounted() {
    },
    methods: {
      getData() {
        this.showChart = false
        this.$store.dispatch("getReportReportItemData", {
          reportReportItemId: this.reportReportItem.id,
          data: {
            filters: this.filters
          }
        }).then((res) => {
          this.data = res
          this.showChart = true
        })
      }
    }
  }
</script>
<style lang="scss">
</style>