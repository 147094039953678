<template lang="pug">
  .gc-user
    .chatrooms_list
      .row
        .col.m-1
          .input-group
            button.btn.btn-secondary
              font-awesome-icon(icon="filter")
            input.form-control(type="text" placeholder="Buscar conversación" v-model="filter")
      .row
        .col
          .border-bottom.pb-2.pt-2(v-for="chatRoom in sortedChatRooms" @click="setSelectedChatId(chatRoom.id)" :key="'chatRoom' + chatRoom.id")
            .d-flex
              .ps-2.pe-2.flex-grow-1
                strong {{chatRoom.account.name}}
                template(v-if="chatRoom.account.code")
                  br
                  small {{chatRoom.account.code}}
            div
              small.ms-2 {{chatRoom.from}}
            .d-flex.justify-content-between
              small
              small(v-if="chatRoom.last_account_message") {{chatRoom.last_account_message | formatFullTime}}
              small(v-else) -
            div
              small.badge.bg-warning(v-if="chatRoom.message_end") {{chatRoom.message_end.name}}
              small(v-else)
</template>
<script>
  export default {
    name: 'ClosedChatRoomsList',
    props: {
      selectedChatId: {type: Number, default: 0},
    },
    data () {
      return {
        filter: '',
      }
    },
    computed: {
      chatRooms() {
        return this.$store.state.chatRoomModule.closedChatRooms
      },
      filteredChatRooms() {
        return this.chatRooms.filter((cr) => {
          return ((cr.account && cr.account.code && cr.account.code.toLowerCase().includes(this.filter.toLowerCase())) || (cr.account && cr.account.name.toLowerCase().includes(this.filter.toLowerCase())) || (cr.from.includes(this.filter) || cr.to.includes(this.filter)));
        })
      },
      sortedChatRooms() {
        let sortedChatRooms = this.filteredChatRooms
        return sortedChatRooms.sort((a, b) => {
            if (a.last_account_message > b.last_account_message) {
                return -1
            } else if (a.last_account_message < b.last_account_message) {
                return 1
            }
            
            return 0
        })
      }
    },
    mounted() {
      
    },
    methods: {
      setSelectedChatId(id) {
        if(id > 0) {
          this.$store.dispatch("chatRoomUserMarkAsRead", {chatRoomId: id})
        }
        this.$emit('setSelectedChatId', id)
      }
    }
  }
</script>
<style lang="scss">

</style>