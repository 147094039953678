<template lang="pug">
  .gc-chat.container-fluid.p-0.bg-dark-opacity-25
    .row.g-0
      .col-xxl-2.col-xl-3.col-lg-4.col-md-5
        .p-2
          .cont-contacts
            chat-room-list(@setSelectedChatId="setSelectedChatId")
      .col-xxl-10.col-xl-9.col-lg-8.col-md-7.bg-white(v-if="selectedChatId > 0")
        .p-2
          .border-bottom.p-2
            strong.text-primary {{selectedChatRoom.account.name}}
          chat-room-chat(@setSelectedChatId="setSelectedChatId")
</template>
<script>
  import ChatRoomList from "../../components/messages/ClosedChatRoomsList.vue"
  import ChatRoomChat from "../../components/messages/ClosedChatRoomChat.vue"
  export default {
    name: 'ClosedChat',
    components: {
      ChatRoomList,
      ChatRoomChat
    },
    data () {
      return {
        selectedChatId: 0,
        messageTypeId: 0,
        phoneNumber: '',
        departmentCode: '',
        rgpd: 0,
        messageTempalateId: 0,
        params: [],
        successMessage: '',
        errorMessage: '',
        customMessage: '',
        editCustomMessage: false,
        messageTemplates: []
      }
    },
    computed: {
      chatRooms() {
        return this.$store.state.chatRoomModule.closedChatRooms
      },
      selectedChatRoom() {
        return this.$store.state.chatRoomModule.selectedClosedChatRoom
      },
    },
    watch: {
      
    },
    mounted() {
      this.$store.dispatch("getUserClosedChatRooms")
    },
    methods: {
      setSelectedChatId(id) {
        this.selectedChatId = id

        if(this.selectedChatId > 0) {
          let index = this.chatRooms.findIndex((cr) => cr.id == this.selectedChatId)

          if(index > -1) {
            this.$store.commit("setSelectedClosedChatRoom", this.chatRooms[index])
          } else {
            this.$store.commit("setSelectedClosedChatRoom", {})
          }
        } else {
          this.$store.commit("setSelectedClosedChatRoom", {})
        }
      },
    }
  }
</script>
<style lang="scss" scoped>
.cont-contacts {
  height: calc(100vh - 140px);
  overflow-y:auto;
}
</style>