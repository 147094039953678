  <template lang="pug">
  .task-item-list(v-if="task")
    .row.border-bottom.p-2
      .col-lg-6
        h6 {{task.task_description.name}}
        span.badge.me-2(:style="'background:' + task.task_type.background + ';color:' + task.task_type.color + ';'") 
          font-awesome-icon.me-2(icon="tasks")
          span {{task.task_type.name}}
        span.badge.me-2(:style="'background:' + task.task_priority.background + ';color:' + task.task_priority.color + ';'") 
          font-awesome-icon.me-2(icon="traffic-light")
          span {{task.task_priority.name}}
        span.badge.me-2(:style="'background:' + task.task_status.background + ';color:' + task.task_status.color + ';'") 
          font-awesome-icon.me-2(icon="flag")
          span {{task.task_status.name}}
        span.badge.me-2(v-if="task.task_group_id" style="backround:#ffffff;color:#6c757d;")
          font-awesome-icon.me-2(icon="layer-group")
          span {{task.task_group.name}}
        span.badge.me-2(v-if="task.account_id" style="backround:#ffffff;color:#6c757d;")
          font-awesome-icon.me-2(icon="id-card")
          span {{task.task_group.name}}
      .col-lg-2.col-md-6
        span(v-if="task.start_date") {{task.start_date | formatDate}} {{task.start_hour | formatTime}}
        br
        span(v-if="task.end_date") {{task.end_date | formatDate}} {{task.end_hour | formatTime}}
      .col-lg-1.col-md-2
        template(v-for="user in task.users")
          span.border.rounded-circle.p-1(style="font-size: 12px") {{user.name | shortTileCapitalize}}
      .col-lg-2.col-md-2
        span.align-middle(:class="currentDuration ? 'text-success' : ''") {{totalDuration | timeInHours}}
        br
        span(v-if="currentDuration") {{currentDuration | timeInHours}}
        strong.text-success(v-if="currentStaredTask && currentStaredTask.id") En proceso
      .col-lg-1.col-md-2
</template>

<script>
  
  export default {
    name: 'TaskListItem',
    props: {
      task: {type: Object, default: function() { return {}}},
    },
    data () {
      return {
        currentDuration: 0,
        currentDurationInterval: null,
      }
    },
    computed: {
      currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },
      startedTasks() {
        if(this.$store.state.taskModule.startedTasks) {
          return this.$store.state.taskModule.startedTasks
        } else {
          return []
        }
      },
      currentStaredTask() {
        let index = this.startedTasks.findIndex(x => x.id == this.task.id)

        if(index > -1) {
          return this.startedTasks[index]
        } else {
          return {}
        }
      },
      totalDuration() {
        return this.task.duration + this.currentDuration
      }
    },
    methods: {
      startTask() {
        this.$store.dispatch("startTask", {
          taskId: this.task.id
        }).then(() => {
          this.currentDurationInterval = setInterval(()=> {
            this.currentDuration++
          }, 1000)
        })
      },
      endTask() {
        this.$store.dispatch("endTask", {
          taskId: this.task.id
        }).then(() => {
          clearInterval(this.currentDurationInterval)
          this.currentDuration = 0
        })
      },
    }
  }
</script>