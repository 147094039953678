<template lang="pug">
  .gc-queue
    
</template>
<script>

  export default {
    name: 'GcQueue',
    data () {
      return {
      }
    },
    computed: {
      queues() {
        return this.$store.state.queueModule.queues
      },
      hiddenQueues() {
        return this.$store.state.queueModule.hiddenQueues
      },
    },
    mounted() {
      window.Echo.channel('App.Pbx')
        .listen('UpdateQueueReportToday', (e) => {
            this.processUpdateQueueReportToday(e.queueReportToday)
        })

      this.$store.dispatch("getMyQueues")
        .then((queues) => {
          this.$store.commit("setQueues", queues)
        })
    },
    methods: {
      processUpdateQueueReportToday(queueReportToday) {
        let index = this.queues.findIndex((q) => q.id == queueReportToday.queue_id)

        if(index > -1) {
          let queue = this.queues[index]

          this.$set(queue, "report_today", queueReportToday)
          this.$store.commit("updateQueue", queue)
        } else {
          let index = this.hiddenQueues.findIndex((q) => q.id == queueReportToday.queue_id)

          if(index > -1) {
            let queue = this.hiddenQueues[index]

            this.$set(queue, "report_today", queueReportToday)
            this.$store.commit("updateHiddenQueue", queue)
          }
        }
      }
    }
  }
</script>
<style lang="scss">
</style>