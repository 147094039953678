<template lang="pug">
  .module-add
    draw-form(
      :module="currentModule"
      :value="object"
    )
</template>

<script>
  import DrawForm from '../../components/forms/Draw.vue'

  export default {
    name: 'ModuleDuplicate',
    components: {
      DrawForm
    },
    beforeMount() {
      this.duplicateId = this.$route.params.id
      this.moduleUrl = this.$route.params.moduleUrl
      this.$store.dispatch("getModuleByUrl", {moduleUrl: this.moduleUrl})
        .then((res) => {
          this.currentModule = res
        })

      this.$store.dispatch("getRegister", {moduleUrl: this.moduleUrl, id: this.duplicateId})
        .then((res) => {
          this.object = res
          this.object.id = null
       })
    },
    data () {
      return {
        moduleUrl: '',
        currentParentModule: {},
        foreignKey: '',
        currentModule: {},
        object: {},
        duplicateId: 0
      }
    },
    methods: {
    }
  }
</script>