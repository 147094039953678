<template lang="pug">
  .login
    .row
      .col-md-4
      .col-md-4(style="min-height: 500px;")
        .text-center.mb-3
          img.mb-3(src="/media/logo.png" style="max-width:60%;")
        .card
          .card-body
            h5.card-title Modificar la contraseña
            p El password debe cumplirs las siguientes instrucciones:
            ul
              li 
                strong Longitud mínima 8 caracteres
              li 
                strong Debe contener minúscula
              li 
                strong Debe contener mayúscula
              li 
                strong Debe contener números
              li 
                strong Debe contener un carácter especial
            form(name='form' @submit.prevent='resetPassword')
              .form-group.mb-2
                label(for='password') Password
                input.form-control(v-model='password' type='password' name='username')
              .form-group
                label(for='confirm') Confirme el password
                input.form-control(v-model='confirm' type='password' name='password')
              hr
              .d-grid.gap-2
                button.btn.btn-primary(:disabled='loading')
                  span.spinner-border.spinner-border-sm(v-show='loading')
                  span Cambiar password
              template(v-if="successMessage")
                hr
                .d-grid.gap-2
                  button.btn.btn-secondary(@click="goLogin()")
                    span Volver al login
              .alert.alert-success.mt-2(v-if='successMessage' role='alert') {{successMessage}}
              .alert.alert-danger.mt-2(v-if='message' role='alert') {{message}}

</template>

<script>

export default {
  name: 'ResetPassword',
  data () {
    return {
      loading: false,
      message: '',
      successMessage: '',
      confirm: '',
      password: '',
    }
  },
  beforeCreate() {
    if(localStorage.getItem('user')) {
      this.user = JSON.parse(localStorage.getItem('user'))
      this.$router.push('/dashboards');
    }
  },
  methods: {
    goLogin() {
      this.$router.push('/login')
    },
    resetPassword() {
      this.message = ''
      this.successMessage = ''
      if(this.password && this.password == this.confirm && this.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)) {
        this.$store.dispatch("resetPassword", {
          token: this.id = this.$route.params.token,
          password: this.password,
          confirm: this.confirm
        }).then((res) => {
          if(res.error) {
            this.message = "Los datos introducidos no son correctos";
          } else {
            this.successMessage = "Los datos introducidos son correctos"
          }
        })
        
      } else {
        this.message = "Los datos introducidos no son correctos";
      }
    }
  }

}
</script>

<style lang="scss">
  .login {
    overflow: hidden;
  }
</style>
