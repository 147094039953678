<template lang="pug">
.account-calls
  .border.p-2
    .row
      .col-6
        h5.card-title Listado de llamdas 
      .col-6
        p.text-end 
          span {{$t('Mostrando')}}&nbsp;
          strong {{limitStart}} - {{limitEnd}}
          span &nbsp;{{$t('de')}}&nbsp; 
          strong {{totalElements}}
    .row.mb-2
      .col-5
        input.form-control(type="date" v-model="start")
      .col-5
        input.form-control(type="date" v-model="end")
      .col-2
        select.form-select.border-secondary(aria-label='Default select example' v-model="page" @change="search()")
          option(v-for='i in totalPages' :key="'page' + i" :value='i') {{i}}
    
    table.table
      tbody
        tr(v-for="call in registers")
          td
            span.ms-3.icon.text-primary(v-if="call.call_type_id == 1")
              font-awesome-icon(icon="arrow-right")
            span.ms-3.icon.text-success(v-else-if="call.call_type_id == 2")
              font-awesome-icon(icon="arrow-left")
            span.ms-3.icon.text-warning(v-else)
              font-awesome-icon(icon="redo")
          td
            div
              strong.badge.ms-3(:class="getBackgroundStatus(call.call_status_id)") {{call.call_status.name}}
          td
            .p-0
              span {{[call.start, "unix"] | unixToDateTime}}
            .p-0
              span.me-2 W: {{call.duration_wait | timeInHoursMin}}
              span D: {{call.duration | timeInHoursMin}}
            .p-0
              span.badge.bg-info(v-if="call.call_end_id") {{call.call_end.name}}
              span.badge.bg-secondary(v-else) Sin final
          td
            template(v-for="user in call.answered_users")
              span {{user.name}}
              br
          td
            button.btn.btn-link.text-dark.btn-sm(@click.stop="setModalCall(call)")
              font-awesome-icon(icon="eye")
    
</template>
<script>
  import {Modal} from 'bootstrap'
  export default {
    name: 'AccountCalls',
    props: { 
      accountId: Number
    },
    data () {
      return {
        start: null,
        end: null,
        page: 1,
        totalPages: 1,
        limit: 10,
        limitStart: 0,
        limitEnd: 0,
        totalElements: 0,
        sortColumn: 'start',
        sortDirection: 'DESC',
        registers: [],
        pointerFilter: null
      }
    },
    computed: {
      
    },
    watch: {
      start() {
        if(this.pointerFilter) {
            clearTimeout(this.pointerFilter)
          }
          this.pointerFilter = setTimeout(() => this.search(), 500)
      },
      end() {
        if(this.pointerFilter) {
            clearTimeout(this.pointerFilter)
          }
          this.pointerFilter = setTimeout(() => this.search(), 500)
      },
    },
    mounted() {
      this.search()
    },
    methods: {
      setModalCall(call) {
        this.$store.commit("setModalCall", call)
        let modalCall = document.getElementById('callModal')
        new Modal(modalCall).show() 
      },
      getBackgroundStatus(callStatusId) {
        let callStatusClass = 'bg-primary'

        switch(callStatusId) {
          case 2:
            callStatusClass = 'bg-success'
            break
          case 3:
            callStatusClass = 'bg-warning'
            break
          case 4:
            callStatusClass = 'bg-danger'
            break
        }

        return callStatusClass
      },
      search() {
        this.$store.dispatch("searchCalls", {
          page: this.page,
          data: {
            advancedFilter: {
              account_id: this.accountId,
              start: {
                start: this.start,
                end: this.end
              },

            },
            sortColumn: this.sortColumn,
            sortDirection: this.sortDirection,
            limit: this.limit,
          }
        }).then((res) => {
          this.registers = res.data
          this.totalPages = res.total_pages
          this.limit = res.limit
          this.limitStart = res.limit_start + 1
          this.limitEnd = res.limit_start + res.limit
          this.totalElements = res.total
          if(this.limitEnd > this.totalElements) {
            this.limitEnd = this.totalElements
          }
        })
      }
    }
  }
</script>
<style lang="scss">
</style>