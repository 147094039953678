<template lang="pug">
  .chart-radialbar
    apexchart(type="radialBar" height="350" :options="chartOptions" :series="series" ref="chart")
</template>

<script>

  export default {
    name: 'ChartRadialBar',
    props: {
      series: Array,
      labels: Array,
      size: String,
      colors: {type: Array, default: function() {return ['#6259ca'] }},
    },
    data () {
      return {
        chartOptions: {
          colors: this.colors,
          chart: {
            type: 'radialBar',
          },
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: '12px',
                  color: undefined
                },
                value: {
                  fontSize: '28px',
                  color: undefined
                }
              }
            },
          },
          labels: this.labels,
        },
      }
    },
    watch: {
      series(newVal) {
        this.$refs['chart'].updateSeries(newVal)
      }
    },
    methods: {
      
    }
  }
</script>
<style lang="scss">
  .chart-container {
  width: 100%;
  height: 500px;
}
</style>