<template lang="pug">
  .legal_document_status_log.border.p-2
    h5.card-title Registro de cambios de estado
    table.table.table-border
      tbody
        tr(v-for="legalDocumentStatusLog in legalDocumentStatusLogArray")
          td
            span.badge(:style="'background:' + legalDocumentStatusLog.legal_document_status.background + ';color:' + legalDocumentStatusLog.legal_document_status.color + ';'") {{legalDocumentStatusLog.legal_document_status.name}}
          td {{legalDocumentStatusLog.created_at | formatFullTime}}
          td {{legalDocumentStatusLog.user.name}}
</template>

<script>
  export default {
    name: 'LegalDocumentStatusLog',
    props: {
      legalDocumentStatusLogArray: {type: Array, default: function() {return [] }},
    },
  }
</script>