<template lang="pug">
  .chart-container(ref="chartdiv")
</template>

<script>

  import * as am5 from '@amcharts/amcharts5';
  import * as am5xy from '@amcharts/amcharts5/xy';
  import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

  export default {
    name: 'ChartStackedColumn',
    props: {
      data: Array,
      categoryField: String,
      series: Array,
      serieField: String
    },
    mounted() {
      this.root = am5.Root.new(this.$refs.chartdiv);

      this.root.setThemes([am5themes_Animated.new(this.root)]);

      this.chart = this.root.container.children.push(
        am5xy.XYChart.new(this.root, {
          panX: false,
          panY: false,
          wheelX: "panX",
          wheelY: "zoomX",
          layout: this.root.verticalLayout
        })
      );

      this.chart.set("scrollbarX", am5.Scrollbar.new(this.root, {
        orientation: "horizontal"
      }));

      this.xAxis = this.chart.xAxes.push(am5xy.CategoryAxis.new(this.root, {
        categoryField: this.categoryField,
        renderer: am5xy.AxisRendererX.new(this.root, {}),
        tooltip: am5.Tooltip.new(this.root, {})
      }));

      this.xAxis.data.setAll(this.data);

      this.yAxis = this.chart.yAxes.push(am5xy.ValueAxis.new(this.root, {
        min: 0,
        renderer: am5xy.AxisRendererY.new(this.root, {})
      }));
      
      this.legend = this.chart.children.push(am5.Legend.new(this.root, {
        centerX: am5.p50,
        x: am5.p50
      }));

      this.series.forEach((s) => {
        this.makeSeries(s[this.serieField], s[this.serieField])
      })

    },
    data () {
      return {
        root: null,
        chart: null,
        xAxis: null,
        yAxis: null,
        legend: null,
      }
    },
    methods: {
      makeSeries(name, fieldName) {
        let series = this.chart.series.push(am5xy.ColumnSeries.new(this.root, {
          name: name,
          stacked: true,
          xAxis: this.xAxis,
          yAxis: this.yAxis,
          valueYField: fieldName,
          categoryXField: this.categoryField
        }));

        series.columns.template.setAll({
          tooltipText: "{name}, {categoryX}: {valueY}",
          tooltipY: am5.percent(10)
        });
        series.data.setAll(this.data);

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear();

        series.bullets.push(() => {
          return am5.Bullet.new(this.root, {
            sprite: am5.Label.new(this.root, {
              text: "{valueY}",
              fill: this.root.interfaceColors.get("alternativeText"),
              centerY: am5.p50,
              centerX: am5.p50,
              populateText: true
            })
          });
        });

        //this.legend.data.push(series);
      }
    }
  }
</script>
<style lang="scss">
  .chart-container {
  width: 100%;
  height: 500px;
}
</style>