<template lang="pug">
  .generate-order                        
    #generateOrderModal.modal(tabindex='-1' aria-hidden="true") 
      .modal-dialog.modal-lg
        .modal-content
          .modal-header
            h5.modal-title Generar un pedido
            button#generateOrderModalClose.btn-close(type='button' data-bs-dismiss='modal' aria-label='Close')
          .modal-body
            draw-simp-form(
              :module="currentModule" 
              :value="orderData"
              @createRegister="createOrder"
            )
</template>

<script>
  import jQuery from 'jquery'
  import DrawSimpForm from '../forms/DrawSimp.vue'
  export default {
    name: 'GenerateOrder',
    components: {
      DrawSimpForm
    },
    props: {
      legalDocument: {type: Object, default: function() {return [] }},
    },
    data () {
      return {
        currentModule: {},
        orderData: {},
      }
    },
    beforeMount() {
      this.$store.dispatch("getModuleByUrl", {moduleUrl: 'orders'})
        .then((res) => {
          this.currentModule = res
          this.setOrderData();
        })
    },
    methods: {
      setOrderData() {
        this.orderData = {}
        this.orderData.parent_legal_document_id = this.legalDocument.id
        this.orderData.account_id = this.legalDocument.account_id
        if(this.legalDocument.invoice_account_address_id) {
          this.orderData.invoice_account_address_id = this.legalDocument.invoice_account_address_id
        } else {
          this.orderData.invoice_name = this.legalDocument.invoice_legal_document_address.name
          this.orderData.invoice_code = this.legalDocument.invoice_legal_document_address.code
          this.orderData.invoice_address = this.legalDocument.invoice_legal_document_address.address
          this.orderData.invoice_address_aux = this.legalDocument.invoice_legal_document_address.address_aux
          this.orderData.invoice_postal_code = this.legalDocument.invoice_legal_document_address.postal_code
          this.orderData.invoice_location = this.legalDocument.invoice_legal_document_address.location
          this.orderData.invoice_region = this.legalDocument.invoice_legal_document_address.region
          this.orderData.invoice_country_id = this.legalDocument.invoice_legal_document_address.country_id
        }
      },
      createOrder(res) {
        this.$router.push('/orders/' + res.id)
        this.orderData = {}
        jQuery('#generateOrderModalClose').trigger('click')
      }
    }
  }
</script>