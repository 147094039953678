<template lang="pug">
  li
    .form-check
      input.form-check-input(type="checkbox" :value="department.id" :id="'departmentCheck' + department.id" v-model="value")
      label.form-check-label(:for="'departmentCheck' + department.id") {{department.name}}
      span.ms-2(v-if="department.childrens.length" @click="toggleShowChildrens()") 
        font-awesome-icon(v-if="!showChildrens" icon="angle-down")
        font-awesome-icon(v-else icon="angle-up")
    ul.no-style(v-if="showChildrens && department.childrens" v-for="children in department.childrens")
      filter-department-node(
        :department="children"
        @updateCheckbox="updateCheckbox"
      )

</template>

<script>
  export default {
    name: 'FilterDepartmentNode',
    props: { 
      department: Object,
      selectedDeparmentsId: Array
    },
    data () {
      return {
        showChildrens: false,
        value: false
      }
    },
    watch: {
      value(newVal) {
        this.$emit("updateCheckbox", {
          department_id: this.department.id,
          checked: newVal
        })
      },
      selectedDeparmentsId(newVal) {
        if(newVal && this.department && newVal.includes(this.department.id)) {
          this.value = true
        } else {
          this.value = false
        }
      }
    },
    beforeMount() {
      if(this.selectedDeparmentsId && this.selectedDeparmentsId.includes(this.department.id)) {
        this.value = true
      } else {
        this.value = false
      }
    },
    methods: {
      updateCheckbox(event) {
        this.$emit("updateCheckbox", event)
      },
      toggleShowChildrens() {
        this.showChildrens = !this.showChildrens
      }
    }
  
  }
</script>
<style lang="scss" scoped>
  ul.no-style {
    list-style: none;
  }
  ul.no-style {
    
    padding-left: 20px;
    margin-left: 7px;
  }
</style>