<template lang="pug">
  .fop
    .header-actions
      .header-actions-content(style="z-index: 100")
        .d-flex.flex-row
          .p-2
            filter-department(defaultKey="fop_extensions" @updateDepartmentIds="updateDepartmentIds")
          .p-2
            .input-group
              button.btn.btn-secondary
                font-awesome-icon(icon="filter")
              input.form-control(type="text" placeholder="Buscar extension" v-model="filter")
    .main-content
      .container-fluid
        .row
          .col-xxl-9.col-xl-9.col-lg-6
            .card
              .card-body
                .alert.bg-warning-opacity-25(role="alert" v-if="channelInTransfer")
                  .d-flex
                    span Transferencia en curso, pulse la cruz para recuperar la llamada
                    button.btn-close.btn-close-dark.me-2.m-auto(type="button" aria-label="Close" @click="cancelAttendedTransfer()")
                .alert.bg-primary-opacity-25(role="alert" v-if="channelToTransfer")
                  .d-flex
                    span Para transferir la llamada pulse sobre el usuario deseado
                    button.btn-close.btn-close-dark.me-2.m-auto(type="button" aria-label="Close" @click="setChannelToTransfer(null)")
                .alert.bg-primary-opacity-25(role="alert" v-if="channelToRedirect")
                  .d-flex
                    span Para redirigir la llamada pulse sobre el usuario deseado
                    button.btn-close.btn-close-dark.me-2.m-auto(type="button" aria-label="Close" @click="setChannelToRedirect(null)")
                .row.row-cols-xxl-5.row-cols-xl-3.row-cols-lg-3.row-cols-md-3
                  .col(v-for="extension in sortedExtensions" :key="'extension' + extension.id")
                    extension-status(
                      :extension="extension" 
                      :calls="extensionCalls[extension.id] ? extensionCalls[extension.id].calls : []" 
                      :showCalls="true" 
                      )
          .col-xxl-3.col-xl-3.col-lg-6
            .p-2
              filter-department(defaultKey="fop_queues" @updateDepartmentIds="updateQueueDepartmentIds")
            nav.nav.mb-2
              a.nav-link(:class="tabItem == 'queue' ? 'active' : ''" @click="setTabItem('queue')") En cola
                span.badge.rounded-pill.bg-primary.ms-1(v-if="totalOnQueueCalls") {{totalOnQueueCalls}}
              a.nav-link.position-relative(:class="tabItem == 'parking' ? 'active' : ''" @click="setTabItem('parking')") Parking
                span.badge.rounded-pill.bg-primary.ms-1(v-if="totalOnParkingCalls") {{totalOnParkingCalls}}
            .card
              .card-body
                div(v-if="tabItem =='queue'")
                  strong LLamadas en cola
                  queue-calls(:departmentsId="queueDepartmentsId" :queues="filteredQueues")
                div(v-if="tabItem =='parking'")
                  strong Llamadas aparcadas
                  parking-calls
                

</template>

<script>
  import ExtensionStatus from '../../components/extensions/ExtensionStatus.vue'
  import QueueCalls from '../../components/calls/QueueCalls.vue'
  import ParkingCalls from '../../components/calls/ParkingCalls.vue'
  export default {
    name: 'ExtensionFop',
    components: {
      ExtensionStatus,
      QueueCalls,
      ParkingCalls,
    },
    data () {
      return {
        tabItem: 'queue',
        departmentsId: [],
        queueDepartmentsId: [],
        filter: '',
        queues: []
      }
    },
    computed: {
      extensions() {
        return this.$store.state.extensionModule.extensions
      },
      currentUser() {
        return this.$store.state.currentUser
      },
      filteredDepartmentExtensions() {
        if(this.departmentsId.length > 0) {
          return this.extensions.filter((e) => {
            return (this.departmentsId.some( (d) => d == e.department_id) || (this.currentUser && this.currentUser.extension_id == e.id));
          })  
        } else {
          return this.extensions
        }
      },
      filteredExtensions() {
        return this.filteredDepartmentExtensions.filter((e) => {
          return (e.name.toLowerCase().includes(this.filter));
        })
      },
      sortedExtensions () {
        let extensions = this.filteredExtensions
        return extensions.sort((a, b) => {
            if(this.currentUser.extension_id && a.id == this.currentUser.extension_id) {
                return -1
            } else if(this.currentUser.extension_id && b.id == this.currentUser.extension_id) {
                return 1
            } else {
                if (a.name < b.name) {
                    return -1
                } else if (a.name > b.name) {
                    return 1
                }
            }
            return 0
        })
      },
      calls() {
        return this.$store.state.callModule.calls
      },
      filteredQueues() {
        if(this.queueDepartmentsId.length > 0) {
          return this.queues.filter((q) => {
            return this.queueDepartmentsId.some((d) => d == q.department_id);
          })  
        } else {
          return this.queues
        }
      },
      onQueueCalls() {
        return this.calls.filter((c) => {
          return c.queue && this.filteredQueues.some((fq) => c.queue == fq.number)
        })
      },
      totalOnQueueCalls() {
        return this.onQueueCalls.length
      },
      onParkingCalls() {
        return this.calls.filter((c) => {return c.parked})
      },
      totalOnParkingCalls() {
        return this.onParkingCalls.length
      },
      channelToRedirect() {
        return this.$store.state.callModule.channelToRedirect
      },
      channelToTransfer() {
        return this.$store.state.callModule.channelToTransfer
      },
      channelInTransfer() {
        return this.$store.state.callModule.channelInTransfer
      },
      extensionCalls() {
        let extensionCalls = {}

        this.calls.forEach((call) => {
          call.connected.forEach((connected) => {
            let index = this.extensions.findIndex((e) => ((e.number == connected)))

            if(index > -1) {
              if(!extensionCalls[this.extensions[index].id]) {
                extensionCalls[this.extensions[index].id] = {}
                this.$set(extensionCalls[this.extensions[index].id], 'calls', [])
              }

              extensionCalls[this.extensions[index].id].calls.push(call)
            }
          })
        })

        return extensionCalls
      }
    },
    watch: {
    },
    beforeMount() {
      this.$store.dispatch("getRegisters", {moduleUrl: "queues"})
        .then((res) => {
          this.queues = res
        })
      this.$store.dispatch("getRegisters", {moduleUrl: "extensions"})
        .then((res) => {
          this.$store.dispatch("setExtensions", res)
        })
    },
    methods: {
      updateDepartmentIds(event) {
        this.departmentsId = event.departments_id
      },
      updateQueueDepartmentIds(event) {
        this.queueDepartmentsId = event.departments_id
      },
      setTabItem(tabItem) {
        this.tabItem = tabItem
      },
      cancelAttendedTransfer() {
        this.$store.dispatch("cancelAttendedtransferChannel", {
          channel: this.channelInTransfer,
        })
      },
      setChannelToTransfer() {
        this.$store.commit("setChannelToTransfer", '')
      },
      setChannelToRedirect() {
        this.$store.commit("setChannelToRedirect", '')
      }
    }
  }
</script>
<style lang="scss">
</style>