<template lang="pug">
.report-summary-department
  #modalVoicemail.modal.fade(tabindex='-1' aria-labelledby='modalVoicemailLabel' aria-hidden='true')
    .modal-dialog
      .modal-content
        .modal-header
          h5#modalVoicemailLabel.modal-title Llamadas en el buzón
          button.btn-close(type='button' data-bs-dismiss='modal' aria-label='Close')
        .modal-body
          table.table
            thead
              tr
                th Número
                th Fecha
                th Contestada
            tbody
              tr
                td 93123456
                td 10-05-2022 10:32:25
                td
                  strong.text-success Sí
                td
                  a.me-3
                    font-awesome-icon(icon="voicemail")
                  a.me-3
                    font-awesome-icon(icon="phone")
              tr
                td 93123457
                td 10-05-2022 10:38:25
                td
                  strong.text-danger No
                td
                  a.me-3
                    font-awesome-icon(icon="voicemail")
                  a.me-3
                    font-awesome-icon(icon="phone")
              tr
                td 93123458
                td 10-05-2022 10:40:25
                td
                  strong.text-success Sí
                td
                  a.me-3
                    font-awesome-icon(icon="voicemail")
                  a.me-3
                    font-awesome-icon(icon="phone")
  #modalLost.modal.fade(tabindex='-1' aria-labelledby='modalLostLabel' aria-hidden='true')
    .modal-dialog
      .modal-content
        .modal-header
          h5#modalLostLabel.modal-title Llamadas perdidas
          button.btn-close(type='button' data-bs-dismiss='modal' aria-label='Close')
        .modal-body
          table.table
            thead
              tr
                th Número
                th Fecha
                th Contestada
            tbody
              tr
                td 93123457
                td 10-05-2022 10:38:25
                td
                  strong.text-danger No
                td
                  a.me-3
                    font-awesome-icon(icon="phone")
              tr
                td 93123458
                td 10-05-2022 10:40:25
                td
                  strong.text-success Sí
                td
                  a.me-3
                    font-awesome-icon(icon="phone")
  .card.mb-3
    .card-body
      .d-flex.justify-content-between
        h5.card-title {{reportReportItem.name}}
      table.table.text-center
        thead
          tr
            th Departamento
            th Pedir Cita
            th Modificar Cita
            th con. Horario
            th h. Asesora
            th horario + cita
            th buzón
            th perdida
            th no retornadas
            th 24 horas +
            th % buzón
        tbody
          tr
            td Tienda 1
            td 25
            td 15
            td 12
            td 21
            td 10
            td.bg-primary
              a.text-white(href='#' data-bs-toggle="modal" data-bs-target="#modalVoicemail") 3
            td.bg-warning 
              a.text-dark(href='#' data-bs-toggle="modal" data-bs-target="#modalLost") 2
            td.bg-danger.text-white 1
            td.bg-danger.text-white 0
            td.bg-info.text-white 3%
          tr
            td Tienda 2
            td 25
            td 15
            td 12
            td 21
            td 10
            td.bg-primary.text-white 3
            td.bg-warning 2
            td.bg-danger.text-white 1
            td.bg-danger.text-white 0
            td.bg-info.text-white 3%
          tr
            td Tienda 3
            td 25
            td 15
            td 12
            td 21
            td 10
            td.bg-primary.text-white 3
            td.bg-warning 2
            td.bg-danger.text-white 1
            td.bg-danger.text-white 0
            td.bg-info.text-white 3%
          tr
            td Tienda 4
            td 25
            td 15
            td 12
            td 21
            td 10
            td.bg-primary.text-white 3
            td.bg-warning 2
            td.bg-danger.text-white 1
            td.bg-danger.text-white 0
            td.bg-info.text-white 3%
              
</template>
<script>
  export default {
    name: 'ReportSummaryDepartment',
    components: {
      
    },
    props: {
      reportReportItem: {type: Object, default: function() {return {} }},
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        data: {},
        showTab: 'in',
        colors: ["#09ad95", "#f7b731", "#f82649", "#6259ca", "#45aaf2"],
        lastFilter: 0
      }
    },
    computed: {
      series() {
        return []
      },
      labels() {
        return []
      }
    },
    watch: {
      filters: {
        deep: true,
        handler(newVal) {
          if(newVal.counter != this.lastFilter) {
            this.getData()
            this.lastFilter = newVal.counter
          }
        }
      }
    },
    mounted() {
    },
    methods: {
      getData() {
        /*this.$store.dispatch("getReportReportItemData", {
          reportReportItemId: this.reportReportItem.id,
          data: {
            filters: this.filters
          }
        }).then((res) => {
          this.data = res
        })*/
      }
    }
  }
</script>
<style lang="scss">
</style>