<template lang="pug">
  .input-select
    template(v-if="isAjax")
      v-select(:options="options" :reduce="prod => prod.id"  label="name" @search="ajaxSearch" placeholder="Producto" v-model="localValue")
        template(slot="option" slot-scope="option")
          span {{option.name}}
          br
          span.small {{'#' + option.code}} 
          br 
          span.small EAN: {{option.ean}}
    template(v-else)
      v-select(:options="options" :reduce="label => label.id"  label="label" v-model="localValue")
</template>
<script>
export default {
  name: 'InputProduct',
  props: {
    value: {default: ''},
    moduleOptions: {type: String, default: ''},
    relatedKey: {type: String, default: ''},
    relatedValue: {default: ''},
  },
  beforeMount() {
    let parts = this.moduleOptions.split(':')

    this.isAjax = true
    this.ajaxModuleUrl = parts[1],
    this.ajaxModuleColumns = parts[2]

    if(this.value && this.options.findIndex(o => o.id == this.value) == -1) {
      this.$store.dispatch("getRegister", {
        moduleUrl: this.ajaxModuleUrl,
        id: this.value
      }).then((res) => {
        this.options.push({id: res.id, name: res.name, ean: res.ean, code: res.code })
      })
    }
  
    this.localValue = this.value
  },
  data () {
    return {
      localValue: '',
      options: [],
      isAjax: false,
      ajaxModuleUrl: '',
      ajaxModuleColumns: ''
    }
  },
  watch: {
    localValue (newValue) {
      this.$emit('input', newValue)
    },
    value (newValue) {
      this.localValue = newValue

      let parts = this.moduleOptions.split(':')

      this.isAjax = true
      this.ajaxModuleUrl = parts[1],
      this.ajaxModuleColumns = parts[2]

      if(newValue && this.options.findIndex(o => o.id == newValue) == -1) {
        this.$store.dispatch("getRegister", {
          moduleUrl: this.ajaxModuleUrl,
          id: newValue
        }).then((res) => {
          this.options.push({id: res.id, name: res.name, ean: res.ean, code: res.code })
        })
      }
    },
    relatedValue() {
      let parts = this.moduleOptions.split(':')
    
      this.isAjax = true
      this.ajaxModuleUrl = parts[1],
      this.ajaxModuleColumns = parts[2]
    }
  },
  methods: {
    ajaxSearch(search, loading) {
      if(this.isAjax) {
          let filter = {
              filter: search,
              moduleUrl: this.ajaxModuleUrl,
              columns: this.ajaxModuleColumns,
          }
          loading(true)
          this.$store.dispatch('getRegistersListAjax', filter).then((res) => {
              this.options = res
              loading(false)
          })
      }
    },
  }
}
</script>
<style scoped>
</style>