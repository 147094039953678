<template lang="pug">
  .pbx-channels
    .container-fluid
      .row
        .col-3
          ul.list-group.small
            li.list-group-item(v-for="channel in channels") {{channel.channel}}: {{ [channel.start, "unix"] | unixToDateTime }} {{channel.ChannelStateDesc}}
        .col-3
          .card.mb-2(v-for="bridge in bridges") 
            .card-body.small
              span(v-if="bridge.id") {{bridge.id}}
              br
              span {{bridge.firstLinkedid}}
              br
              template(v-for="bridgeElement in bridge.elements")
                span {{bridgeElement.channel}}: {{bridgeElement.Linkedid}}
                br
        .col-6
          .row.row-cols-3
            .col.mb-2(v-for="call in calls")
              .card
                .card-body.small
                  strong {{call.CallerIDNum}} -> {{call.Exten}} ({{call.callType}})
                  br
                  span {{call.Linkedids}}
                  br
                  span {{ [call.start, "unix"] | unixToDateTime }}
                  hr
                  span Cola:&nbsp;
                  span(v-if="call.queue") {{call.queue}}
                  hr
                  span Extensiones: &nbsp;
                  span(v-for="connected in call.connected") {{connected}},&nbsp;
                  hr
                  template(v-for="callChannel in call.channels")
                    small {{callChannel.type}} {{callChannel.name}} {{callChannel.ChannelStateDesc}}
                    br
</template>
<script>

  export default {
    name: 'PbxChannels',
    data () {
      return {
        
      }
    },
    computed: {
      channels() {
        return this.$store.state.callModule.channels
      },
      bridges() {
        return this.$store.state.callModule.bridges
      },
      calls() {
        return this.$store.state.callModule.calls
      },
    },
    mounted() {
      
    },
    methods: {
      
    }
  }
</script>
<style lang="scss">
</style>