<template lang="pug">
  .time-group-show
    .header-actions
      .header-actions-content
        .d-flex
          .flex-grow-1.p-2.text
            .row.justify-content-center
    .main-content
      .container-fluid
        .row(v-if="timeGroup")
          .col-lg-6.col-md-8.offset-leg-3.offset-md-2
            .card.mb-2
              .card-body
                .d-flex.justify-content-between
                  h5.card-title {{timeGroup.name}}
                  div
                    button.btn.btn-success.me-2(v-if="needApply" @click="applyChanges()")
                      font-awesome-icon.me-2(icon="save")
                      span Sincronizar cambios
                    button.btn.btn-primary(@click="setCreateTimeGroup(true)")
                      font-awesome-icon.me-2(icon="plus-circle")
                      span Añadir opción
            time-group-option(v-if="createTimeGroup" :timeGroupId="timeGroup.id" :create="true" @createTimeGroupOption="createTimeGroupOption")
            time-group-option(v-for="timeGroupOption in timeGroupOptions"  :timeGroupId="timeGroup.id" :timeGroupOption="timeGroupOption" @updateTimeGroupOption="updateTimeGroupOption" @removeTimeGroupOption="removeTimeGroupOption" :key="'timeGroupOption' + timeGroupOption.id")
</template>

<script>
  import TimeGroupOption from "../../components/time_groups/TimeGroupOption.vue"
  export default {
    name: 'TimeGroupShow',
    components: {
      TimeGroupOption
    },
    beforeMount() {
      if(this.$route.params.id) {
        this.id = this.$route.params.id

        this.$store.dispatch("getRegister", {moduleUrl: this.moduleUrl, id: this.id})
          .then((res) => {
            this.timeGroup = res
          })
      }
    },
    data () {
      return {
        id: null,
        moduleUrl: 'time_groups',
        timeGroup: {},
        createTimeGroup: false,
        needApply: false
      }
    },
    computed: {
      timeGroupOptions() {
        return this.timeGroup.time_group_options
      }
    },
    watch: {
      
    },
    methods: {
      setCreateTimeGroup(value) {
        this.createTimeGroup = value
      },
      createTimeGroupOption(timeGroupOption) {
        this.timeGroup.time_group_options.push(timeGroupOption)
        this.setCreateTimeGroup(false)
        this.needApply = true;
      },
      updateTimeGroupOption(timeGroupOption) {
        let index = this.timeGroup.time_group_options.findIndex((tgo) => tgo.id == timeGroupOption.id)

        if(index > -1) {
          let timeGroupOptions = this.timeGroup.time_group_options

          this.$set(timeGroupOptions, index, timeGroupOption)
          this.$set(this.timeGroup, 'time_group_options', timeGroupOptions)
          this.needApply = true;
        }
      },
      removeTimeGroupOption(timeGroupOption) {
        let index = this.timeGroup.time_group_options.findIndex((tgo) => tgo.id == timeGroupOption.id)

        if(index > -1) {
          let timeGroupOptions = this.timeGroup.time_group_options

          timeGroupOptions.splice(index, 1)
          this.$set(this.timeGroup, 'time_group_options', timeGroupOptions)
          this.needApply = true;
        }
      },
      applyChanges() {
        this.$store.dispatch("timeGroupSaveOptions", {
          timeGroupId: this.timeGroup.id
        }).then((res) => {
          if(res.id) {
            this.$store.commit("setNeedApplyConfig", true)

            this.$swal.fire({
                title: this.$t('Configuración modificada, desea aplicar la configuración?'),
                icon: 'warning',
                showCancelButton: false,
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.$store.dispatch("pbxApplyConfig")
                    .then(() => {
                      this.needApply = false          
                    })
                }
            })
          }
        })
      }
    }
  }
</script>