import Vue from 'vue'
// import productsService from '../services/api/products'

const extensionModule = {
    state: {
        extensions: [],
    },
    mutations: {
        setExtensions (state, extensions) {
            state.extensions = extensions
        },
        addExtension (state, extension) {
            let index = state.extensions.findIndex(x => x.id === extension.id)

            if(index == -1) {
                state.extensions.push(extension)
            }
        },
        updateExtension (state, extension) {
            let index = state.extensions.findIndex(x => x.id === extension.id)

            if(index > -1) {
                Vue.set(state.extensions, index, extension)
            }
        },
        deleteExtension (state, extension) {
            let index = state.extensions.findIndex(x => x.id === extension.id)

            if(index > -1) {
                state.extensions.splice(index,1)
            }
        },
        deleteExtensionByIndex (state, index) {
            if(index > -1) {
                state.extensions.splice(index,1)
            }
        },
    },
    actions: {
        
    }
}

export default extensionModule