import Vue from 'vue'
import chatRoomsService from '../services/api/chat_rooms'

const chatRoomModule = {
    state: {
        chatRooms: [],
        selectedChatRoom: {},
        closedChatRooms: [],
        selectedClosedChatRoom: {},
        messages: [],
    },
    mutations: {
        setChatRooms (state, chatRooms) {
            state.chatRooms = chatRooms
        },
        setClosedChatRooms (state, chatRooms) {
            state.closedChatRooms = chatRooms
        },
        updateChatRoom (state, chatRoom) {
            let index = state.chatRooms.findIndex((cr) => cr.id == chatRoom.id)

            if(index > -1) {
                Vue.set(state.chatRooms, index, chatRoom)
            }
        },
        addChatRoom (state, chatRoom) {
            let index = state.chatRooms.findIndex((cr) => cr.id == chatRoom.id)

            if(index == -1) {
                state.chatRooms.push(chatRoom)
            }
        },
        spliceChatRoom (state, index) {
            state.chatRooms.splice(index, 1)
        },
        chatRoomsUpdateUnread (state, chatRoomId) {
            let index = state.chatRooms.findIndex((cr) => cr.id == chatRoomId)

            if(index > -1) {
                let chatRoom = state.chatRooms[index]
                Vue.set(chatRoom, "unread", 0)
                Vue.set(state.chatRooms, index, chatRoom)
            }
        },
        setSelectedChatRoom(state, selectedChatRoom) {
            state.selectedChatRoom = selectedChatRoom
        },
        setSelectedClosedChatRoom(state, selectedClosedChatRoom) {
            state.selectedClosedChatRoom = selectedClosedChatRoom
        },
        setMessages(state, messages) {
            state.messages = messages
        },
        addMessage(state, message) {
            state.messages.unshift(message)
        },
        updateMessage(state, payload) {
            Vue.set(state.messages, payload.index, payload.message)
        }
    },
    actions: {
        getUserChatRooms (context) {
            return new Promise((resolve, reject) => {
                chatRoomsService.getUserChatRooms()
                    .then((res) => {
                        context.commit("setChatRooms", res)
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        getUserClosedChatRooms (context) {
            return new Promise((resolve, reject) => {
                chatRoomsService.getUserClosedChatRooms()
                    .then((res) => {
                        context.commit("setClosedChatRooms", res)
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        getChatRoomMessages (context, payload) {
            return new Promise((resolve, reject) => {
                chatRoomsService.getChatRoomMessages(payload.chatRoomId, payload.page)
                    .then((res) => {
                        context.commit("setMessages", res)
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        chatRoomSendMessage (context, payload) {
            return new Promise((resolve, reject) => {
                chatRoomsService.chatRoomSendMessage(payload.chatRoomId, payload.data)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        chatRoomSendTemplate (context, payload) {
            return new Promise((resolve, reject) => {
                chatRoomsService.chatRoomSendTemplate(payload.chatRoomId, payload.data)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        sendMessageTemplate (context, payload) {
            return new Promise((resolve, reject) => {
                chatRoomsService.sendMessageTemplate(payload)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        chatRoomUserMarkAsRead (context, payload) {
            return new Promise((resolve, reject) => {
                chatRoomsService.chatRoomUserMarkAsRead(payload.chatRoomId)
                    .then((res) => {
                        context.commit("chatRoomsUpdateUnread", payload.chatRoomId)
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        captureChatRoom (context, payload) {
            return new Promise((resolve, reject) => {
                chatRoomsService.captureChatRoom(payload.chatRoomId)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        transferChatRoom (context, payload) {
            return new Promise((resolve, reject) => {
                chatRoomsService.transferChatRoom(payload.chatRoomId, payload.userId)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        releaseChatRoom (context, payload) {
            return new Promise((resolve, reject) => {
                chatRoomsService.releaseChatRoom(payload.chatRoomId)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        closeChatRoom (context, payload) {
            return new Promise((resolve, reject) => {
                chatRoomsService.closeChatRoom(payload.chatRoomId)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        uncloseChatRoom (context, payload) {
            return new Promise((resolve, reject) => {
                chatRoomsService.uncloseChatRoom(payload.chatRoomId)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        
        
    }
}

export default chatRoomModule