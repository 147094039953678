import Vue from 'vue'
import restTimeService from '../services/api/rest_times'

const restTimeModule = {
    state: {
        restTimes: [],
        restTime: {},
        userRestTime: null
    },
    mutations: {
        setRestTimes (state, restTimes) {
            state.restTimes = restTimes
        },
        addRestTime (state, restTime) {
            let index = state.restTimes.findIndex(x => x.id === restTime.id)

            if(index == -1) {
                state.restTimes.push(restTime)
            }
        },
        updateRestTime (state, restTime) {
            let index = state.restTimes.findIndex(x => x.id === restTime.id)

            if(index > -1) {
                Vue.set(state.restTimes, index, restTime)
            }
        },
        deleteRestTime (state, restTime) {
            let index = state.restTimes.findIndex(x => x.id === restTime.id)

            if(index > -1) {
                state.restTimes.splice(index,1)
            }
        },
        deleteRestTimeByIndex (state, index) {
            if(index > -1) {
                state.restTimes.splice(index,1)
            }
        },
        setRestTime (state, restTime) {
            state.restTime = restTime
        },
        setUserRestTime (state, userRestTime) {
            state.userRestTime = userRestTime
        },
    },
    actions: {
        isUserRestTimeActive(context) {
            return new Promise((resolve, reject) => {
                restTimeService.isUserRestTimeActive()
                    .then((res) => {
                        if(res && res.id) {
                            context.commit("setUserRestTime", res)
                        }
                        resolve(res)
                    })
                    .catch(reject)
            });
        },
        startUserRestTime(context, payload) {
            return new Promise((resolve, reject) => {
                restTimeService.startUserRestTime(payload.restTimeId)
                    .then((res) => {
                        context.commit("setUserRestTime", res)
                        resolve(res)
                    })
                    .catch(reject)
            });
        },
        endUserRestTime(context) {
            return new Promise((resolve, reject) => {
                restTimeService.endUserRestTime()
                    .then((res) => {
                        context.commit("setUserRestTime", null)
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        forceEndUserRestTime(context, payload) {
            return new Promise((resolve, reject) => {
                restTimeService.forceEndUserRestTime(payload.userId)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
    }
}

export default restTimeModule