// import Vue from 'vue'
import dashboardsService from '../services/api/dashboards'

const dashboardModule = {
    state: {
        dashboards: [],
    },
    mutations: {
        setDashboards (state, dashboards) {
            state.dashboards = dashboards
        }
    },
    actions: {
        getMyDashboards (context, payload) {
            return new Promise((resolve, reject) => {
                dashboardsService.getMyDashboards(payload.dashboardTypeId)
                    .then((res) => {
                        context.commit("setDashboards", res)
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        
    }
}

export default dashboardModule