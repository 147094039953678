<template lang="pug">
  .screen-my-extension-statuses-large
    .card.mb-3
      .card-body
        .d-flex.justify-content-between
          h5.card-title.mb-1 {{ $t('Estado usuarios') }} 
            span.text-primary(v-if="currentUser.department_id") {{currentUser.department.name}}
          a.text-dark(v-if="showUsers" @click="showUsers = !showUsers" href="#!")
            font-awesome-icon(icon="compress-alt")
          a.text-dark(v-else  @click="showUsers = !showUsers"  href="#!")
            font-awesome-icon(icon="expand-alt")
        table.table.text-center
          thead
            tr
              th(style="width:20%") 
                strong
                  font-awesome-icon.me-1(icon="check")
                br
                span {{ $t('ACTIVOS') }}
              th(style="width:20%") 
                strong
                  font-awesome-icon.me-1(icon="exclamation-triangle")
                br
                span {{ $t('ERROR') }}
              th(style="width:20%") 
                strong
                  font-awesome-icon.me-1(icon="phone")
                br
                span {{ $t('EN LLAMADA') }}
              th(style="width:20%") 
                strong
                  font-awesome-icon.me-1(icon="coffee")
                br
                span {{ $t('EN DESCANSO') }}
              th(style="width:20%") 
                strong
                  font-awesome-icon.me-1(icon="times-circle")
                br
                span {{ $t('DESCONECTADO') }}
          tbody
            tr
              td
                h1.mb-0.text-success {{totalExtensionsOnline}}
              td
                h1.mb-0.text-danger {{totalExtensionsError}}
              td
                h1.mb-0.text-primary {{totalExtensionsInCall}}
              td
                h1.mb-0.text-warning {{totalExtensionsInRestTime}}
              td
                h1.mb-0.text-secondary {{totalExtensionsOffline}}
            tr(v-if="showUsers")
              td(colspan="5")
                .row.row-cols-xl-3(:class="filters.classColumn ? filters.classColumn : 'row-cols-xxl-4'")
                  .col.text-start(v-for="extension in extensionsUser" :key="'gc-user-extention' + extension.id")
                    extension-user-status(
                      :extension="extension" 
                      :calls="extensionCalls[extension.id] ? extensionCalls[extension.id].calls : []" 
                      :showCalls="false" 
                    )



</template>
<script>
  import ExtensionUserStatus from '../extensions/ExtensionUserStatus.vue'
  export default {
    name: 'ScreenMyExtensionStatusesLarge',
    components: {
      ExtensionUserStatus
    },
    props: {
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        showUsers: true,
        departments_id: []
      }
    },
    computed: {
      currentUser() {
        return this.$store.state.currentUser
      },
      extensions() {
        if(this.filters.departments_id && this.filters.departments_id.length > 0) {
          let extensions = []

          this.$store.state.extensionModule.extensions.forEach((ext) => {
            let index = this.filters.departments_id.findIndex((d) => d == ext.department_id)

            if(index > -1) {
              extensions.push(ext)
            }
          })

          return extensions
  
        } else if(this.departments_id.length > 0) {
          let extensions = []

          this.$store.state.extensionModule.extensions.forEach((ext) => {
            let index = this.departments_id.findIndex((d) => d == ext.department_id)

            if(index > -1) {
              extensions.push(ext)
            }
          })

          return extensions
  
        } else {
          return this.$store.state.extensionModule.extensions
        }
      },
      users() {
        return this.$store.state.userModule.users
      },
      extensionsUser() {
        let extensionsUser = []
        this.extensions.forEach((ext) => {
          let extensionUsers = this.users.filter(u => u.extension_id == ext.id)

          this.$set(ext, 'users', extensionUsers)
          
          extensionsUser.push(ext)
        })

        return extensionsUser
      },
      calls() {
        return this.$store.state.callModule.calls
      },
      extensionCalls() {
        let extensionCalls = {}

        this.calls.forEach((call) => {
          call.connected.forEach((connected) => {
            let index = this.extensionsUser.findIndex((extension) => extension.number == connected)

            if(index > -1) {
              if(!extensionCalls[this.extensionsUser[index].id]) {
                extensionCalls[this.extensionsUser[index].id] = {}
                this.$set(extensionCalls[this.extensionsUser[index].id], 'calls', [])
              }

              extensionCalls[this.extensionsUser[index].id].calls.push(call)
            }
          })
        })

        return extensionCalls
      },
      totalExtensions() {
        return this.extensionsUser.length
      },
      totalExtensionsOnline() {
        let i = 0
        this.extensionsUser.forEach((extension) => {
          if(extension.extension_status_id != 6 && !this.extensionCalls[extension.id] && extension.users.length > 0) {
            let online = false

            extension.users.forEach((u) => {
              if(!u.active_rest_time && u.active_user_session) {
                online = true
              }
            })

            if(online) {
              i = i + 1
            }    
          }
        })

        return i
      },
      totalExtensionsError() {
        let i = 0
        this.extensionsUser.forEach((extension) => {
          if(extension.users.length > 0 && extension.extension_status_id == 6) {
            i = i + 1
          }
        })

        return i
      },
      totalExtensionsInCall() {
        let i = 0
        this.extensionsUser.forEach((extension) => {
          if(!(extension.users.length > 0 && extension.extension_status_id == 6) && this.extensionCalls[extension.id]) {
            i = i + 1
          }
        })
    
        return i
      },
      totalExtensionsInRestTime() {
        let i = 0
        this.extensionsUser.forEach((extension) => {
          if(!(extension.users.length > 0 && extension.extension_status_id == 6) && !this.extensionCalls[extension.id]) {
            let inRestTime = true
            if(extension.users.length > 0) {
              extension.users.forEach((u) => {
                if(!u.active_rest_time && u.active_user_session) {
                  inRestTime = false
                }
              })
            } else {
              inRestTime = false
            }

            if(inRestTime) {
              i = i + 1
            }
          }
        })

        return i
      },
      totalExtensionsOffline() {
        let total = this.totalExtensions - this.totalExtensionsOnline - this.totalExtensionsInCall - this.totalExtensionsInRestTime - this.totalExtensionsError

        if(total < 0) {
          total = 0
        }

        return total
      }
    },
    mounted() {
      this.$store.dispatch("getRegisters", {moduleUrl: "users"})
        .then((users) => {
          this.$store.commit("setUsers", users)
        })
      
      if(this.currentUser.department_id) {
        this.$store.dispatch("getDepartmentsStructureId", {
          departments_id: [this.currentUser.department_id]
        }).then((res) => {
          this.departments_id = res.departments_id
        })
      }
        
    },
    methods: {
      
    }
  }
</script>
<style lang="scss">
</style>