<template lang="pug">
  .sample-wall-1
    .main-content
      .container-fluid
        .row
          .col-lg-6
            screen-my-queues-penalty-0
            screen-my-extension-calls
          .col-lg-6
            screen-my-extension-statuses-large
</template>

<script>
  export default {
    name: 'SampleWall1',
    data () {
      return {
        filter: '',
        showCalls: false,
        showUsers: true,
        showQueue: false
      }
    },
    methods: {
      
    }
  }
</script>
<style lang="scss" scoped>
  .card {
    min-width: 80px !important;
  }
</style>