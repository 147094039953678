// import Vue from 'vue'
import legalDocumentsService from '../services/api/legal_documents'

const legalDocumentModule = {
    state: {
        legalDocument: {},
    },
    mutations: {
        setAccount (state, legalDocument) {
            state.legalDocument = legalDocument
        }
    },
    actions: {
        legalDocumentAddMultipleLines (context, payload) {
            return new Promise((resolve, reject) => {
                legalDocumentsService.legalDocumentAddMultipleLines(payload.legalDocumentId, payload.data)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        
    }
}

export default legalDocumentModule