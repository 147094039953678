<template lang="pug">
.card.p-1.mt-2.small(:class="getUserBackground()")
    .card-body.p-0.mb-0.text-truncate
      .row.g-0.m-0.p-0.d-flex.align-items-center(@click="redirectChannelToTransfer()")
        .col-md-12.p-1
          .d-flex.pb-0.mb-0
            .me-auto
              small 
                span.badge.bg-primary(v-if="calls.length > 0") {{ $t('En uso') }}
                span.badge.bg-warning(v-else-if="isExtensionRestTime") {{extensionRestTimeName}}
                span.badge.bg-success(v-else-if="isExtensionOnline") {{ $t('Disponible') }}
                span.badge.bg-danger(v-else) {{ $t('Desconectado') }}
            div
              template(v-if="currentUser.extension_id && currentUser.extension_id != extension.id")
                small(@dblclick="makeCall(currentUser.extension.number, extension.number)" title="Doble click para llamar")
                    span {{extension.number}}
              template(v-else)
                small
                  span {{extension.number}}
          strong.d-block.mb-0 {{extension.name}}
          small.d-block.mb-1(v-if="extension.department_id") {{extension.department.name}}
          small.d-block.mb-1(v-else) &nbsp;
      div(v-if="showCalls")
        call-item(
          v-for="call in calls" 
          :call="call" 
          :extension="extension" 
        )

</template>

<script>
  import CallItem from '../calls/CallItem.vue'
  export default {
    name: 'UserStatus',
    components: {
      CallItem
    },
    props: { 
      extension: Object,
      calls: Array,
      showCalls: {type: Boolean, default: false },
    },
    data () {
      return {
        callHover: null
      }
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      channelToRedirect() {
        return this.$store.state.callModule.channelToRedirect
      },
      channelToTransfer() {
        return this.$store.state.callModule.channelToTransfer
      },
      currentUser() {
        return this.$store.state.currentUser
      },
      isExtensionRestTime() {
        let restTime = true
        if(this.extension.users && this.extension.users.length > 0) {
          this.extension.users.forEach((u) => {
            if(!u.active_rest_time && u.active_user_session) {
              restTime = false
            }
          })

        } else {
          restTime = false
        }
        
        return restTime
      },
      isExtensionOnline() {
        let online = false
        if(this.extension.users) {
          this.extension.users.forEach((u) => {
            if(!u.active_rest_time && u.active_user_session) {
              online = true
            }
          })

        }
        
        return online
      },
      extensionRestTimeName() {
        let str = ''

        if(this.extension.users) {
          this.extension.users.forEach((u) => {
            if(u.active_rest_time) {
              if(str == '') {
                str = u.active_rest_time.rest_time.name
              } else if (u.active_rest_time.rest_time.name != str) {
                str = 'Pausado'
              }
            }
          })
        }

        return str
      }
    },
    watch: {
      extension(newVal) {
        console.log(newVal)
      }
    },
    methods: {
      setCallHover(call) {
        this.callHover = call
      },
      getUserBackground() {
        let background = 'bg-white';

        if(this.calls.length > 0) {
          background = 'bg-primary-opacity-25'
        }
        else if(this.isExtensionRestTime) {
          background = 'bg-warning-opacity-25'
        }
        else if(this.isExtensionOnline && (this.extension.extension_status_id == 6 || !this.extension.extension_status_id)) {
          background = 'bg-danger-opacity-25'
        }
        else if(this.isExtensionOnline) {
          background = 'bg-success-opacity-25'
        } else {
          background = 'bg-secondary-opacity-25'
        }

        if(this.extension.extension_status_id == 7) {
          background = background + ' blink'
        }

        return background
      },
      getExtensionStatusColor(extension) {
        let color = 'bg-secondary-opacity-25'

        switch(extension.extension_status_id) {
          case 1:
              color = 'bg-secondary-opacity-25';
            break;
          case 2:
              color = 'bg-secondary-opacity-25';
            break;
          case 3:
              color = 'bg-success-opacity-25';
            break;
          case 4:
              color = 'bg-primary-opacity-25';
            break;
          case 5:
              color = 'bg-warning-opacity-25';
            break;
          case 6:
              color = 'bg-danger-opacity-25';
            break;
          case 7:
              color = 'bg-info-opacity-25 blink';
            break;
          case 8:
              color = 'bg-primary-opacity-25 blink';
            break;
          case 9:
              color = 'bg-warning-opacity-25';
            break;
          case 10:
              color = 'bg-warning-opacity-25';
            break;
          case 11:
              color = 'bg-secondary-opacity-25';
            break;
          case 12:
              color = 'bg-secondary-opacity-25';
            break;
        }

        return color
      },
      redirectChannelToTransfer() {
        if(this.channelToRedirect && this.extension.number) {
            let data = {
              channel: this.channelToRedirect,
              extension: this.extension.number
            }
            this.$store.dispatch("redirectChannel", data)
            this.$store.commit("setChannelToRedirect", '')
        } else if(this.channelToTransfer && this.extension.number) {
            let data = {
              channel: this.channelToTransfer,
              extension: this.extension.number
            }
            this.$store.dispatch("attendedtransferChannel", data)
            this.$store.commit("setChannelToTransfer", '')
        }
      },
      makeCall(extension, phone) {
        this.$store.dispatch("makeCall", {
          phone: phone,
          extension: extension
        })
      }
    }
  }
</script>
<style lang="scss">
</style>