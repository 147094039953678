import appService from "./appService";

const authService = {
    login(username, password) {
        return appService.post('/auth/login', {username: username, password: password})
            .then((res) => {
                if (res.data.access_token) {
                    localStorage.setItem('user', JSON.stringify(res.data));
                }

                return res.data
            })
    },
    recovery(username) {
        return appService.post('/auth/recovery', {username: username})
            .then((res) => {
                return res.data
            })
    },
    resetPassword(token, password, confirm) {
        return appService.post('/auth/reset-password', {token, password, confirm})
            .then((res) => {
                return res.data
            })
    },
    logout() {
        return appService.post('/auth/logout')
            .then((res) => {
                return res.data
            })
    },
    me() {
        return appService.post('/auth/me')
            .then((res) => {
                return res.data
            })
    },
    keepAlive() {
        return appService.post('/auth/keep-alive')
            .then((res) => {
                return res.data
            })
    },
    changeCompany(companyId) {
        return appService.post('/auth/change_company/' + companyId)
            .then((res) => {
                if (res.data.user) {
                    let user = JSON.parse(localStorage.getItem('user'));
                    user.user = res.data.user
                    localStorage.setItem('user', JSON.stringify(user));
                }
                return res.data
            })
    },
    changeExtension(username, password, extensionId) {
        return appService.post('/auth/change_extension/' + extensionId, {username: username, password: password})
            .then((res) => {
                if (res.data.user) {
                    let user = JSON.parse(localStorage.getItem('user'));
                    user.user = res.data.user
                    localStorage.setItem('user', JSON.stringify(user));
                }
                return res.data
            })
    },
    forceLogout(userId) {
        return appService.post('/auth/force-logout/' + userId)
            .then((res) => {
                return res.data
            })
    },
}

export default authService;