<template lang="pug">
  .chat-room-chat
    .d-flex.justify-content-between
      div
        button.btn.btn-link(@click="prev()" title="Salir")
          font-awesome-icon(icon="arrow-left")
        button.btn.btn-link( @click="unclose" title="Abrir")
          font-awesome-icon(icon="box-open")
    div
      .row
        .chat
          template(v-for="message in messages")
            chat-room-message-item(:message="message" :key="'messageItem' + message.id")
</template>
<script>
  import ChatRoomMessageItem from './ChatRoomMessageItem.vue'
  export default {
    name: 'ClosedChatRoomChat',
    components: {
      ChatRoomMessageItem
    },
    data () {
      return {
        selectedChatId: 0,
        text: '',
        sendMessageTemplate: false,
        sendFile: false,
        messageTempalateId: 0,
        params: [],
        path: '',
        fileName: '',
        messageEndId: null
      }
    },
    computed: {
      currentUser() {
        return this.$store.state.currentUser
      },
      selectedChatRoom() {
        return this.$store.state.chatRoomModule.selectedClosedChatRoom
      },
      messages() {
        return this.$store.state.chatRoomModule.messages.slice().reverse()
      }
    },
    watch: {
      selectedChatRoom: {
        deep: true,
        handler(newVal, oldVal) {
          if(!oldVal || (newVal.id && newVal.id != oldVal.id)) {
            this.$store.commit("setMessages", [])
            this.getChatRoomMessages(newVal.id, 1).then(() => {
              setTimeout(() => {this.scrollToBottom()}, 500)
            })
          }
        }
      },
      messages() {
        setTimeout(() => {this.scrollToBottom()}, 500)
      },
    },
    mounted() {
      this.resetMessages()
      if(this.selectedChatRoom.id) {
        this.getChatRoomMessages(this.selectedChatRoom.id, 1)
      }
    },
    methods: {
      prev() {
        this.setSelectedChatId(0)
      },
      setSelectedChatId(id) {
        this.$emit('setSelectedChatId', id)
      },
      resetMessages() {
        this.$store.commit("setMessages", [])
      },
      getChatRoomMessages(chatRoomId, page) {
        this.$store.dispatch("getChatRoomMessages", {
          chatRoomId: chatRoomId,
          page: page
        })
      },
      
      unclose() {
        
          this.$store.dispatch("uncloseChatRoom", {
            chatRoomId: this.selectedChatRoom.id,
          }).then(() => {
            this.setSelectedChatId(0)
            this.$store.dispatch("getUserClosedChatRooms")
          })
        
      },
      scrollToBottom() {
          let container = this.$el.querySelector(".chat");
          if(container) {
              container.scrollTop = container.scrollHeight;
          }
      },
    }
  }
</script>
<style lang="scss">
</style>