<template lang="pug">
  .compare-sample
    .container-fluid
      .row.mb-2
        .col-lg-12
          .card
            .card-body
              .row.row-cols-4.mb-2
                .col
                  v-select(:options="accountOptions" :reduce="label => label.id"  label="label" :filterable="false" @search="accountsAjaxSearch" placeholder="Cuenta" v-model="filter.account_id")
                .col
                  v-select(:options="productOptions" :reduce="prod => prod.id"  label="name" :filterable="false" @search="productsAjaxSearch" placeholder="Producto" v-model="filter.product_id")
                    template(slot="option" slot-scope="option")
                      span {{option.name}}
                      br
                      span.small {{'#' + option.code}} 
                      br 
                      span.small EAN: {{option.ean}}
              .row
                .col
                  button.btn.btn-primary(type='button' @click="getData()") Buscar
      .row.mb-2
        .col-lg-6
          .card.mb-2
            .card-body
              input.input(type="month" v-model="dateFirst" @change="getDataFirst()")
          .card.mb-2(v-if="!loading1")
            .card-body
              h5.card-title Facturación por día
              template(v-if="dataFirst['byDate']")
                chart-line(:data="dataFirst.byDate.chart", categoryFiled="date", valueFiled="value", title="Facturación diaria")
          .card.mb-2(v-if="!loading1")
            .card-body
              h5.card-title Facturación por cuenta
              template(v-if="dataFirst['byAccount']")
                chart-simple-column(:data="dataFirst['byAccount'].chart", categoryField="account", valueField="value", title="Facturación")
              .table-responsive
                table.table(v-if="dataFirst.byAccount && dataFirst.byAccount.table")
                  thead
                    tr
                      th Cuenta
                      th Total
                  tbody
                    tr(v-for="row in dataFirst.byAccount.table")
                      td {{row.account}}
                      td {{row.value | numFormat('1,000.00')}}
          .card.mb-2(v-if="!loading1")
            .card-body
              h5.card-title Facturación por producto
              template(v-if="dataFirst['byProduct']")
                chart-simple-column(:data="dataFirst['byProduct'].chart", categoryField="product", valueField="value", title="Facturación")
              .table-responsive
                table.table(v-if="dataFirst.byProduct && dataFirst.byProduct.table")
                  thead
                    tr
                      th Producto
                      th Total
                  tbody
                    tr(v-for="row in dataFirst.byProduct.table")
                      td {{row.product}}
                      td {{row.value | numFormat('1,000.00')}}
        .col-lg-6
          .card.mb-2
            .card-body
              input.input(type="month" v-model="dateSecond" @change="getDataSecond()")
          .card.mb-2(v-if="!loading2")
            .card-body
              h5.card-title Facturación por día
              template(v-if="dataSecond['byDate']")
                chart-line(:data="dataSecond.byDate.chart", categoryFiled="date", valueFiled="value", title="Facturación diaria")
          .card.mb-2(v-if="!loading2")
            .card-body
              h5.card-title Facturación por cuenta
              template(v-if="dataSecond['byAccount']")
                chart-simple-column(:data="dataSecond['byAccount'].chart", categoryField="account", valueField="value", title="Facturación")
              .table-responsive
                table.table(v-if="dataSecond.byAccount && dataSecond.byAccount.table")
                  thead
                    tr
                      th Cuenta
                      th Total
                  tbody
                    tr(v-for="row in dataSecond.byAccount.table")
                      td {{row.account}}
                      td {{row.value | numFormat('1,000.00')}}
          .card.mb-2(v-if="!loading2")
            .card-body
              h5.card-title Facturación por producto
              template(v-if="dataSecond['byProduct']")
                chart-simple-column(:data="dataSecond['byProduct'].chart", categoryField="product", valueField="value", title="Facturación")
              .table-responsive
                table.table(v-if="dataSecond.byProduct && dataSecond.byProduct.table")
                  thead
                    tr
                      th Producto
                      th Total
                  tbody
                    tr(v-for="row in dataSecond.byProduct.table")
                      td {{row.product}}
                      td {{row.value | numFormat('1,000.00')}}
</template>

<script>
  import ChartSimpleColumn from '../../components/chart/ChartSimpleColumn.vue'
  import ChartLine from '../../components/chart/ChartLine.vue'
  export default {
    name: 'ReportCompareSample',
    components: {
      ChartSimpleColumn,
      ChartLine
    },
    data () {
      return {
        filter: {},
        dataFirst: {},
        dataSecond: {},
        dateFirst: null,
        dateSecond: null,
        accountOptions: [],
        productOptions: [],
        loading1: false,
        loading2: false
      }
    },
    methods: {
      getData() {
        this.getDataFirst()
        this.getDataSecond()
      },
      getDataFirst() {
        this.loading1 = true
        let filter = this.filter
        let start = this.dateFirst + '-01';
        let end = this.$moment(start).clone().endOf('month').format('YYYY-MM-DD');

        this.$set(filter, 'start', start)
        this.$set(filter, 'end', end)

        this.$store.dispatch("getReport", {filter: filter})
          .then((data) => {
            this.dataFirst = data
            this.loading1 = false
          })
      },
      getDataSecond() {
        this.loading2 = true
        let filter = this.filter
        let start = this.dateSecond + '-01';
        let end = this.$moment(start).clone().endOf('month').format('YYYY-MM-DD');

        this.$set(filter, 'start', start)
        this.$set(filter, 'end', end)

        this.$store.dispatch("getReport", {filter: filter})
          .then((data) => {
            this.dataSecond = data
            this.loading2 = false
          })
      },
      accountsAjaxSearch(search, loading) {
        let filter = {
              filter: search,
              moduleUrl: 'accounts',
              columns: 'id,name',
          }
          loading(true)
          this.$store.dispatch('getRegistersListAjax', filter).then((res) => {
              this.accountOptions = res
              loading(false)
          })
      },
      productsAjaxSearch(search, loading) {
        let filter = {
              filter: search,
              moduleUrl: 'products',
              columns: 'id,name,code,ean',
          }
          loading(true)
          this.$store.dispatch('getRegistersListAjax', filter).then((res) => {
              this.productOptions = res
              loading(false)
          })
      },
    }
  }
</script>
<style lang="scss">
  
</style>