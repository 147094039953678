<template lang="pug">
  .report-show
    .header-actions
      .header-actions-content
        .d-flex
          .flex-grow-1.p-2(v-if="reports")
            .row.justify-content-center
              .col-md-6
                v-select(:options="reports" :reduce="report => report.id"  label="name" v-model="id" placeholder="Seleccione una ")
    .main-content
      .container-fluid
        .row
          .col
            .card.mb-2
              .card-body
                .row.mb-2
                  .col
                    label Desde
                    input.form-control(type="date" v-model="filters.start")
                  .col
                    label Hasta
                    input.form-control(type="date" v-model="filters.end")
                  .col
                    label Departamentos
                    filter-department(defaultKey="reports" @updateDepartmentIds="updateDepartmentIds")
                  .col
                    label Usuarios
                    v-select(:options="users" :reduce="user => user.id"  label="name" v-model="filters.users_id" placeholder="Seleccione usuarios" multiple)
                  .col
                .row
                  .col
                    button.btn.btn-primary.me-2(@click="search") Filtrar
                    button.btn.btn-success() Exportar CSV

        .row(v-if="selectReport && selectReport.report_report_items && selectReport.report_report_items.length > 0")
          .mb-2(v-for="reportReportItem in selectReport.report_report_items" :class="'col-md-' + reportReportItem.width")
            component(:is="reportReportItem.report_item.component" :filters="filters" :reportReportItem="reportReportItem")
            
</template>

<script>
  import ReportTotalCallByCallType from "../../components/reports/ReportTotalCallByCallType.vue"
  import ReportTotalCallByCallStatus from "../../components/reports/ReportTotalCallByCallStatus.vue"
  import ReportTotalCallByCallEnd from "../../components/reports/ReportTotalCallByCallEnd.vue"
  import ReportTotalCallByCallStatusHour from "../../components/reports/ReportTotalCallByCallStatusHour.vue"
  import ReportTotalCallByWaitStep from "../../components/reports/ReportTotalCallByWaitStep.vue"
  import ReportTotalCallSummaryUsers from "../../components/reports/ReportTotalCallSummaryUsers.vue"
  import ReportTotalSessionsSummaryUsers from "../../components/reports/ReportTotalSessionsSummaryUsers.vue"
  import ReportTotalRestTimesSummaryUsers from "../../components/reports/ReportTotalRestTimesSummaryUsers.vue"
  import ReportYearSummaryServiceLevel from "../../components/reports/ReportYearSummaryServiceLevel.vue"
  import ReportYearSummaryCallEnd from "../../components/reports/ReportYearSummaryCallEnd.vue"
  import ReportSummaryDepartment from "../../components/reports/ReportSummaryDepartment.vue"
  import ReportBsKpi from "../../components/reports/ReportBSKpi.vue"
  export default {
    name: 'ReportShow',
    components: {
      ReportTotalCallByCallType,
      ReportTotalCallByCallStatus,
      ReportTotalCallByCallStatusHour,
      ReportTotalCallByCallEnd,
      ReportTotalCallByWaitStep,
      ReportTotalCallSummaryUsers,
      ReportTotalSessionsSummaryUsers,
      ReportTotalRestTimesSummaryUsers,
      ReportYearSummaryServiceLevel,
      ReportYearSummaryCallEnd,
      ReportSummaryDepartment,
      ReportBsKpi
    },
    beforeMount() {

      this.$store.dispatch("getMyReports")
        .then(() => {
          if(this.$route.params.id) {
            this.id = parseInt(this.$route.params.id)
          } else if(this.reports && this.reports[0]) {
            this.id = this.reports[0].id
          }
        })
    
    },
    data () {
      return {
        id: 0,
        showTemplate: true,
        filters: {
          start: null,
          end: null,
          departments_id: [],
          users_id: [],
          counter: 0
        }
      }
    },
    computed: {
      users() {
        return this.$store.state.userModule.users
      },
      reports() {
        return this.$store.state.reportModule.reports
      },
      selectReport() {
        let selectReport = null
        if(this.reports) {
          let index = this.reports.findIndex((d) => d.id == this.id)

          if(index > -1) {
            selectReport = this.reports[index]
          }
        }

        return selectReport
      },
    },
    watch: {
      id() {
        this.showTemplate = false
        setTimeout(()=> {
          this.showTemplate = true
        }, 1000)
      }
    },
    methods: {
      search() {
        this.filters.counter++
      },
      updateDepartmentIds(event) {
        this.$set(this.filters, 'departments_id', event.departments_id)
      }
    }
  }
</script>