<template lang="pug">
  .gc-extension
    
</template>
<script>

  export default {
    name: 'GcExtension',
    data () {
      return {
        extensionStatuses: [
          {id: 1, extension_status_code: '-2', device_state_code: '-', name: 'Extension eliminada Dialplan'},
          {id: 2, extension_status_code: '-1', device_state_code: '-', name: 'Hint eliminada Dialplan'},
          {id: 3, extension_status_code: '0', device_state_code: 'NOT_INUSE', name: 'Disponible'},
          {id: 4, extension_status_code: '1', device_state_code: 'INUSE', name: 'En uso'},
          {id: 5, extension_status_code: '2', device_state_code: 'BUSY', name: 'Comunicado'},
          {id: 6, extension_status_code: '4', device_state_code: 'UNAVAILABLE', name: 'No accesible'},
          {id: 7, extension_status_code: '8', device_state_code: 'RINGING', name: 'Sonando'},
          {id: 8, extension_status_code: '9', device_state_code: 'RINGINUSE', name: 'Sonando y en uso'},
          {id: 9, extension_status_code: '16', device_state_code: 'ONHOLD', name: 'Retenida'},
          {id: 10, extension_status_code: '17', device_state_code: '-', name: 'Retenida y en uso'},
          {id: 11, extension_status_code: '-', device_state_code: 'UNKNOWN', name: 'Desconocido'},
          {id: 12, extension_status_code: '-', device_state_code: 'INVALID', name: 'Invalido'},
        ]
      }
    },
    computed: {
      extensions() {
        return this.$store.state.extensionModule.extensions
      },
      users() {
        return this.$store.state.userModule.users
      },
    },
    mounted() {
      window.Echo.channel('App.Pbx')
        .listen('PbxExtensionStatus', (e) => {
            this.processExtensionStatus(e.event)
        })
        /*.listen('PbxDeviceStateChange', (e) => {
            this.processDeviceStateChange(e.event)
        })*/

        this.$store.dispatch("getRegisters", {moduleUrl: "extensions"})
          .then((extensions) => {
            this.$store.commit("setExtensions", extensions)
          })
    },
    methods: {
      processExtensionStatus(event) {
        let index = this.extensions.findIndex((e) => e.number == event.Exten)

        if(index > -1) {
          let indexExtensionStatus = this.extensionStatuses.findIndex((status) => status.extension_status_code == event.Status)

          if(indexExtensionStatus > -1) {
            let extension = this.extensions[index]
            let extensionStatus = this.extensionStatuses[indexExtensionStatus]

            this.$set(extension, 'extension_status_id', extensionStatus.id)
            this.$set(extension, 'extension_status', extensionStatus)
            this.$store.commit("updateExtension", extension)

            let indexUser = this.users.findIndex((u) => u.extension_id == extension.id)

            if(indexUser > -1) {
              let user = this.users[indexUser]

              this.$set(user, 'extension', extension)
              this.$store.commit("updateUser", user)
            } else {
              let indexUserSecondary = this.users.findIndex((u) => u.secondary_extension_id == extension.id)

              if(indexUserSecondary > -1) {
                let user = this.users[indexUserSecondary]

                this.$set(user, 'secondary_extension', extension)
                this.$store.commit("updateUser", user)
              }
            }
          }
        }
      },
      /*processDeviceStateChange(event) {
        console.log(event)
      }*/
    }
  }
</script>
<style lang="scss">
</style>