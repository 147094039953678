<template lang="pug">
  tr.my-queue-full
    td.text-end
      h5
        a(data-bs-toggle="modal" :data-bs-target="'#extensionsQueue' + queue.number")
          span.badge(:style="'background: ' + queue.color + ';'" v-if="queue.short_name") {{queue.short_name}}
          span.badge(:style="'background: ' + queue.color + ';'" v-else) {{queue.number}}
      .modal.fade(:id="'extensionsQueue' + queue.number" tabindex='-1' :aria-labelledby="'extensionsQueue' + queue.number + 'Label'" aria-hidden='true')
        .modal-dialog.modal-xl
          .modal-content
            .modal-header
              h5.modal-title(:id="'extensionsQueue' + queue.number + 'Label'") Agentes 
              button.btn-close(type='button' data-bs-dismiss='modal' aria-label='Close')
            .modal-body
              .row.row-cols-xxl-4.row-cols-xl-3(:key="'modalQueues' + counterKey")
                .col.text-start(v-for="extension in queueExtensionUsers")
                  extension-user-status(
                      :extension="extension" 
                      :calls="extensionCalls[extension.id] ? extensionCalls[extension.id].calls : []" 
                      :showCalls="false" 
                    )
    td.text-center.pb-0.position-relative
      h5.text-info-opacity-20.position-absolute(style="top: 3px;left: 15px")
        font-awesome-icon(icon="angle-double-right")
      h1.mt-1.mb-2.text-info {{totalQueueCalls}}
      .bg-info-opacity-25.text-end.pe-2.small.pt-1.pb-1(v-if="showQueue")
        font-awesome-icon.me-1(icon="clock")
        span(v-if="firstQueueCall" :key="'durationFirstCall' + counterKey") {{[firstQueueCall.start, "unix"] | unixToDateTimeEn | diffToNow | timeInHoursMin}}
    td.text-center.pb-0.position-relative
      h5.text-secondary-opacity-20.position-absolute(style="top: 3px;left: 15px")
        font-awesome-icon(icon="exclamation-triangle")
      h1.mt-1.mb-2.text-secondary {{queue.report_today.total_lost_calls}}
      .bg-secondary-opacity-25.d-flex.justify-content-between.small.pt-1.pb-1(v-if="showQueue")
        .ps-2
          //font-awesome-icon.me-1(icon="percent")
          //span 0.1
        .pe-2
          font-awesome-icon.me-1(icon="history")
          span {{queue.report_today.last_hour_total_lost_calls}}
    td.text-center.pb-0.position-relative
      h5.text-primary-opacity-20.position-absolute(style="top: 3px;left: 15px")
        font-awesome-icon(icon="phone")
      h1.mt-1.mb-2.text-primary {{queue.report_today.total_calls}}
      .bg-primary-opacity-25.text-end.pe-2.small.pt-1.pb-1(v-if="showQueue")
        font-awesome-icon.me-1(icon="history")
        span {{queue.report_today.last_hour_total_calls}}
    td.text-center.pb-0.position-relative
      h5.text-warning-opacity-20.position-absolute(style="top: 3px;left: 15px")
        font-awesome-icon(icon="clock")
      h1.mt-1.mb-2.text-warning {{Math.round(queue.report_today.last_hour_wait_time)}}
      .bg-warning-opacity-25.text-end.pe-2.small.pt-1.pb-1(v-if="showQueue")
        font-awesome-icon.me-1(icon="calendar")
        span {{Math.round(queue.report_today.wait_time)}}
    td.text-center.pb-0.position-relative
      h5.text-success-opacity-20.position-absolute(style="top: 3px;left: 15px")
        font-awesome-icon(icon="check")
      h1.mt-1.mb-2.text-success {{totalActiveQueueUsers}}
      .bg-success-opacity-25.text-end.pe-2.small.pt-1.pb-1(v-if="showQueue")
        font-awesome-icon.me-1(icon="phone")
        span {{totalQueueUsersCalls}}
    td.text-center.pb-0.position-relative
      h5.text-info-opacity-20.position-absolute(style="top: 3px;left: 15px")
        font-awesome-icon(icon="thumbs-up")
      h1.mt-1.mb-2.text-info(v-if="queue.disable_statistics") -
      h1.mt-1.mb-2.text-info(v-else) {{Math.round(queue.report_today.target_wait_time)}}%
    td.text-center.pb-0.position-relative
      h5.text-dark-opacity-20.position-absolute(style="top: 3px;left: 15px")
        font-awesome-icon(icon="clipboard-check")
      h1.mt-1.mb-2.text-dark(v-if="queue.disable_statistics") -
      h1.mt-1.mb-2.text-dark(v-else) {{Math.round(queue.report_today.pickup_rate)}}%
</template>
<script>
  import UserStatus from '../users/UserStatus.vue'
  import ExtensionUserStatus from '../extensions/ExtensionUserStatus.vue'
  export default {
    name: 'MyQueueFull',
    components: {
      UserStatus,
      ExtensionUserStatus
    },
    data () {
      return {
      }
    },
    props: {
      queue: {type: Object, default: function() {return {} }},
      showQueue: Boolean
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      queues() {
        return this.$store.state.queueModule.queues
      },
      extensions() {
        return this.$store.state.extensionModule.extensions
      },
      users() {
        return this.$store.state.userModule.users
      },
      calls() {
        return this.$store.state.callModule.calls
      },
      extensionCalls() {
        let extensionCalls = {}

        this.calls.forEach((call) => {
          call.connected.forEach((connected) => {
            let index = this.extensions.findIndex((extension) => extension.number == connected)

            if(index > -1) {
              if(!extensionCalls[this.extensions[index].id]) {
                extensionCalls[this.extensions[index].id] = {}
                this.$set(extensionCalls[this.extensions[index].id], 'calls', [])
              }

              extensionCalls[this.extensions[index].id].calls.push(call)
            }
          })
        })

        return extensionCalls
      },
      queueExtensions() {
        let queueExtensions = []
        this.extensions.forEach((ext) => {
          let index = this.queue.queue_extensions.findIndex((qe) => qe.extension_id == ext.id)

          if(index > -1) {
            queueExtensions.push(ext)
          }
        })

        return queueExtensions
      },
      queueExtensionUsers() {
        let queueExtensionUsers = []

        this.queueExtensions.forEach((qe) => {
          let index = this.users.findIndex((u) => u.extension_id && u.extension_id == qe.id)

          if(index > -1) {
            this.$set(qe, 'user', this.users[index])
          }

          queueExtensionUsers.push(qe)
        })

        return queueExtensionUsers
      },
      queueUsers() {
        let queueUsers = []
        this.users.forEach((u) => {
          let index = this.queueExtensions.findIndex((qe) => u.extension_id && u.extension_id == qe.id)

          if(index > -1) {
            queueUsers.push(u)
          }
        })

        return queueUsers
      },
      activeQueueUsers() {
        let activeQueueUsers = []

        this.queueExtensionUsers.forEach((qeu) => {
          if(qeu.user && qeu.user.active_user_session && !qeu.user.active_rest_time && !this.extensionCalls[qeu.id] && qeu.extension_status_id == 3) {
            activeQueueUsers.push(qeu)
          }
        })

        return activeQueueUsers
      },
      queueUsersCalls() {
        let userCalls = {}

        this.calls.forEach((call) => {
          call.connected.forEach((connected) => {
            let index = this.queueUsers.findIndex((u) => ((u.extension && u.extension.number == connected) || (u.secondary_extension && u.secondary_extension.number == connected)))

            if(index > -1) {
              if(!userCalls[this.users[index].id]) {
                userCalls[this.users[index].id] = {}
                this.$set(userCalls[this.users[index].id], 'calls', [])
              }

              userCalls[this.users[index].id].calls.push(call)
            }
          })
        })

        return userCalls
      },
      totalActiveQueueUsers() {
        return this.activeQueueUsers.length
      },
      totalQueueUsersCalls() {
        
        return Object.keys(this.queueUsersCalls).length
      },
      totalIdleExtensions() {
        let idleExtensions = this.queueExtensions.filter((qe) => qe.extension_status_id == 3)

        return idleExtensions.length
      },
      totalInUseExtensions() {
        let idleExtensions = this.queueExtensions.filter((qe) => qe.extension_status_id == 7 || qe.extension_status_id == 8)

        return idleExtensions.length
      },
      queueCalls() {
        return this.calls.filter((x) => x.queue == this.queue.number)
      },
      totalQueueCalls() {
        return this.queueCalls.length
      },
      firstQueueCall() {
        if(this.totalQueueCalls > 0) {
          return this.queueCalls[0]
        } else {
          return null
        }
      }
    },
    mounted() {
    },
    methods: {
      
    }
  }
</script>
<style lang="scss">
</style>