<template lang="pug">
  .my-calls(v-if="currentUser.extension_id")
    .calls-notifications
      .toast-container
        div(v-for="(call, index) in reverseMyCalls" :key="'divmyCall-' + call.Linkedids[0]") 
          my-call-item(:call="call" :index="reverseMyCalls.length - index - 1" :key="'myCall-' + call.Linkedids[0]")
    #offcanvasMyCallsUserStatus.offcanvas.offcanvas-end(tabindex='-1' aria-labelledby='offcanvasMyCallsUserStatusLabel')
      .offcanvas-header
        h5#offcanvasMyCallsUserStatusLabel.offcanvas-title Estados de las usuarios
        a(type='button' data-bs-dismiss='offcanvas' aria-label='Close')
          font-awesome-icon(icon='times')
      .offcanvas-body
        

</template>
<script>
  import MyCallItem from './MyCallItem.vue'
  export default {
    name: 'MyCalls',
    components: {
      MyCallItem
    },
    data () {
      return {
      }
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      myCalls() {
        return this.$store.state.callModule.myCalls
      },
      reverseMyCalls() {
        return this.myCalls.slice().reverse()
      },
      currentUser() {
        return this.$store.state.currentUser
      },
    },
    watch: {
      
    },
    mounted() {
      this.$store.dispatch("getCurrentUserCallEnds")
    },
    methods: {
    }
  }
</script>
<style lang="scss">
  .my-calls {
    position: fixed;
    z-index: 100;
  }
  .calls-notifications {
    //overflow: auto;
    z-index: 1001;
    right: 0px;
    bottom: 0px;
    max-height: 50%;
    position: fixed;
    padding-bottom: 20px;
    padding-right: 15px;
  }
</style>