<template lang="pug">
  .screen-queues-full
    .card.mb-3
      .card-body
        .d-flex.justify-content-between
          h5.card-title.mb-1(v-if="filters.name") {{ filters.name }}
          h5.card-title.mb-1(v-else) {{ $t('Mis colas') }}
          div
            template(v-if="filters.hiddenTable")
              a.text-dark.me-2(@click="showTable = !showTable" href="#!")
                font-awesome-icon(:icon="['fas', 'window-minimize']")
            template(v-if="showTable")
              a.text-dark(v-if="showQueue" @click="showQueue = !showQueue" href="#!")
                font-awesome-icon(icon="compress-alt")
              a.text-dark(v-else  @click="showQueue = !showQueue"  href="#!")
                font-awesome-icon(icon="expand-alt")

        table.table(v-if="showTable")
          thead
            tr.text-center
              th 
              th {{ $t('En Cola') }}
              th {{ $t('Perdidas') }}
              th {{ $t('Totales') }}
              th {{ $t('Espera') }}
              th {{ $t('Disponibles') }}
              th {{ $t('Nivel Servicio') }}
              th {{ $t('Pickup rate') }}

          tbody
            my-queue-full(v-for="queue in queues" :queue="queue" :showQueue="showQueue")
          tfoot(v-if="queues.length > 1")
            my-queue-full-total(:key="'totalQueues' + counterKey" :queues="queues")
              
</template>
<script>
  import MyQueueFull from '../queues/MyQueueFullPenalty0.vue'
  import MyQueueFullTotal from '../queues/MyQueueFullTotalPenalty0.vue'
  export default {
    name: 'ScreenMyQueuesFull',
    components: {
      MyQueueFull,
      MyQueueFullTotal
    },
    props: {
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        showQueue: false,
        showTable: true,
      }
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      currentUser() {
        return this.$store.state.currentUser
      },
      queues() {
        return this.$store.state.queueModule.queues
      }
    },
    mounted() {
        if(this.filters.hiddenTable) {
          this.showTable = false
        }
        if(this.filters.queues_id) {
          this.$store.dispatch("getQueuesByIds", {
            queues_id: this.filters.queues_id
          })
        }
    },
    methods: {
      
    }
  }
</script>
<style lang="scss">
</style>