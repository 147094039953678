import Vue from 'vue'
import queuesService from '../services/api/queues'

const queueModule = {
    state: {
        queues: [],
        hiddenQueues: [],
    },
    mutations: {
        setQueues (state, queues) {
            state.queues = queues
        },
        addQueue (state, queue) {
            let index = state.queues.findIndex(x => x.id === queue.id)

            if(index == -1) {
                state.queues.push(queue)
            }
        },
        updateQueue (state, queue) {
            let index = state.queues.findIndex(x => x.id === queue.id)

            if(index > -1) {
                Vue.set(state.queues, index, queue)
            }
        },
        deleteQueue (state, queue) {
            let index = state.queues.findIndex(x => x.id === queue.id)

            if(index > -1) {
                state.queues.splice(index,1)
            }
        },
        deleteQueueByIndex (state, index) {
            if(index > -1) {
                state.queues.splice(index,1)
            }
        },
        setHiddenQueues (state, queues) {
            state.hiddenQueues = queues
        },
        addHiddenQueue (state, queue) {
            let index = state.hiddenQueues.findIndex(x => x.id === queue.id)

            if(index == -1) {
                state.hiddenQueues.push(queue)
            }
        },
        updateHiddenQueue (state, queue) {
            let index = state.hiddenQueues.findIndex(x => x.id === queue.id)

            if(index > -1) {
                Vue.set(state.hiddenQueues, index, queue)
            }
        },
        deleteHiddenQueue (state, queue) {
            let index = state.hiddenQueues.findIndex(x => x.id === queue.id)

            if(index > -1) {
                state.hiddenQueues.splice(index,1)
            }
        },
        deleteHiddenQueueByIndex (state, index) {
            if(index > -1) {
                state.hiddenQueues.splice(index,1)
            }
        },
    },
    actions: {
        getMyQueues (context) {
            return new Promise((resolve, reject) => {
                queuesService.getMyQueues()
                    .then((queues) => {
                        context.commit('setQueues', queues)
                        resolve(queues)
                    })
                    .catch(reject)
            })
        },
        getMyHiddenQueues (context) {
            return new Promise((resolve, reject) => {
                queuesService.getMyHiddenQueues()
                    .then((queues) => {
                        context.commit('setHiddenQueues', queues)
                        resolve(queues)
                    })
                    .catch(reject)
            })
        },
        getDepartmentsQueues (context, payload) {
            return new Promise((resolve, reject) => {
                queuesService.getDepartmentsQueues(payload)
                    .then((queues) => {
                        context.commit('setQueues', queues)
                        resolve(queues)
                    })
                    .catch(reject)
            })
        },
        getQueuesByIds (context, payload) {
            return new Promise((resolve, reject) => {
                queuesService.getQueuesByIds(payload)
                    .then((queues) => {
                        context.commit('setQueues', queues)
                        resolve(queues)
                    })
                    .catch(reject)
            })
        },
        getHiddenQueuesByIds (context, payload) {
            return new Promise((resolve, reject) => {
                queuesService.getQueuesByIds(payload)
                    .then((queues) => {
                        context.commit('setHiddenQueues', queues)
                        resolve(queues)
                    })
                    .catch(reject)
            })
        },
        getQueuesTotalCallsByHours (context, payload) {
            return new Promise((resolve, reject) => {
                queuesService.getQueuesTotalCallsByHours(payload)
                    .then((queues) => {
                        resolve(queues)
                    })
                    .catch(reject)
            })
        },
        updateQueueExtensions (context, payload) {
            return new Promise((resolve, reject) => {
                queuesService.updateQueueExtensions(payload.queueId, payload.data)
                    .then((queues) => {
                        resolve(queues)
                    })
                    .catch(reject)
            })
        },
        getQueueExtensions (context, payload) {
            return new Promise((resolve, reject) => {
                queuesService.getQueueExtensions(payload.queueId)
                    .then((queues) => {
                        resolve(queues)
                    })
                    .catch(reject)
            })
        },
        pauseAllQueues (context, payload) {
            return new Promise((resolve, reject) => {
                queuesService.pauseAllQueues(payload.extensionId)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        unpauseAllQueues (context, payload) {
            return new Promise((resolve, reject) => {
                queuesService.unpauseAllQueues(payload.extensionId)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        pauseExtensionQueue (context, payload) {
            return new Promise((resolve, reject) => {
                queuesService.pauseExtensionQueue(payload.extensionId, payload.queueId)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        unpauseExtensionQueue (context, payload) {
            return new Promise((resolve, reject) => {
                queuesService.unpauseExtensionQueue(payload.extensionId, payload.queueId)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
    }
}

export default queueModule