<template lang="pug">
  .inDeliveryNote-show(v-if="legalDocument.id")
    generate-invoice(:legalDocument="legalDocument")
    .header-actions
      .header-actions-content
        .d-flex
          .flex-grow-1.p-2
            .btn-group.me-2.ms-2
              //.dropdown(v-if="legalDocument.legal_document_status.next_step")
                button.btn.btn-outline-primary.dropdown-toggle(type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false")
                  font-awesome-icon.me-2(:icon="['fas', 'plus-circle']")
                  span.me-2 Generar
                ul.dropdown-menu(aria-labelledby="dropdownMenuButton1")
                  li
                    button.dropdown-item(type='button' data-bs-toggle='modal' data-bs-target='#generateInvoiceModal') Factura
            //.btn-group.me-2.ms-2
              button.btn.btn-outline-secondary
                font-awesome-icon.me-2(:icon="['fas', 'euro-sign']")
                span Modificar pagos
          .p-2
            .btn-group.me-2
              a.btn.btn-outline-secondary(type='button' href="/delivery_notes")
                font-awesome-icon.me-2(:icon="['fas', 'arrow-left']")
                span Volver
            .btn-group.me-2
              a.btn.btn-outline-info(type='button' :href="backendUrl + '/legal_documents/' + legalDocument.id + '/pdf'" target="_blank")
                font-awesome-icon.me-2(icon="file-pdf")
                span PDF
              a.btn.btn-outline-primary(type='button' :href="'/delivery_notes/' + legalDocument.id + '/edit'")
                font-awesome-icon.me-2(:icon="['fas', 'edit']")
                span Editar
            .btn-group.me-2
              button.btn.btn-outline-danger(type='button' @click="deleteLegalDocument()")
                font-awesome-icon.me-2(:icon="['fas', 'trash']")
                span Eliminar
    .main-content
      .p-2
        .container-fluid
          .row
            .col-12
              .card
                .card-body
                  .row
                    .col-4
                      .border.p-2
                        table.table.table-border.table-sm
                          tbody
                            tr
                              td(colspan="2") 
                                h3.card-title Albarán de entrada
                            tr
                              td Número
                              td 
                                h5.card-title {{legalDocument.fullnumber}}
                            tr
                              td Estado
                              td 
                                h5
                                  span.badge(:style="'background:' + legalDocument.legal_document_status.background + ';color:' + legalDocument.legal_document_status.color + ';'") {{legalDocument.legal_document_status.name}}
                            tr
                              td Error
                              td
                                span.badge.bg-danger(v-if="legalDocument.error") Error
                                span.badge.bg-success(v-else) Correcto
                            tr
                              td Cuenta
                              td
                                strong {{legalDocument.account.name}}
                            tr
                              td Fecha
                              td
                                strong {{legalDocument.date}}
                            tr
                              td Código
                              td
                                strong {{legalDocument.code}}
                            tr
                              td Finalizado
                              td
                                span.badge.bg-success(v-if="legalDocument.finished") Si
                                span.badge.bg-secondary(v-else) No
                    .col-4
                      .border.p-2
                        h5.card-title Dirección
                        template(v-if="legalDocument.shipping_legal_document_address")
                          span {{legalDocument.shipping_legal_document_address.address}} {{legalDocument.shipping_legal_document_address.address_aux}}
                          br
                          span {{legalDocument.shipping_legal_document_address.postal_code}} {{legalDocument.shipping_legal_document_address.location}}
                          br
                          span {{legalDocument.shipping_legal_document_address.region}} {{legalDocument.shipping_legal_document_address.country.name}}
                    .col-4
                      legal-document-status-log(:legalDocumentStatusLogArray="legalDocument.legal_document_status_log")
                  .row.mt-4
                    .col-12
                      .border.p-2
                        table.table.table-border
                          thead
                            th(style="width: 100px") 
                            th(style="width: 200px") Producto
                            th Descripción
                            th(style="width: 150px") Unidades
                            th(style="width: 150px") Precio
                            th(style="width: 150px") Dte%
                            th(style="width: 150px") Impuestos
                            th.pe-2.text-end(style="width: 150px") Total
                          tbody
                            tr(v-for="legalDocumentLine in legalDocument.legal_document_lines")
                              template(v-if="legalDocumentLineId == legalDocumentLine.id")
                                td 
                                td 
                                  v-select(:options="productOptions" :reduce="prod => prod.id"  label="name" :filterable="false" @search="productsAjaxSearch" placeholder="Producto" v-model="product_id")
                                    template(slot="option" slot-scope="option")
                                      span {{option.name}}
                                      br
                                      span.small {{'#' + option.code}} 
                                      br 
                                      span.small EAN: {{option.ean}}
                                td
                                  .row
                                    .col
                                      .input-group
                                        input.form-control(type="text" placeholder="Lote" v-model="lot")    
                                    .col
                                      .input-group
                                        input.form-control(type="date" placeholder="Fecha de caducidad" v-model="expiration_date")    
                                td 
                                  .input-group
                                    input.form-control(type="number" step="0.0001" placeholder="Unidades" v-model="quantity")
                                td 
                                  .input-group
                                    input.form-control(type="number" step="0.0001" placeholder="Precio" v-model="price")
                                td 
                                  .input-group
                                    input.form-control(type="number" step="0.01" min="0" max="100" placeholder="Dte.%" v-model="discount")
                                td 
                                  v-select(:options="taxes" :reduce="label => label.id"  label="label" placeholder="Impuestos" multiple="multiple" v-model="taxes_id")
                                td
                                  .btn-group.me-2
                                    button.btn.btn-primary.btn-sm(@click="updateLine()")
                                      font-awesome-icon.me-1(icon="save")
                                  .btn-group
                                    button.btn.btn-secondary.btn-sm(@click="resetEditLine()")
                                      font-awesome-icon.me-1(icon="ban")
                              template(v-else)
                                td
                                  div(v-if="legalDocumentLine.parent_legal_document_line")
                                    button.btn.btn-sm.btn-primary(type="button")  
                                      br
                                      small {{legalDocumentLine.parent_legal_document_line.legal_document.fullnumber}}
                                td 
                                  span(v-if="legalDocumentLine.product_id") {{legalDocumentLine.legal_document_line_product.code}}
                                  br
                                  .btn-group
                                    button.btn.btn-link.text-dark(@click="editLegalDocumentLine(legalDocumentLine)")
                                      font-awesome-icon(icon="edit")
                                  .btn-group
                                    button.btn.btn-link.text-danger(@click="deleteLegalDocumentLine(legalDocumentLine.id)")
                                      font-awesome-icon(icon="trash")
                                td
                                  span(v-if="legalDocumentLine.product_id") {{legalDocumentLine.legal_document_line_product.ean}} {{legalDocumentLine.legal_document_line_product.name}}
                                  div(v-if="legalDocumentLine.description" v-html="legalDocumentLine.description.description")
                                  div(v-if="legalDocumentLine.product_id && legalDocumentLine.legal_document_line_product.lot") Lote: {{legalDocumentLine.legal_document_line_product.lot}}
                                  div(v-if="legalDocumentLine.product_id && legalDocumentLine.legal_document_line_product.expiration_date") Fecha expiración: {{legalDocumentLine.legal_document_line_product.expiration_date}}
                                  legal-document-relationed(:relatedLegalDocumentLines="legalDocumentLine.related_legal_document_lines")
                                td {{legalDocumentLine.quantity | numFormat('1,000.0000')}}
                                td {{legalDocumentLine.price | numFormat('1,000.0000')}} {{legalDocument.money.symbol}}
                                td {{legalDocumentLine.discount | numFormat('1,000.00')}}%
                                td 
                                  span.badge.bg-secondary(v-for="tax in legalDocumentLine.taxes") {{tax.pivot.name}}
                                td.text-end {{legalDocumentLine.price_without_tax | numFormat('1,000.0000')}} {{legalDocument.money.symbol}}
                          tfoot
                            template(v-if="!legalDocument.finished && legalDocumentLineId == 0")
                              th 
                              th 
                                v-select(:options="productOptions" :reduce="prod => prod.id"  label="name" :filterable="false" @search="productsAjaxSearch" placeholder="Producto" v-model="product_id")
                                  template(slot="option" slot-scope="option")
                                    span {{option.name}}
                                    br
                                    span.small {{'#' + option.code}} 
                                    br 
                                    span.small EAN: {{option.ean}}
                              th
                                .row
                                  .col
                                    .input-group
                                      input.form-control(type="text" placeholder="Lote" v-model="lot")    
                                  .col
                                    .input-group
                                      input.form-control(type="date" placeholder="Fecha de caducidad" v-model="expiration_date")
                              th 
                                .input-group
                                  input.form-control(type="number" step="0.0001" placeholder="Unidades" v-model="quantity")
                              th 
                                .input-group
                                  input.form-control(type="number" step="0.0001" placeholder="Precio" v-model="price")
                              th 
                                .input-group
                                  input.form-control(type="number" step="0.01" min="0" max="100" placeholder="Dte.%" v-model="discount")
                              th 
                                v-select(:options="taxes" :reduce="label => label.id"  label="label" placeholder="Impuestos" multiple="multiple" v-model="taxes_id")
                              th
                                .input-group
                                  input.form-control(type="number" step="0.0001" placeholder="Total" v-model="total" v-if="totalTaxes <= 1")
                                  button.btn.btn-primary.btn-sm(@click="addLine()")
                                    font-awesome-icon.me-1(icon="plus-circle")
                                    span(v-if="totalTaxes > 1") Añadir
                  .row.mt-4
                    .col-12
                      .border.p-2
                        h5.card-title Observaciones
                        template(v-if="!editComments")
                          p {{comments}}
                          .btn-group
                            button.btn.btn-primary(@click="setEditComments(true)")
                              font-awesome-icon.me-2(icon="edit")
                              span Editar
                        template(v-else)
                          .input-group.mb-2
                            textarea.form-control(rows="5" placeholder="Observaciones" v-model="comments")
                          .btn-group
                            button.btn.btn-primary(@click="saveComments()")
                              font-awesome-icon.me-2(icon="save")
                              span Guardar
                            button.btn.btn-secondary(@click="setEditComments(false)")
                              font-awesome-icon.me-2(icon="ban")
                              span Cancelar
                  .row.mt-4
                    .col-4
                      .border.p-2
                        h5.card-title Información de pagos
                        table.table.table-border(v-if="false")
                          tbody
                            tr
                              td Método
                              td.text-end Trasnferencia
                            tr
                              td 21/04/2021
                              td.text-end 25.25{{legalDocument.money.symbol}}
                            tr
                              td 21/05/2021
                              td.text-end 25.25{{legalDocument.money.symbol}}
                    .col-4
                      .border.p-2
                        h5.card-title Impuestos
                        table.table.table-border
                          tbody
                            tr(v-for="tax in legalDocument.taxes")
                              td {{tax.name}}
                              td.text-end {{tax.pivot.total | numFormat('1,000.00')}} {{legalDocument.money.symbol}}
                    .col-4
                      .border.p-2
                        h5.card-title Resumen
                        table.table.table-border
                          tbody
                            tr
                              td Base
                              td.text-end {{legalDocument.price_without_tax | numFormat('1,000.00')}} {{legalDocument.money.symbol}}
                            tr
                              td Impuestos
                              td.text-end {{legalDocument.price_with_tax - legalDocument.price_without_tax | numFormat('1,000.00')}} {{legalDocument.money.symbol}}
                            tr
                              td 
                                strong Total
                              td.text-end
                                strong {{legalDocument.price_with_tax | numFormat('1,000.00')}} {{legalDocument.money.symbol}}
</template>

<script>
  import GenerateInvoice from '../../components/legal_documents/GenerateInvoice.vue'
  import legalDocumentStatusLog from '../../components/legal_documents/legalDocumentStatusLog.vue'
  import legalDocumentRelationed from '../../components/legal_documents/legalDocumentRelationed.vue'
  export default {
    name: 'InDeliveryNoteShow',
    components: {
      GenerateInvoice,
      legalDocumentStatusLog,
      legalDocumentRelationed
    },
    beforeMount() {
      this.id = this.$route.params.id
      this.moduleUrl = 'in_delivery_notes'
      this.$store.dispatch("getModuleByUrl", {moduleUrl: this.moduleUrl})
        .then((res) => {
          this.currentModule = res
        })
      this.$store.dispatch("getRegister", {moduleUrl: this.moduleUrl, id: this.id})
        .then((res) => {
          this.legalDocument = res
          if(this.legalDocument.description) {
            this.comments = this.legalDocument.description.description
          }
        })
      this.$store.dispatch("getRegistersList", {moduleUrl: 'taxes', columns: 'id,name'})
        .then((res) => {
          this.taxes = res
        })
    },
    data () {
      return {
        id: 0,
        moduleUrl: '',
        config: [],
        currentModule: {},
        legalDocumentLineId: 0,
        legalDocument: {},
        editComments: false,
        taxes: [],
        comments: '',
        productOptions: [],
        product_id: null,
        description: '',
        lot: '',
        expiration_date: null,
        quantity: 1,
        price: null,
        total: null,
        discount: 0,
        taxes_id: []
      }
    },
    computed: {
      backendUrl() {
        return this.$store.state.authModule.backendUrl
      },
      totalTaxes() {
        return this.taxes_id.length
      }
    },
    watch: {
      product_id(newVal) {
        if(this.legalDocumentLineId == 0 && newVal) {
          this.$store.dispatch("getProductAccount", {productId: newVal, accountId: this.legalDocument.account_id})
            .then((res) => {
              this.taxes_id = []
              res.taxes_account.forEach((tax) => {
                this.taxes_id.push(tax.id);
              })
              
              this.price = res.price
            })
        }
      }
    },
    methods: {
      productsAjaxSearch(search, loading) {
        let filter = {
              filter: search,
              moduleUrl: 'products',
              columns: 'id,name,code,ean',
          }
          loading(true)
          this.$store.dispatch('getRegistersListAjax', filter).then((res) => {
              this.productOptions = res
              loading(false)
          })
      },
      saveComments() {
        let data = {
          legal_document_id: this.legalDocument.id,
          description: this.comments
        }

        if(this.legalDocument.description) {
          this.$store.dispatch('updateRegister', {
            moduleUrl: 'in_delivery_note_description',
            id: this.legalDocument.description.id,
            data: data
          }).then((res) => {
            if(!res.errors) {
              this.legalDocument.description = res
              this.comments = res.description
              this.setEditComments(false)
            }    
          })
        } else {
          this.$store.dispatch('createRegister', {
            moduleUrl: 'in_delivery_note_description',
            data: data
          }).then((res) => {
            if(!res.errors) {
              this.legalDocument.description = res
              this.comments = res.description
              this.setEditComments(false)
            }    
          })  
        }
      },
      setEditComments(val) {
        this.editComments = val
      },
      addLine() {
        let data = {
          legal_document_id: this.id,
          product_id: this.product_id,
          description: this.description,
          quantity: this.quantity,
          lot: this.lot,
          expiration_date: this.expiration_date,
          price: this.price,
          total: this.total,
          discount: this.discount,
          taxes_id: this.taxes_id
        }

        this.$store.dispatch('createRegister', {
          moduleUrl: 'in_delivery_note_lines',
          data: data
        }).then((res) => {
          if(!res.errors) {
            this.legalDocument = res
            this.resetForm()
          }    
        })
      },
      editLegalDocumentLine(legalDocumentLine) {
        this.product_id = legalDocumentLine.product_id

        if(this.product_id) {
          let option = {
            id: legalDocumentLine.product_id,
            ean: legalDocumentLine.legal_document_line_product.ean,
            code: legalDocumentLine.legal_document_line_product.code,
            name: legalDocumentLine.legal_document_line_product.name
          }

          this.productOptions = [],
          this.productOptions.push(option)
        }
        
        if(legalDocumentLine.description) {
          this.description = legalDocumentLine.description.description
        } else {
          this.description = ''
        }
        this.quantity = legalDocumentLine.quantity
        this.price = legalDocumentLine.price
        this.discount = legalDocumentLine.discount
        this.taxes_id = []
        if(legalDocumentLine.taxes) {
          legalDocumentLine.taxes.forEach((tax) => {
            this.taxes_id.push(tax.id)
          })
        }

        this.legalDocumentLineId = legalDocumentLine.id
      },
      updateLine() {
        let data = {
          legal_document_id: this.id,
          product_id: this.product_id,
          description: this.description,
          lot: this.lot,
          expiration_date: this.expiration_date,
          quantity: this.quantity,
          price: this.price,
          discount: this.discount,
          taxes_id: this.taxes_id
        }

        this.$store.dispatch('updateRegister', {
          moduleUrl: 'in_delivery_note_lines',
          id: this.legalDocumentLineId,
          data: data
        }).then((res) => {
          if(!res.errors) {
            this.legalDocument = res
            this.resetEditLine()
          }    
        })
      },
      resetEditLine() {
        this.legalDocumentLineId = 0
        this.resetForm()
      },
      resetForm() {
        this.product_id = null
        this.description = ''
        this.quantity = 1
        this.price = null
        this.discount = 0
        this.taxes_id = []
      },
      deleteLegalDocumentLine(legalDocumentLineId) {
        this.$swal.fire({
          title: 'Está seguro que desea eliminar este elemento?',
          icon: 'warning',
          showCancelButton: true
        }).then((result) => {
          if(result) {
            this.$store.dispatch("deleteRegister", {
              moduleUrl: 'in_delivery_note_lines',
              id: legalDocumentLineId
            }).then((res) => {
              this.legalDocument = res
            })
          }
        });
      },
      deleteLegalDocument() {
        this.$swal.fire({
          title: 'Está seguro que desea eliminar este elemento?',
          icon: 'warning',
          showCancelButton: true
        }).then((result) => {
          if(result.isConfirmed) {
            this.$store.dispatch("deleteRegister", {
              moduleUrl: 'in_delivery_notes',
              id: this.id
            }).then(() => {
              this.$router.push('/in_delivery_notes')
            })
          }
        });
      }
    }
  }
</script>
<style scooped lang="scss">
  .vs__dropdown-menu {
    min-width: 300px !important;
  }
</style>