<template lang="pug">
.report-bs-kpi
  .row
    div(:class="expanded ? 'col-10' : 'col-6'")
      .card.mb-3
        .card-body
          .d-flex.justify-content-between
            h5.card-title Información general de llamadas
            .form-check.form-switch
              input.form-check-input(type="checkbox" role="switch" id="flexSwitchCheckDefault" v-model="expanded")
              label.form-check-label(for="flexSwitchCheckDefault") Ampliar información
          table.table
            thead
              tr
                th
                th.text-center 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Total of calls received in each 900/800 free numbe") #Total Incoming calls
                th.text-center.bg-secondary-opacity-25(v-if="!expanded") 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls that don’t ring in any extension because the customer has hung during the voiceover, or there wasn’t any extension available, etc.") #Total Non-offered calls
                th.text-center.bg-secondary-opacity-25(v-if="expanded") 
                  span #T Opción Cliente
                th.text-center.bg-secondary-opacity-25(v-if="expanded") 
                  span #T Fallidas
                th.text-center.bg-secondary-opacity-25(v-if="expanded") 
                  span #T Call me later
                th.text-center.bg-secondary-opacity-25(v-if="expanded") 
                  span #T Buzón
                th.text-center 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls that ring in one extension at least") #Total offered calls
                th.text-center.bg-secondary-opacity-25(v-if="!expanded") 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Offered calls that have rung on an extension but haven’t been picked") #Total not answered calls
                th.text-center.bg-secondary-opacity-25(v-if="expanded") 
                  span #T No atendidas
                th.text-center.bg-secondary-opacity-25(v-if="expanded") 
                  span #T Call me later
                th.text-center.bg-secondary-opacity-25(v-if="expanded") 
                  span #T Buzón
                th.text-center 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls offered and attended by an agent") #Total answered calls
                th.text-center 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls answered in relation to total incoming calls") %T answered calls/incoming calls
                th.text-center 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Answered calls in relation to offered calls (calls that have rung on an extension)") T Pick Up rate
                th
            tbody
              tr
                td Spain
                td.text-center 1000
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 50
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 30
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 4
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 10
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 6
                td.text-center 950
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 25
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 10
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 8
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 7
                td.text-center 925
                td.text-center 93%
                td.text-center 97%
                td
                  a.text-info(@click="setShowDeep(1)" href="#!")
                    font-awesome-icon(v-if="showDeep == 0" icon="angle-down")
                    font-awesome-icon(v-else icon="angle-up")
              tr
                td France
                td.text-center 300
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 20
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 10
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 2
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 5
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 3
                td.text-center 280
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 5
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 3
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 1
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 1
                td.text-center 275
                td.text-center 92%
                td.text-center 98%
                td
                  a.text-muted(href="#!")
                    font-awesome-icon(icon="angle-down")
              tr
                td Portugal
                td.text-center 250
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 20
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 9
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 3
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 4
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 4
                td.text-center 230
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 10
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 5
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 2
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 3
                td.text-center 220
                td.text-center 88%
                td.text-center 96%
                td
                  a.text-muted(href="#!")
                    font-awesome-icon(icon="angle-down")
            tfoot  
              tr
                td Total
                td.text-center 1550
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 90
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 49
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 9
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 19
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 13
                td.text-center 230
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 40
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 18
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 11
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 11
                td.text-center 1420
                td.text-center 91%
                td.text-center 97%
                td
  .row
    .col
      .card.mb-3(v-if="showDeep >= 1")
        .card-body
          .d-flex.justify-content-between
            h5.card-title Información general de llamadas por departamento
          table.table.mb-3
            thead
              tr
                th
                th.text-center 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Total of calls received in each 900/800 free number") #Total Incoming calls
                th.text-center.bg-secondary-opacity-25(v-if="!expanded") 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls that don’t ring in any extension because the customer has hung during the voiceover, or there wasn’t any extension available, etc.") #Total Non-offered calls
                th.text-center.bg-secondary-opacity-25(v-if="expanded") 
                  span #T Fallidas
                th.text-center.bg-secondary-opacity-25(v-if="expanded") 
                  span #T Call me later
                th.text-center.bg-secondary-opacity-25(v-if="expanded") 
                  span #T Buzón
                th.text-center 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls that ring in one extension at least") #Total offered calls
                th.text-center.bg-secondary-opacity-25(v-if="!expanded") 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Offered calls that have rung on an extension but haven’t been picked") #Total not answered calls
                th.text-center.bg-secondary-opacity-25(v-if="expanded") 
                  span #T No atendidas
                th.text-center.bg-secondary-opacity-25(v-if="expanded") 
                  span #T Call me later
                th.text-center.bg-secondary-opacity-25(v-if="expanded") 
                  span #T Buzón
                th.text-center 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls offered and attended by an agent") #Total answered calls
                th.text-center 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls answered in relation to total incoming calls") %T answered calls/incoming calls
                th.text-center 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Answered calls in relation to offered calls (calls that have rung on an extension)") % T Pick Up rate
                th.text-center 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Percentage of calls offered in this city, area or centre, with respect to the total of the country") % offered calls/total offered calls
                th.text-center 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Percentage of calls picked in this city, area or centre, with respect to the total of offered calls in the country") % answered calls/total offered calls
                th.text-center 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="%Service level") Calls answered in less than 20 seconds in relation to the total offered  calls in each area
                th
            tbody
              tr
                td BCN
                td.text-center 290
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 5
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 1
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 2
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 2
                td.text-center 285
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 5
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 3
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 1
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 1
                td.text-center 280
                td.text-center 97%
                td.text-center 98%
                td.text-center 30%
                td.text-center 29%
                td.text-center 92%
                td
                  a.text-info(@click="setShowDeep(2)" href="#!")
                    font-awesome-icon(v-if="showDeep == 1" icon="angle-down")
                    font-awesome-icon(v-else icon="angle-up")
              tr.bg-primary-opacity-25(v-if="showDeep >= 2")
                td.ps-3 - BCN CENTRO
                td.text-center 85
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center 85
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 1
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 1
                td.text-center 84
                td.text-center 99%
                td.text-center 99%
                td.text-center 9%
                td.text-center 9%
                td.text-center 92%
                td
                  a.text-info(@click="setShowDeep(3)" href="#!")
                    font-awesome-icon(v-if="showDeep == 2" icon="angle-down")
                    font-awesome-icon(v-else icon="angle-up")
              tr.bg-info-opacity-25(v-if="showDeep >= 3")
                td.ps-3 
                  span.ps-3 - Bon Pastor
                td.text-center 30
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center 30
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 1
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 1
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center 29
                td.text-center 97%
                td.text-center 97%
                td.text-center 3%
                td.text-center 3%
                td.text-center 92%
                td
                  a.text-info(@click="setShowDeep(4)" href="#!")
                    font-awesome-icon(v-if="showDeep == 3" icon="angle-down")
                    font-awesome-icon(v-else icon="angle-up")
              tr.bg-info-opacity-25(v-if="showDeep == 3")
                td.ps-3 
                  span.ps-3 - Sant Gervasi
                td.text-center 20
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center 20
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center 20
                td.text-center 100%
                td.text-center 100%
                td.text-center 1%
                td.text-center 1%
                td.text-center 90%
                td
                  a.text-muted(href="#!")
                    font-awesome-icon(icon="angle-down")
              tr.bg-info-opacity-25(v-if="showDeep == 3")
                td.ps-3 
                  span.ps-3 - La Maquinista
                td.text-center 5
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center 5
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center 5
                td.text-center 100%
                td.text-center 100%
                td.text-center 1%
                td.text-center 1%
                td.text-center 90%
                td
                  a.text-muted(href="#!")
                    font-awesome-icon(icon="angle-down")
              tr.bg-info-opacity-25(v-if="showDeep == 3")
                td.ps-3 
                  span.ps-3 - Meridianda
                td.text-center 10
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center 10
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center 10
                td.text-center 100%
                td.text-center 100%
                td.text-center 1%
                td.text-center 1%
                td.text-center 96%
                td
                  a.text-muted(href="#!")
                    font-awesome-icon(icon="angle-down")
              tr.bg-info-opacity-25(v-if="showDeep == 3")
                td.ps-3 
                  span.ps-3 - Ganduxer
                td.text-center 13
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center 13
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center 13
                td.text-center 100%
                td.text-center 100%
                td.text-center 1%
                td.text-center 1%
                td.text-center 93%
                td
                  a.text-muted(href="#!")
                    font-awesome-icon(icon="angle-down")
              tr.bg-info-opacity-25(v-if="showDeep == 3")
                td.ps-3 
                  span.ps-3 - Les Corts
                td.text-center 7
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center 7
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center 7
                td.text-center 100%
                td.text-center 100%
                td.text-center 1%
                td.text-center 1%
                td.text-center 90%
                td
                  a.text-muted(href="#!")
                    font-awesome-icon(icon="angle-down")
              tr.bg-primary-opacity-25(v-if="showDeep >= 2 && showDeep != 4")
                td.ps-3 - BCN NORTE
                td.text-center 74
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center 74
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center 74
                td.text-center 100%
                td.text-center 100%
                td.text-center 8%
                td.text-center 8%
                td.text-center 96%
                td
                  a.text-muted(href="#!")
                    font-awesome-icon(icon="angle-down")
              tr.bg-primary-opacity-25(v-if="showDeep >= 2 && showDeep != 4")
                td.ps-3 - BCN PERIFERIA
                td.text-center 58
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center 58
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 1
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 1
                td.text-center 57
                td.text-center 98%
                td.text-center 98%
                td.text-center 6%
                td.text-center 6%
                td.text-center 93%
                td
                  a.text-muted(href="#!")
                    font-awesome-icon(icon="angle-down")
              tr.bg-primary-opacity-25(v-if="showDeep >= 2 && showDeep != 4")
                td.ps-3 - BCN SUR
                td.text-center 66
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center 66
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 1
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 1
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center 65
                td.text-center 98%
                td.text-center 98%
                td.text-center 7%
                td.text-center 7%
                td.text-center 90%
                td
                  a.text-muted(href="#!")
                    font-awesome-icon(icon="angle-down")
              tr(v-if="showDeep != 4")
                td BIL
                td.text-center 95
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center 95
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 2
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 1
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 1
                td.text-center 93
                td.text-center 95%
                td.text-center 98%
                td.text-center 10%
                td.text-center 10%
                td.text-center 96%
                td
                  a.text-muted(href="#!")
                    font-awesome-icon(icon="angle-down")
              tr(v-if="showDeep != 4")
                td MAD
                td.text-center 381
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 3
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 1
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 2
                td.text-center 378
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 8
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 1
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 3
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 4
                td.text-center 370
                td.text-center 97%
                td.text-center 98%
                td.text-center 40%
                td.text-center 39%
                td.text-center 93%
                td
                  a.text-muted(href="#!")
                    font-awesome-icon(icon="angle-down")
              tr(v-if="showDeep != 4")
                td SALES CENTER
                td.text-center 102
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 5
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 1
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 2
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 2
                td.text-center 97
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 7
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 2
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 4
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 1
                td.text-center 90
                td.text-center 88%
                td.text-center 93%
                td.text-center 10%
                td.text-center 9%
                td.text-center 90%
                td
                  a.text-muted(href="#!")
                    font-awesome-icon(icon="angle-down")
              tr(v-if="showDeep != 4")
                td VAL
                td.text-center 98
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 3
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 1
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 2
                td.text-center 95
                td.text-center.bg-secondary-opacity-25(v-if="!expanded") 3
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 3
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center.bg-secondary-opacity-25(v-if="expanded") 0
                td.text-center 92
                td.text-center 94%
                td.text-center 97%
                td.text-center 10%
                td.text-center 10%
                td.text-center 93%
                td
                  a.text-muted(href="#!")
                    font-awesome-icon(icon="angle-down")
          .d-flex.flex-row-reverse(v-if="showDeep == 4")
            .btn-group
              button.btn(:class="showExtensions ? 'btn-primary' : 'btn-outline-primary'" @click="showExtensions = true") Extensiones
              button.btn(:class="!showExtensions ? 'btn-primary' : 'btn-outline-primary'" @click="showExtensions = false") Usuarios
          table.table(v-if="showDeep == 4")
            thead
              tr
                th
                th.text-center 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Total of calls received in each 900/800 free number") Total Incoming calls
                th.text-center 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls that ring in one extension at least") #Total offered calls
                th.text-center 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls offered and attended by an agent") #Total answered calls
                th.text-center 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls answered in relation to total incoming calls") %T answered calls/incoming calls
                th.text-center 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Answered calls in relation to offered calls (calls that have rung on an extension)") %T Pick Up rate
                th.text-center 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Percentage of calls offered in this city, area or centre, with respect to the total of the country") % offered calls/total offered calls
                th.text-center 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Percentage of calls picked in this city, area or centre, with respect to the total of offered calls in the country") % answered calls/total offered calls
                th.text-center 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls answered in less than 20 seconds in relation to the total offered calls in each area") %Service level
                th.text-center 
                  span Total time on the phone
                th.text-center 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Average on-call time for this agent") Average handle time
                th.text-center 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Average response time of this agent") Average answer time
                th.text-center 
                  span Hours logged in the system
                th.text-center(v-if="!showExtensions") 
                  span Hours paused in the system
                th.text-center 
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Percentage of time on the phone compared to time logged on the system") Total time on phone / hours logged
            tbody
              tr(v-if="showExtensions")
                td EXT Bon Pastor
                td.text-center 30
                td.text-center 30
                td.text-center 29
                td.text-center 97%
                td.text-center 97%
                td.text-center 3%
                td.text-center 3%
                td.text-center 92%
                td.text-center 25h 32m
                td.text-center 52m
                td.text-center 32s
                td.text-center 83h 12m
                td.text-center 30%
              tr(v-if="showExtensions")
                td EXT Bon Pastor BRIA
                td.text-center 0
                td.text-center 0
                td.text-center 0
                td.text-center -
                td.text-center -
                td.text-center -
                td.text-center -
                td.text-center -
                td.text-center 0m
                td.text-center -
                td.text-center -
                td.text-center 0m
                td.text-center -
              tr(v-if="!showExtensions")
                td Sin tipificar
                td.text-center 5
                td.text-center 5
                td.text-center 5
                td.text-center 100%
                td.text-center 100%
                td.text-center 0.5%
                td.text-center 0.5%
                td.text-center 93%
                td.text-center 2h 32m
                td.text-center 30m
                td.text-center 36s
                td.text-center -
                td.text-center -
                td.text-center -
              tr(v-if="!showExtensions")
                td Usuario 1
                td.text-center 15
                td.text-center 15
                td.text-center 14
                td.text-center 93%
                td.text-center 93%
                td.text-center 1.5%
                td.text-center 1.4%
                td.text-center 92%
                td.text-center 16h
                td.text-center 59m
                td.text-center 32s
                td.text-center 43h 12m
                td.text-center 10h 9m
                td.text-center 43%
              tr(v-if="!showExtensions")
                td Usuario 2
                td.text-center 10
                td.text-center 10
                td.text-center 10
                td.text-center 100%
                td.text-center 100%
                td.text-center 1%
                td.text-center 1%
                td.text-center 94%
                td.text-center 7h
                td.text-center 42m
                td.text-center 30s
                td.text-center 40h
                td.text-center 8h 9m
                td.text-center 17%
</template>
<script>
  import ChartColumns from '../chart/ChartColumns.vue'
  import {Tooltip} from 'bootstrap'
  export default {
    name: 'ReportBSKpi',
    components: {
      ChartColumns
    },
    props: {
      reportReportItem: {type: Object, default: function() {return {} }},
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        data: {},
        showTab: 'in',
        colors: ["#09ad95", "#f7b731", "#f82649", "#6259ca", "#45aaf2"],
        lastFilter: 0,
        months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        showExtensions: true,
        expanded: false,
        showDeep: 0

      }
    },
    computed: {
      series() {
        if(this.data.dataChart && Object.values(this.data.dataChart).length > 0) {
          let series = []  
          series.push({
            name: "SLA",
            data: []
          })

          series.push({
            name: "Lost",
            data: []
          })
          
          Object.values(this.data.dataChart).forEach((row) => {
            series[0].data.push(row.sla)
            series[1].data.push(row.lost)
          })

          return series
        } else {
          return []
        }
      },
      labels() {
        return ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
      }
    },
    watch: {
      filters: {
        deep: true,
        handler(newVal) {
          if(newVal.counter != this.lastFilter) {
            this.getData()
            this.lastFilter = newVal.counter
          }
        }
      },
      expanded() {
        setTimeout(() => {
          let tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
          let tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
          console.log(tooltipList)
        }, 1000)
      },
      showDeep() {
        setTimeout(() => {
          let tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
          let tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
          console.log(tooltipList)
        }, 1000)
      },
      showExtensions() {
        setTimeout(() => {
          let tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
          let tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
          console.log(tooltipList)
        }, 1000)
      }
    },
    mounted() {
      let tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
      let tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
      console.log(tooltipList)
    },
    methods: {
      setShowDeep(deep) {
        if(this.showDeep >= deep) {
          this.showDeep = deep -1
        } else {
          this.showDeep = deep
        }
      },
      getData() {
        this.$store.dispatch("getReportReportItemData", {
          reportReportItemId: this.reportReportItem.id,
          data: {
            filters: this.filters
          }
        }).then((res) => {
          this.data = res
        })
      }
    }
  }
</script>
<style lang="scss">
</style>