<template lang="pug">
  .filter-department
    .input-group
      template(v-if="showModal")
        button.btn.btn-primary(type="button" data-bs-toggle="modal" :data-bs-target="'#filterDepartmentModal' + defaultKey")
          font-awesome-icon(icon="filter")
      template(v-else)
        button.btn.btn-secondary(type="button")
          font-awesome-icon(icon="filter")
      v-select(:options="departments" :reduce="label => label.id"  label="label" v-model="selectedDeparmentsId" multiple="multiple" :placeholder="'Filtrar departamento'" style="min-width:180px")

    .modal.fade(:id="'filterDepartmentModal' + defaultKey" :aria-labelledby="'filterDepartmentModalLabel' + defaultKey")
      .modal-dialog
        .modal-content
          .modal-header
            h5.modal-title(:id="'filterDepartmentModalLabel' + defaultKey") Seleccionar departamento
            button.btn-close(type="button" data-bs-dismiss="modal" aria-label="Close")
          .modal-body
            ul.no-style
              filter-department-node(
                v-for="department in departmentsStructure" 
                :department="department" 
                :selectedDeparmentsId="selectedDeparmentsId"
                :key="defaultKey + 'filterDeparment' + department.id"
                @updateCheckbox="updateCheckbox"
              )

</template>

<script>
  export default {
    name: 'FilterDepartment',
    props: { 
      defaultKey: String,
      showModal: {type: Boolean, default: true },
    },
    data () {
      return {
        showChildres: false,
        selectedDeparmentsId: [],
        allDeparmentsId: []
      }
    },
    computed: {
      departmentsStructure() {
        return this.$store.state.departmentModule.departmentsStructure
      },
      departments() {
        return this.$store.state.departmentModule.departments
      },
      currentUser() {
        return this.$store.state.currentUser
      }
    },
    watch: {
      selectedDeparmentsId(newVal) {
        this.getDepartmentsStructureId(newVal)
      },
      currentUser(newVal) {
        if(newVal[this.defaultKey + '_departments_id']) {
          this.selectedDeparmentsId = newVal[this.defaultKey + '_departments_id']
        } else if(newVal.security_departments) {
          let departmentsId = []
          newVal.security_departments.forEach((d) => {
            departmentsId.push(d.id)
          })

          this.selectedDeparmentsId = departmentsId
        }
      }
    },
    mounted() {
      this.$store.dispatch("getDepartmentsStructure")
      this.$store.dispatch("getDepartmentsList")
      
      if(this.currentUser[this.defaultKey + '_departments_id']) {
        this.selectedDeparmentsId = this.currentUser[this.defaultKey + '_departments_id']
      } else if(this.currentUser.security_departments) {
        let departmentsId = []
        this.currentUser.security_departments.forEach((d) => {
          departmentsId.push(d.id)
        })

        this.selectedDeparmentsId = departmentsId
      }
    },
    methods: {
      getDepartmentsStructureId(departmentsId) {
        this.$store.dispatch("getDepartmentsStructureId", {
          departments_id: departmentsId
        }).then((res) => {
          this.$emit("updateDepartmentIds", res)
        })
      },
      updateCheckbox(event) {
        let index = this.selectedDeparmentsId.findIndex((d) => d == event.department_id)

        if(event.checked) {
          if(index == -1) {
            this.selectedDeparmentsId.push(event.department_id)
          }
        } else {
          if(index > -1) {
            this.selectedDeparmentsId.splice(index, 1)
          }
        }
      }
    }
  
  }
</script>
<style lang="scss">
  .filter-department .vs--searchable .vs__dropdown-toggle {
    padding-top: 5px;
    padding-bottom: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  ul.no-style {
    list-style: none;
  }

</style>