import callCommentsService from '../services/api/call_comments'

const callCommentModule = {
    state: {
    },
    mutations: {
    },
    actions: {
        assignCommentToCall(context, data) {
            return new Promise((resolve, reject) => {
                callCommentsService.assignCommentToCall(data)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        getCallComments(context, data) {
            return new Promise((resolve, reject) => {
                callCommentsService.getCallComments(data.linkedid)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
    }
}

export default callCommentModule