<template lang="pug">
  .time-group-option
    .card.mb-2
      .card-body
        .d-flex.justify-content-between
          .ps-3
            template(v-if="create")
              h5.card-title.mb-0 Añadir una nueva opción
            template(v-else)
              h5.card-title.mb-1
                font-awesome-icon.me-2(icon="calendar")
                span {{timeGroupOption.date | formatDate}}
              p
              strong(v-if="timeGroupOption.every_year") Repetir cada año
              strong(v-else) Solo para este año
          div(v-if="!edit")
            button.btn.btn-primary.me-2(@click="setEdit(true)")
              font-awesome-icon.me-2(icon="edit")
              span Editar
            button.btn.btn-danger(@click="remove")
              font-awesome-icon(icon="trash")
              span Eliminar
        .p-3(v-if="edit")
          .btn-group.mb-2(v-if="false")
            button.btn(:class="tab == 'date' ? 'btn-primary' : 'btn-outline-primary'" @click="setTab('date')") Festivo
            button.btn(:class="tab == 'advanced' ? 'btn-primary' : 'btn-outline-primary'" @click="setTab('advanced')") Avanzado

          .form-input.mb-3
            label.label Indicar la fecha
            input.form-control(type="date" v-model="date")
          .form-check
            input#everyYear.form-check-input(type="checkbox" v-model="every_year")
            label.form-check-label(for="everyYear") Repetir cada año
          .mt-3
          button.btn.btn-primary.me-3(@click="save()") 
            font-awesome-icon.me-2(icon="save")
            span Guardar
          button.btn.btn-secondary(@click="setEdit(false)") 
            font-awesome-icon.me-2(icon="times")
            span Cancelar
        
            
</template>

<script>
  export default {
    name: 'TimeGroupShow',
    beforeMount() {

    },
    props: {
      timeGroupId: {type: Number},
      timeGroupOption: {type: Object,default: () => ({})},
      create: {type: Boolean,default: false},
    },
    data () {
      return {
        edit: false,
        date: null,
        every_year: true,
        tab: 'date'
      }
    },
    computed: {
      
    },
    watch: {
      create(newVal) {
        if(newVal) {
          this.setEdit(true)
        }
      },
      timeGroupOption(newVal) {
        if(newVal.id) {
          this.date = newVal.date
          this.every_year = newVal.every_year
        } else {
          this.date = null
          this.every_year = true
        }
      }
    },
    mounted() {
      if(this.create) {
        this.setEdit(true)
      } else {
        this.date = this.timeGroupOption.date
          this.every_year = this.timeGroupOption.every_year
      }
    },
    methods: {
      setEdit(edit) {
        this.edit = edit
      },
      setTab(tab) {
        this.tab = tab
      },
      save() {
        if(this.timeGroupOption.id) {
          if(this.date) {
            let payload = {
              timeGroupId: this.timeGroupId,
              timeGroupOptionId: this.timeGroupOption.id,
              data: {
                date: this.date,
                every_year: this.every_year
              }
            }

            this.$store.dispatch("updateTimeGroupOption", payload)
              .then((res) => {
                this.setEdit(false)
                this.$emit("updateTimeGroupOption", res)
              })
          }
        } else {
          if(this.date) {
            let payload = {
              timeGroupId: this.timeGroupId,
              data: {
                date: this.date,
                every_year: this.every_year
              }
            }

            this.$store.dispatch("createTimeGroupOption", payload)
              .then((res) => {
                this.$emit("createTimeGroupOption", res)
              })
          }
        }
      },
      remove() {
        this.$swal.fire({
          title: '¿Está seguro que desea eliminar este elemento?',
          icon: 'warning',
          showCancelButton: true
        }).then((result) => {
          if(result.isConfirmed) {
            let payload = {
              timeGroupId: this.timeGroupId,
              timeGroupOptionId: this.timeGroupOption.id,
            }

            this.$store.dispatch("deleteTimeGroupOption", payload)
              .then((res) => {
                this.$emit("removeTimeGroupOption", res)
              })
          }
        });
      }
    }
  }
</script>