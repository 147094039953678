<template lang="pug">
  .sample-wall-1
    .header-actions
      .header-actions-content(style="z-index: 100")
        .d-flex.flex-row
          .p-2
            filter-department(@updateDepartmentIds="updateDepartmentIds")
          .p-2
            .input-group
              button.btn.btn-secondary
                font-awesome-icon(icon="filter")
              input.form-control(type="text" placeholder="Buscar cola" v-model="filter")
    .main-content
      .container-fluid
        .row
          .col-lg-6
            .card.mb-3
              .card-body
                .d-flex.justify-content-between
                  h5.card-title.mb-1 Mis colas
                  a.text-dark(v-if="showQueue" @click="showQueue = !showQueue" href="#!")
                    font-awesome-icon(icon="compress-alt")
                  a.text-dark(v-else  @click="showQueue = !showQueue"  href="#!")
                    font-awesome-icon(icon="expand-alt")

                table.table
                  thead
                    tr.text-center
                      th 
                      th(style="width:20%") En Cola
                      th(style="width:20%") Perdidas
                      th(style="width:20%") Totales
                      th(style="width:20%") Espera
                      th(style="width:20%") Disponibles

                  tbody
                    tr(v-for="i in 5")
                      td.text-end
                        h5
                          span.badge.bg-primary BCN
                      td.text-center.pb-0.position-relative
                        h5.text-info-opacity-20.position-absolute(style="top: 3px;left: 15px")
                          font-awesome-icon(icon="angle-double-right")
                        h4.mt-1.mb-2.text-info 2
                        .bg-info-opacity-25.text-end.pe-2.small.pt-1.pb-1(v-if="showQueue")
                          font-awesome-icon.me-1(icon="clock")
                          span 12s
                      td.text-center.pb-0.position-relative
                        h5.text-secondary-opacity-20.position-absolute(style="top: 3px;left: 15px")
                          font-awesome-icon(icon="exclamation-triangle")
                        h4.mt-1.mb-2.text-secondary 2
                        .bg-secondary-opacity-25.d-flex.justify-content-between.small.pt-1.pb-1(v-if="showQueue")
                          .ps-2
                            font-awesome-icon.me-1(icon="percent")
                            span 0.1
                          .pe-2
                            font-awesome-icon.me-1(icon="history")
                            span 0
                      td.text-center.pb-0.position-relative
                        h5.text-primary-opacity-20.position-absolute(style="top: 3px;left: 15px")
                          font-awesome-icon(icon="phone")
                        h4.mt-1.mb-2.text-primary 2
                        .bg-primary-opacity-25.text-end.pe-2.small.pt-1.pb-1(v-if="showQueue")
                          font-awesome-icon.me-1(icon="history")
                          span 5
                      td.text-center.pb-0.position-relative
                        h5.text-warning-opacity-20.position-absolute(style="top: 3px;left: 15px")
                          font-awesome-icon(icon="clock")
                        h4.mt-1.mb-2.text-warning 3.4s
                        .bg-warning-opacity-25.text-end.pe-2.small.pt-1.pb-1(v-if="showQueue")
                          font-awesome-icon.me-1(icon="calendar")
                          span 5.3s
                      td.text-center.pb-0.position-relative
                        h5.text-success-opacity-20.position-absolute(style="top: 3px;left: 15px")
                          font-awesome-icon(icon="check")
                        h4.mt-1.mb-2.text-danger(v-if="i == 2") 2
                        h4.mt-1.mb-2.text-danger.blink(v-else-if="i == 1") 2
                        h4.mt-1.mb-2.text-success(v-else) 2
                        .bg-success-opacity-25.text-end.pe-2.small.pt-1.pb-1(v-if="showQueue")
                          font-awesome-icon.me-1(icon="phone")
                          span 5

          .col-lg-6
            .card.mb-3
              .card-body
                .d-flex.justify-content-between
                  h5.card-title.mb-1 Estado colas
                #chart
                  apexchart(type="bar" height="350" :options="chartOptions" :series="series")
          
            .card.mb-3
              .card-body
                .d-flex.justify-content-between
                  h5.card-title.mb-1 Estado colas
                #chart2
                  apexchart(type="bar" height="350" :options="chartOptions2" :series="series2")
        
</template>

<script>
  export default {
    name: 'SampleWall1',
    data () {
      return {
        showQueue: true,
        filter: '',
        series: [{
            name: 'En cola',
            data: [3, 0, 2, 1, 5, 2]
          }, {
            name: 'En curso',
            data: [5, 2, 3, 0, 1, 4]
          }],
        chartOptions: {
          colors:['#45aaf2', '#09ad95'],
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: ['BCN', 'MAD', 'BIL', 'VAL', 'SO', 'C2C'],
          },
          fill: {
            opacity: 1
          },
        },
        series2: [{
            name: 'Atendidas',
            data: [0,0,0,0,0,0,0,0,2,3,8,5,2,6,1,8,6,9,8,9,2,0,0,0]
          }, {
            name: 'Fallidas',
            data: [0,0,0,0,0,0,0,0,0,1,2,1,1,1,2,1,2,3,1,1,0,0,0,0]
          }],
        chartOptions2: {
          colors:['#09ad95', "#f82649"],
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: ['0h', '1h', '2h', '3h', '4h', '5h', '6h', '7h', '9h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'],
          },
          fill: {
            opacity: 1
          },
        },
      }
    },
    methods: {
      
    }
  }
</script>
<style lang="scss" scoped>
  .card {
    min-width: 80px !important;
  }
</style>