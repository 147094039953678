import appService from "./appService";

const callCommentsService = {
    assignCommentToCall(data) {
        return appService.post('/call_comments/assign-to-call', data)
            .then((res) => res.data)
    },
    getCallComments(linkedid) {
        return appService.get('/calls/' + linkedid + '/call_comments')
            .then((res) => res.data)
    }
}

export default callCommentsService;