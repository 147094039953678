<template lang="pug">
  .report-total-call-by-call-status
    .card.mb-3
      .card-body
        .d-flex.justify-content-between
          h5.card-title {{reportReportItem.name}}
          .btn-group
            button.btn.btn-sm(@click="showTab = 'in'" :class="showTab == 'in' ? 'btn-primary' : 'btn-outline-primary'") In
            button.btn.btn-sm(@click="showTab = 'out'" :class="showTab == 'out' ? 'btn-primary' : 'btn-outline-primary'") Out
            button.btn.btn-sm(@click="showTab = 'internal'" :class="showTab == 'internal' ? 'btn-primary' : 'btn-outline-primary'") Internal
        template(v-if="showChart && series && series.length > 0 && labels && labels.length > 0")
          chart-donut(:series="series" :labels="labels" :colors="colors")
       
        table.table
          thead
            tr
              th Estado
              th(v-if="showTab == 'in'") In total
              th(v-if="showTab == 'in'") In duration
              th(v-if="showTab == 'in'") In media
              th(v-if="showTab == 'out'") Out total
              th(v-if="showTab == 'out'") Out duration
              th(v-if="showTab == 'out'") Out media
              th(v-if="showTab == 'internal'") Internal total
              th(v-if="showTab == 'internal'") Internal duration
              th(v-if="showTab == 'internal'") Internal media
          tbody
            tr(v-for="row in data.dataTable")
              td {{row.call_status}}
              td(v-if="showTab == 'in'") {{row.in_total}}
              td(v-if="showTab == 'in'") {{row.in_duration | timeInHoursMin  }}
              td(v-if="showTab == 'in'") {{row.in_media | timeInHoursMin}}
              td(v-if="showTab == 'out'") {{row.out_total}}
              td(v-if="showTab == 'out'") {{row.out_duration | timeInHoursMin}}
              td(v-if="showTab == 'out'") {{row.out_media | timeInHoursMin}}
              td(v-if="showTab == 'internal'") {{row.internal_total}}
              td(v-if="showTab == 'internal'") {{row.internal_duration | timeInHoursMin}}
              td(v-if="showTab == 'internal'") {{row.internal_media | timeInHoursMin}}
              
</template>
<script>
  import ChartDonut from '../chart/ChartDonut.vue'
  export default {
    name: 'ReportTotalCallByCallStatus',
    components: {
      ChartDonut
    },
    props: {
      reportReportItem: {type: Object, default: function() {return {} }},
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        data: {},
        showTab: 'in',
        colors: ["#09ad95", "#f7b731", "#f82649", "#6259ca", "#45aaf2"],
        lastFilter: 0,
        showChart: true
      }
    },
    computed: {
      series() {
        if(this.data.dataChart && this.data.dataChart[this.showTab].series) {
          let series = []
          this.data.dataChart[this.showTab].series.forEach((s) => {
            series.push(parseInt(s))
          })
          return series
        } else {
          return []
        }
      },
      labels() {
        if(this.data.dataChart && this.data.dataChart[this.showTab].labels) {
          return this.data.dataChart[this.showTab].labels
        } else {
          return []
        }
      }
    },
    watch: {
      filters: {
        deep: true,
        handler(newVal) {
          if(newVal.counter != this.lastFilter) {
            this.getData()
            this.lastFilter = newVal.counter
          }
        }
      }
    },
    mounted() {
    },
    methods: {
      getData() {
        this.showChart = false
        this.$store.dispatch("getReportReportItemData", {
          reportReportItemId: this.reportReportItem.id,
          data: {
            filters: this.filters
          }
        }).then((res) => {
          this.data = res
          this.showChart = true
        })
      }
    }
  }
</script>
<style lang="scss">
</style>