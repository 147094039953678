import appService from "./appService";

const timeGroupOptionsService = {
    store(timeGroupId, data) {
        return appService.post('/time_groups/' + timeGroupId +'/time_group_options', data)
            .then((res) => res.data)
    },
    update(timeGroupId, timeGroupOptionId, data) {
        return appService.put('/time_groups/' + timeGroupId +'/time_group_options/' + timeGroupOptionId, data)
            .then((res) => res.data)
    },
    delete(timeGroupId, timeGroupOptionId, data = {}) {
        return appService.delete('/time_groups/' + timeGroupId +'/time_group_options/' + timeGroupOptionId, data)
            .then((res) => res.data)
    },
}

export default timeGroupOptionsService;