<template lang="pug">
  .sample
    .container-fluid
      .row.mb-2
        .col-lg-12
          .card
            .card-body
              .row.row-cols-4.mb-2
                .col
                  .input-group
                    input.form-control(type="date" placeholder="Fecha de inicio" v-model="filter.start")
                .col
                  .input-group
                    input.form-control(type="date" placeholder="Fecha de inicio" v-model="filter.end")
                .col
                  v-select(:options="accountOptions" :reduce="label => label.id"  label="label" :filterable="false" @search="accountsAjaxSearch" placeholder="Cuenta" v-model="filter.account_id")
                .col
                  v-select(:options="productOptions" :reduce="prod => prod.id"  label="name" :filterable="false" @search="productsAjaxSearch" placeholder="Producto" v-model="filter.product_id")
                    template(slot="option" slot-scope="option")
                      span {{option.name}}
                      br
                      span.small {{'#' + option.code}} 
                      br 
                      span.small EAN: {{option.ean}}
              .row
                .col
                  button.btn.btn-primary(type='button' @click="getData()") Buscar
      .row.mb-2
        .col-lg-12
          .card(v-if="!loading")
            .card-body
              h5.card-title Facturación por día
              template(v-if="data['byDate']")
                chart-line(:data="data.byDate.chart", categoryFiled="date", valueFiled="value", title="Facturación diaria")
      .row.mb-2
        .col-lg-6
          .card(v-if="!loading")
            .card-body
              h5.card-title Facturación por cuenta
              template(v-if="data['byAccount']")
                chart-simple-column(:data="this.data['byAccount'].chart", categoryField="account", valueField="value", title="Facturación")
              .table-responsive
                table.table(v-if="data.byAccount && data.byAccount.table")
                  thead
                    tr
                      th Cuenta
                      th Total
                  tbody
                    tr(v-for="row in data.byAccount.table")
                      td {{row.account}}
                      td {{row.value | numFormat('1,000.00')}}
        .col-lg-6
          .card(v-if="!loading")
            .card-body
              h5.card-title Facturación por producto
              template(v-if="data['byProduct']")
                chart-simple-column(:data="this.data['byProduct'].chart", categoryField="product", valueField="value", title="Facturación")
              .table-responsive
                table.table(v-if="data.byProduct && data.byProduct.table")
                  thead
                    tr
                      th Producto
                      th Total
                  tbody
                    tr(v-for="row in data.byProduct.table")
                      td {{row.product}}
                      td {{row.value | numFormat('1,000.00')}}
      .row.mb-2
        .col-lg-12
          .card(v-if="!loading")
            .card-body
              h5.card-title Facturación por cuenta y producto
              template(v-if="data['byAccountProduct']")
                chart-stacked-column(:data="this.data['byAccountProduct'].chart", categoryField="account", :series="data.byAccountProduct.table[0].products" serieField="product")
              .table-responsive
                table.table(v-if="data.byAccountProduct && data.byAccountProduct.table")
                  thead
                    tr
                      th Cuenta
                      th(v-for="col in data.byAccountProduct.table[0].products") {{col.product}}
                  tbody
                    tr(v-for="row in data.byAccountProduct.table")
                      td {{row.account}}
                      td(v-for="rowProduct in row.products") {{rowProduct.value | numFormat('1,000.00')}}
</template>

<script>
  import ChartSimpleColumn from '../../components/chart/ChartSimpleColumn.vue'
  import ChartStackedColumn from '../../components/chart/ChartStackedColumn.vue'
  import ChartLine from '../../components/chart/ChartLine.vue'

  export default {
    name: 'ReportSample',
    components: {
      ChartSimpleColumn,
      ChartStackedColumn,
      ChartLine
    },
    data () {
      return {
        filter: {},
        data: {},
        accountOptions: [],
        productOptions: [],
        root: null,
        loading: false
      }
    },
    watch: {
      '$route': function (val) {
        this.$set(this.filter, 'legal_document_type_id', val.params.legal_document_type_id)
        this.data = {}
      }
    },
    mounted() {
      if(this.$route.params.legal_document_type_id) {
        this.$set(this.filter, 'legal_document_type_id', this.$route.params.legal_document_type_id)
      } else {
        this.$set(this.filter, 'legal_document_type_id', 4)
      }
    },
    methods: {
      getData() {
        this.loading = true
        this.$store.dispatch("getReport", {filter: this.filter})
          .then((data) => {
            this.data = data
            this.loading = false
          })
      },
      accountsAjaxSearch(search, loading) {
        let filter = {
              filter: search,
              moduleUrl: 'accounts',
              columns: 'id,name',
          }
          loading(true)
          this.$store.dispatch('getRegistersListAjax', filter).then((res) => {
              this.accountOptions = res
              loading(false)
          })
      },
      productsAjaxSearch(search, loading) {
        let filter = {
              filter: search,
              moduleUrl: 'products',
              columns: 'id,name,code,ean',
          }
          loading(true)
          this.$store.dispatch('getRegistersListAjax', filter).then((res) => {
              this.productOptions = res
              loading(false)
          })
      },
    }
  }
</script>
<style lang="scss">
</style>