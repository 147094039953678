import appService from "./appService";

const departmentsService = {
    getDepartmentsStructure() {
        return appService.get('/departments/structure')
            .then((res) => res.data)
    },
    getDepartmentsStructureId(data) {
        return appService.post('/departments/structure-ids', data)
            .then((res) => res.data)
    },
    getDepartmentsList() {
        return appService.get('/departments/list/id,name')
            .then((res) => res.data)
    },
}

export default departmentsService;