// import Vue from 'vue'
import timeGroupOptionsService from '../services/api/time_group_options'

const timeGroupOptionModule = {
    actions: {
        createTimeGroupOption (context, payload) {
            return new Promise((resolve, reject) => {
                timeGroupOptionsService.store(payload.timeGroupId, payload.data)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        updateTimeGroupOption (context, payload) {
            return new Promise((resolve, reject) => {
                timeGroupOptionsService.update(payload.timeGroupId, payload.timeGroupOptionId, payload.data)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        deleteTimeGroupOption (context, payload) {
            return new Promise((resolve, reject) => {
                timeGroupOptionsService.delete(payload.timeGroupId, payload.timeGroupOptionId, payload.data)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        
    }
}

export default timeGroupOptionModule