<template lang="pug">
  #header
    gc-call
    gc-extension
    gc-queue
    #companyModal.modal.fade(tabindex='-1' aria-labelledby='companyModalLabel' aria-hidden='true')
      .modal-dialog
        .modal-content
          .modal-header
            h5#companyModalLabel.modal-title Seleccione una empresa
            button.btn-close(type='button' data-bs-dismiss='modal' aria-label='Close')
          .modal-body
            v-select(:options="companies" :reduce="label => label.id"  label="label" v-model="newCompanyId")
          .modal-footer
            button.btn.btn-primary(type='button' @click="changeCompany()") Seleccionar

    #offcanvasMainMenu.offcanvas.offcanvas-start.main-menu(tabindex='-1' aria-labelledby='offcanvasMainMenuLabel')
      .offcanvas-header
        h5#offcanvasMainMenuLabel.offcanvas-title Men&uacute;
        button.btn.btn-link#offcanvasMainMenuClose(type='button' data-bs-dismiss='offcanvas' aria-label='Close')
          font-awesome-icon(icon='times')
      .offcanvas-body
        .text-center.cont-logo
          // img.rounded(src='http://flexandlux.es/wp-content/uploads/2020/09/FelxLux_negro-2.png')
        ul.list-unstyled.ps-0
          li.mb-1(v-for="mainMenuItem in mainMenu")
            button.btn.btn-toggle.align-items-center.rounded.collapsed(data-bs-toggle='collapse' :data-bs-target="'#item' + mainMenuItem.id + '-collapse'" aria-expanded='true')
              font-awesome-icon(:icon='mainMenuItem.icon')
              span.ps-2 {{ $t(mainMenuItem.name) }}
            .collapse(:id="'item' + mainMenuItem.id + '-collapse'")
              ul.btn-toggle-nav.list-unstyled.fw-normal.pb-1.small
                li(v-for="mainMenuSubitem in mainMenuItem.main_menu_items")
                  router-link(:to="mainMenuSubitem.url" @click.native="closeMainMenu()") {{ $t(mainMenuSubitem.name) }}
    nav.header-nav
      .header-nav-content
        .d-flex
          div
            .d-flex.flex-row.header-nav-menu
              .p-2.header-nav-menu-item
                a(data-bs-toggle='offcanvas' href='#offcanvasMainMenu' role='button' aria-controls='offcanvasMainMenu')
                  font-awesome-icon(icon='bars')
              .p-2.header-nav-menu-item
                a(v-if="user && user.company_id" href='#' data-bs-toggle="modal" data-bs-target="#companyModal")
                  strong {{user.company.name}}
          .flex-grow-1
          div
            .d-flex.flex-row-reverse.header-nav-menu-right
              .pt-2.me-2
                a(href='/logout')
                  font-awesome-icon(icon="sign-out-alt")
              .p-2.ms-2
                span {{user.name}}&nbsp;
                span.ms-2.badge(v-if="currentExtension" :class="getExtensionStatusColor(currentExtension)") {{currentExtension.number}}
              .p-2.ms-2.me-2
                a(href='#' @click="toogleTheme()")
                  font-awesome-icon(v-if="darkTheme" :icon="['fas', 'sun']")
                  font-awesome-icon(v-else="darkTheme" :icon="['fas', 'moon']")
              .p-2.ms-2.me-2(v-if="false")
                a(href='#')
                  font-awesome-icon(:icon="['fas', 'envelope']")
              .p-2.ms-2.me-2(v-if="companyConfigs && companyConfigs.module_accounts && companyConfigs.module_accounts == 1")
                gc-account
              .p-2.ms-2.me-2
                gc-user
              .p-2.ms-2.me-2(v-if="companyConfigs && companyConfigs.module_calls && companyConfigs.module_calls == 1")
                gc-phone
              .p-2.ms-2.me-2(v-if="companyConfigs && companyConfigs.module_messages && companyConfigs.module_messages == 1")
                gc-message
              .p-2.ms-2.me-2(v-if="companyConfigs && companyConfigs.module_tasks && companyConfigs.module_tasks == 1")
                task-header
              .p-2.ms-2.me-2
                gc-rest-time
              button.alert-notification.bg-warning.p-2.text-center(v-if="needApplyConfig && companyConfigs && companyConfigs.pbx_show_apply_config && companyConfigs.pbx_show_apply_config == 1" @click="pbxApplyConfig()")
                font-awesome-icon(icon="save")
                span.ms-2 Aplicar configuración
</template>
<script>
import TaskHeader from "./tasks/TaskHeader.vue"
import GcCall from "./calls/GcCall.vue"
import GcPhone from "./calls/GcPhone.vue"
import GcAccount from "./accounts/GcAccount.vue"
import GcUser from "./users/GcUser.vue"
import GcExtension from "./pbx/GcExtension.vue"
import GcRestTime from "./rest_times/GcRestTime.vue"
import GcQueue from "./queues/GcQueue.vue"
import GcMessage from "./messages/GcMessage.vue"

import jQuery from 'jquery'

export default {
  name: 'Header',
  components: {
    TaskHeader,
    GcCall,
    GcUser,
    GcAccount,
    GcRestTime,
    GcExtension,
    GcPhone,
    GcQueue,
    GcMessage
  },
  props: {
    darkTheme: {type: Boolean, default: false},
  },
  data() {
    return {
      mainMenu: [],
      user: {},
      newCompanyId: 0,
    }
  },
  computed: {
    needApplyConfig() {
      return this.$store.state.needApplyConfig
    },
    companyConfigs() {
      return this.$store.state.companyConfigs
    },
    companies() {
      let companies = [];
      if(this.user && this.user.companies) {
        this.user.companies.forEach((company) => {
          companies.push({id: company.id, label: company.name})
        })
      }

      return companies
    },
    extensions() {
      return this.$store.state.extensionModule.extensions
    },
    currentExtension() {
      let currentExtension = null
      
      if(this.user.extension_id) {
        let index = this.extensions.findIndex((ext) => ext.id == this.user.extension_id)

        if(index > -1) {
          currentExtension = this.extensions[index]
        }
      }

      return currentExtension
    }
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.user) {
      this.user = user.user
    }

    if(user && user.main_menu) {
      this.mainMenu = user.main_menu
    }
  },
  methods: {
    pbxApplyConfig() {
      this.$store.dispatch("pbxApplyConfig")
    },
    closeMainMenu() {
      jQuery('#offcanvasMainMenuClose').trigger('click')
    },
    toogleTheme() {
      this.$emit("toogleTheme", "toogleTheme")
    },
    changeCompany() {
      this.$store.dispatch("changeCompany", { companyId: this.newCompanyId})
        .then(() => {
          location.reload()
        })
    },
    getExtensionStatusColor(extension) {
        let color = 'bg-secondary'

        switch(extension.extension_status_id) {
          case 1:
              color = 'bg-secondary';
            break;
          case 2:
              color = 'bg-secondary';
            break;
          case 3:
              color = 'bg-success';
            break;
          case 4:
              color = 'bg-primary';
            break;
          case 5:
              color = 'bg-warning';
            break;
          case 6:
              color = 'bg-danger';
            break;
          case 7:
              color = 'bg-info blink';
            break;
          case 8:
              color = 'bg-primary blink';
            break;
          case 9:
              color = 'bg-warning';
            break;
          case 10:
              color = 'bg-warning';
            break;
          case 11:
              color = 'bg-dark';
            break;
          case 12:
              color = 'bg-dark';
            break;
        }

        return color
      },
  }
}
</script>
<style scoped>
</style>
