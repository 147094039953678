import appService from "./appService";

const chatRoomsService = {
    getUserChatRooms() {
        return appService.get('/chat_rooms/own')
            .then((res) => res.data)
    },
    getUserClosedChatRooms() {
        return appService.get('/chat_rooms/own/closed')
            .then((res) => res.data)
    },
    getChatRoomMessages(id, page) {
        return appService.get('/chat_rooms/' + id + '/messages/' + page)
            .then((res) => res.data)
    },
    chatRoomSendMessage(id, data) {
        return appService.post('/chat_rooms/' + id + '/send', data)
            .then((res) => res.data)
    },
    chatRoomSendTemplate(id, data) {
        return appService.post('/chat_rooms/' + id + '/send-template', data)
            .then((res) => res.data)
    },
    sendMessageTemplate(data) {
        return appService.post('/messages/send-template', data)
            .then((res) => res.data)
    },
    chatRoomUserMarkAsRead(id) {
        return appService.put('/chat_rooms/' + id + '/mark-as-read')
            .then((res) => res.data)
    },
    captureChatRoom(id) {
        return appService.put('/chat_rooms/' + id + '/capture')
            .then((res) => res.data)
    },
    releaseChatRoom(id) {
        return appService.put('/chat_rooms/' + id + '/release')
            .then((res) => res.data)
    },
    closeChatRoom(id) {
        return appService.put('/chat_rooms/' + id + '/close')
            .then((res) => res.data)
    },
    uncloseChatRoom(id) {
        return appService.put('/chat_rooms/' + id + '/unclose')
            .then((res) => res.data)
    },
    transferChatRoom(id, userId) {
        return appService.put('/chat_rooms/' + id + '/transfer/' + userId)
            .then((res) => res.data)
    },
}

export default chatRoomsService;